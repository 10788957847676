import {
  makeStyles,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core'
import { DeleteOutline } from '@material-ui/icons'
import React, { useEffect } from 'react'
import { Query, useMutation, useNotify, useRefresh } from 'react-admin'

import * as Api from '../../providers/api'

const useStyles = makeStyles((theme) => ({
  list: {
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    borderWidth: 1,
  }
}))

function GivecardIconList () {

  // Const
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()
  const payload = {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'id', order: 'ASC' },
    filter: { key: 'givecard-icon' },
  }

  // Hook
  const [deleteOne, { loading: deleting, error: deleteError, data: deleteResp }] = useMutation()

  // Effect
  useEffect(() => {
    if (deleteError) {
      notify(`Error: ${deleteError.errorMessage || deleteError}`, 'warning')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteError])

  useEffect(() => {
    if (deleteResp) {
      refresh()
    }
  }, [refresh, deleteResp])

  const handleOnDeleteIconClick = (image) => {
    deleteOne({
      type: 'delete',
      resource: 'images',
      payload: { id: image.id, previousData: image }
    })

    Api.logAction({ action: 'delete givecard icon' })
      .then((ignored) => {})
  }

  return (
    <Query type='getList' resource='images' payload={payload}>
      {({ data, loading, error }) => {
        if (loading) { return <Typography>Loading...</Typography> }
        if (error) { return <Typography>Error... {error.message}</Typography> }
        return !data.length
          ? (<Typography>...</Typography>)
          : (
            <List className={classes.list} aria-label="givecard icon list">
              {
                data.map(imageItem => (
                  <ListItem button key={imageItem.id} alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar variant="rounded" alt={imageItem.name} src={imageItem.path} />
                    </ListItemAvatar>
                    <ListItemText primary={imageItem.name?.split('.')[0]} secondary={imageItem.path} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => {
                        handleOnDeleteIconClick(imageItem)
                      }} disabled={deleting}>
                        <DeleteOutline/>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              }
            </List>
          )
      }}
    </Query>
  )
}

export default GivecardIconList
