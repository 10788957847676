import { makeStyles } from '@material-ui/core'

export const giveCardFormStyles = makeStyles((theme) => ({
  rightBtn: {
    marginLeft: 'auto'
  },
  spaceLeft: {
    marginLeft: theme.spacing(1)
  },
  fieldGroupTitle: {
    marginBottom: theme.spacing(2)
  },
  paperFiledGroup: {
    padding: theme.spacing(2)
  },
  paperItem: {
    padding: theme.spacing(2),
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    borderWidth: 1,
  }
}))
