import {
  Card,
  CardHeader,
  Stepper,
  Step,
  StepButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button
} from '@material-ui/core'
import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import { useAuthProvider, useCreateController, useEditController, usePermissions, useNotify, useRedirect } from 'react-admin'
import { AnyObject } from 'react-final-form'

import { Data } from '../../providers'
import * as Api from '../../providers/api'
import { convertToSlug } from '../../utils'
import AdvanceSetting, { defaultExpirator } from './advanceSetting'
import Business from './business'
import Configuration, { defaultOpeningHours } from './configuration'
import Details, { defaultBranchItem } from './details'
import ImageUploader from './gcImageUploader'

function GiveCardCreate (props) {
  const controller = !props.id ? useCreateController : useEditController
  const {
    basePath,
    defaultTitle,
    record,
    version
  } = controller(props)

  // State
  const [activeStep, setActiveStep] = React.useState(0)
  const [businessList, setBusinessList] = React.useState([])
  const [projectList, setProjectList] = React.useState(null)
  const [givecardList, setGiveCardList] = React.useState(null)
  const [giveCardId, setGiveCardId] = React.useState(props.id)
  const [businessId, setBusinessId] = React.useState('add')
  const [gcConfig, setGCConfig] = React.useState(null)
  const [gcDetails, setGCDetails] = React.useState(null)
  const [gcImages, setGCImages] = React.useState(null)
  const [gcAdvanceSetting, setAdvanceSetting] = React.useState(null)
  const [isLoadingBusiness, setLoadingBusiness] = React.useState(false)
  const [isBusinessLoaded, setBusinessLoaded] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const [conformDialogOpen, setConformDialogOpen] = React.useState(false)
  const [confirmationPromiseResolve, setConfirmationPromiseResolve] = React.useState(null)

  // Const
  const authProvider = useAuthProvider()
  const redirectTo = useRedirect()
  const notify = useNotify()
  const userPermissions = usePermissions()
  const isAdvanceSettingAvailable = useMemo(
    () => userPermissions.loaded && authProvider.checkPermissions(authProvider.ACTIONS.GIVECARD_ADVANCE, userPermissions.permissions),
    [authProvider, userPermissions.loaded, userPermissions.permissions],
    )
  const GiveCardForms = getGiveCardForms(isAdvanceSettingAvailable)[activeStep]

  const businessFormRef = useRef()
  const configurationFormRef = useRef()
  const detailsFormRef = useRef()
  const advanceSettingFormRef = useRef()

  useEffect(() => {
    if (businessList.length === 0 && !isLoadingBusiness && !isBusinessLoaded) {
      setLoadingBusiness(true)
      Data
        .getList('businesses', { sort: { field: 'name', order: 'asc' }, pagination: { page: 1, perPage: 10000 }, filter: '' })
        .then(({ data }) => {
          setBusinessLoaded(true)
          setLoadingBusiness(false)
          setBusinessList(data)
        }).catch(() => {
          setBusinessLoaded(true)
          setLoadingBusiness(false)
      })
    } else if (props.id && record) {
      switch (activeStep) {
        case 0:
          if (businessId === 'add') setBusinessId(record.businessId)
          break
        case 1:
          if (!gcDetails) setGCDetails(record.details)
          break
        case 2:
          if (!gcConfig) setGCConfig(record.config)
          break
        case 3:
          if (!gcImages) setGCImages(record.images)
          break
        case 4:
          if (!gcAdvanceSetting) setAdvanceSetting(record.advanceSetting)
          break
        default:
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, businessList.length, isBusinessLoaded, isLoadingBusiness, props.id, record, version])

  useEffect(() => {
    if (activeStep === 4 && !projectList) {
      Api.getProjectList({ langCode: 'en' })
        .then(value => {
          setProjectList(value)
        })
        .catch(() => {
          setProjectList([])
        })
    }
  }, [activeStep, projectList])

  useEffect(() => {
    if (activeStep === 4 && !givecardList) {
      Api.getGiveCardList({ langCode: 'en' })
        .then(value => {
          setGiveCardList(value)
        })
        .catch(() => {
          setGiveCardList([])
        })
    }
  }, [activeStep, givecardList])

  // Functions
  const confirmationPromise = async () => {
    return new Promise(resolve => {
      setConfirmationPromiseResolve(() => (isConfirm) => {
        if (isConfirm != null) {
          resolve(isConfirm)
        }
      })
    })
  }

  const getFormRef = () => {
    switch (activeStep) {
      case 0:
        return businessFormRef
      case 1:
        return detailsFormRef
      case 2:
        return configurationFormRef
      case 4:
        return advanceSettingFormRef
      default:
        return null
    }
  }

  const getInitialValues = () => {
    switch (activeStep) {
      case 0:
        return getDefaultValue(activeStep)
      case 1:
        return gcDetails || getDefaultValue(activeStep)
      case 2:
        return gcConfig || getDefaultValue(activeStep)
      case 3:
        return gcImages || getDefaultValue(activeStep)
      case 4:
        return gcAdvanceSetting || getDefaultValue(activeStep)
      default:
        return null
    }
  }

  const updateGC = async (params) => {
    setSaving(true)
    if (giveCardId) {
      await Data.update('givecards', { id: giveCardId, data: params })
        .catch((e) => notify(`Error: ${e.errorMessage || e}`, 'warning'))
    } else {
      await Data.create('givecards', { data: params })
        .then(({ data }) => {
          setGiveCardId(data.id)
          setBusinessLoaded(false)
        })
        .catch((e) => notify(`Error: ${e.errorMessage || e}`, 'warning'))
    }
    setSaving(false)
  }

  const handleSaveBtn = async (values, isSaveDraft) => {
    let error
    switch (activeStep) {
      case 0:
        const { selectedBusinessId, ...params } = values
        if (businessId === 'add') {
          setSaving(true)
          await Data.create('businesses', { data: params })
            .then(({ data }) => {
              setBusinessList([...businessList, data])
              setBusinessId(data.id)
              return data
            })
            .then(data => {
              return updateGC({ businessId: data.id })
            })
            .catch((e) => {
              error = e
              setSaving(false)
              notify(`Error resetting cache: ${e.errorMessage || e}`, 'warning')
            })
        } else {
          await updateGC({ businessId: selectedBusinessId })
        }

        break
      case 1:
        setGCDetails(values)
        await updateGC(values)
        break
      case 2:
        setGCConfig(values)
        await updateGC(values)
        break
      case 3:
        setGCImages(values)
        if (!isAdvanceSettingAvailable && !isSaveDraft) {
          await updateGC({ ...values, status: 'pending' })
        } else {
          await updateGC(values)
        }
        break
      case 4:
        setAdvanceSetting(values)
        if (!isSaveDraft) {
          await updateGC({ ...values, status: 'pending' })
        } else {
          await updateGC(values)
        }
        break
      default:
        break
    }
    if (!isSaveDraft && !error) {
      setActiveStep(Math.min(activeStep + 1, getGiveCardForms(isAdvanceSettingAvailable).length - 1))
      if (getGiveCardForms(isAdvanceSettingAvailable).length - 1 === activeStep) {
        redirectTo('list', basePath)
      }
    }
  }

  const handleOnStepItemClick = (step) => () => {
    setActiveStep(Math.min(step, getGiveCardForms(isAdvanceSettingAvailable).length - 1))
  }

  const handleOnBackBtnClick = () => {
    setActiveStep(Math.max(activeStep - 1, 0))
  }

  // Callback
  const handleConformDialogClose = useCallback((isConfirm) => () => {
    if (confirmationPromiseResolve) {
      setConformDialogOpen(false)
      confirmationPromiseResolve(isConfirm)
    }
  }, [confirmationPromiseResolve])

  // Setup form props
  const contentProps: GiveCardFormProps = {
    onSubmit: handleSaveBtn,
    handleOnBackBtnClick,
    initialValues: getInitialValues(activeStep),
    isLastTab: getGiveCardForms(isAdvanceSettingAvailable).length - 1 === activeStep,
    saving: saving,
    setConformDialogOpen: setConformDialogOpen,
    confirmationPromise: confirmationPromise,
  }

  if (activeStep === 0) {
    contentProps.businessList = businessList
    contentProps.setBusinessId = setBusinessId
    contentProps.selectedBusinessId = businessId
  }

  if (activeStep === 4) {
    contentProps.givecardList = givecardList
    contentProps.projectList = projectList

    // Default for create form
    if (!props.id) {
      contentProps.projectCategory = gcConfig?.projectCategory
      contentProps.slug = convertToSlug(gcDetails?.en?.shortDesc)
    }

    if (record && (!contentProps.initialValues || (contentProps.initialValues && !contentProps.initialValues.slug))) {
      contentProps.initialValues = contentProps.initialValues ? {
        ...contentProps.initialValues,
        slug: convertToSlug(record.details?.en?.shortDesc)
      } : {
        slug: convertToSlug(record.details?.en?.shortDesc)
      }
    }

    if (record && !contentProps.projectCategory) {
      contentProps.projectCategory = gcConfig?.projectCategory || record.config?.projectCategory
    }
  }

  return (
    <Card>
      <CardHeader title={defaultTitle}/>
      <Stepper
        activeStep={activeStep}
        alternativeLabel>
        {getGiveCardForms(isAdvanceSettingAvailable).map(({ title }, index) => (
          <Step key={title}>
            <StepButton onClick={handleOnStepItemClick(index)}>{title}</StepButton>
          </Step>
        ))}
      </Stepper>
      {GiveCardForms?.component && <GiveCardForms.component {...contentProps} ref={getFormRef()} showFooter/>}
      <Dialog
        open={conformDialogOpen}
        onClose={handleConformDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">{'You are about to become a Socialgiver 💖'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By submitting this information, you will no longer be able to edit thereafter.
            Let's embark on a journey of making changes to our society!
          </DialogContentText>
        </DialogContent>
        <DialogActions disableSpacing>
          <Button onClick={handleConformDialogClose(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConformDialogClose(true)} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

export type GiveCardFormProps = {
  onSubmit: (AnyObject, isSaveDraft?: boolean) => void,
  handleOnBackBtnClick: () => void,
  initialValues: AnyObject,
  isLastTab: boolean,
  saving: boolean,
  disableForm: boolean,
  setConformDialogOpen: () => void,
  confirmationPromise: () => Promise,
}

export function getDefaultValue (activeStep) {
  switch (activeStep) {
    case 0: return { isSameAsPrimary: false, payments: [{}] }
    case 1: return {
      alwaysOpen: false,
      branches: [defaultBranchItem],
      openingHours: [defaultOpeningHours],
      hashtags: { en: [], th: [] },
    }
    case 2: return {
      usage: 'printAndMobile',
      other: {
        isDisabledFriendly: false,
        isPetFriendly: false,
        hasShaStandard: false,
        requiresShipping: false,
      }
    }
    case 3: return {}
    case 4: return {
      menuOrder: 0,
      qtyStock: 0,
      qtyLimit: 0,
      hoursUntilRePurchasable: 0,
      expirationType: 'none',
      numberOfDays: 60,
      visibility: 'private',
      projectSupport: null,
      projectSupportedId: null,
      postExpirator: defaultExpirator,
      percentageOfDonation: 70,
      percentageOfBusiness: 0,
      percentageOfSocialgiver: 30,
      savedRelatedGiveCards: [],
    }
    default: return null
  }
}

export function getGiveCardForms (isAdvanceSettingAvailable) {
  const giveCardForms = [
    {
      title: 'Business',
      component: Business,
    },
    {
      title: 'Details',
      component: Details,
    },
    {
      title: 'Configuration',
      component: Configuration,
    },
    {
      title: 'Pictures',
      component: ImageUploader,
    }
  ]
  if (isAdvanceSettingAvailable) {
    giveCardForms.push({
      title: 'Advance Setting',
      component: AdvanceSetting,
    })
  }
  return giveCardForms
}

export default GiveCardCreate
