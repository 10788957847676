import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  FormLabel,
  FormControlLabel,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  RadioGroup,
  Radio,
  Checkbox,
  FormHelperText,
  Typography,
  Paper,
  CircularProgress
} from '@material-ui/core'
import { Save } from '@material-ui/icons'
import arrayMutators from 'final-form-arrays'
import React, { useEffect, forwardRef, useImperativeHandle } from 'react'
import { Field, Form } from 'react-final-form'

import { isFieldError, getFieldErrorMessage } from '../../utils.js'
import { required } from '../../validations'
import { GiveCardFormProps } from './create'
import GiveCardFormConfig from './formConfig.json'
import { GiveCardFooter } from './giveCardFooter'
import { giveCardFormStyles } from './style'

const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginRight: 'auto',
  },
  title: {
    marginTop: theme.spacing(2),
  }
}))

function Configuration (props: GiveCardFormProps, ref) {
  // State
  const [usage, setUsage] = React.useState(props.initialValues.usage)

  // Const
  const classes = useStyles()
  const giveCardFormClasses = giveCardFormStyles()
  const formControlProps = {
    disabled: !!props.disableForm,
    required: true,
    fullWidth: true,
    size: 'small'
  }
  let isSaveDraft = false

  // Functions
  useEffect(() => {
    setUsage(props.initialValues.usage)
  }, [props.initialValues.usage])

  const onSaveDraft = (event, handleSubmit) => {
    isSaveDraft = true
    handleSubmit(event)
  }

  const onSubmit = (values) => {
    props.onSubmit({ ...values, usage }, isSaveDraft)
    isSaveDraft = false
  }

  const validate = () => {
    // const errors = {}
    // return errors
  }

  const handleOnUsageChanged = (event) => {
    setUsage(event.target.value)
  }

  useImperativeHandle(ref, () => ({
    something: () => {
      console.log('something')
      return 6969
    }
  }))

  return (
    <Card ref={ref} elevation={0}>
      <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={props.initialValues}
          mutators={{ ...arrayMutators }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                      <Grid container alignContent="flex-start" spacing={2}>
                        <Grid item xs={12}>
                          <Field name="projectCategory" validate={required}>
                            {({ input, meta }) => (
                              <FormControl {...formControlProps} error={isFieldError(meta)}>
                                <InputLabel id="project-category">Project or causes you want to support</InputLabel>
                                <Select
                                  { ...input }
                                  labelId="project-category"
                                  id="projectCategory"
                                >
                                  {
                                    Object.keys(GiveCardFormConfig.projectCategory).map((key) => (
                                      <MenuItem key={key} value={key}>{GiveCardFormConfig.projectCategory[key]}</MenuItem>))
                                  }
                                </Select>
                                <FormHelperText>{getFieldErrorMessage(meta)}</FormHelperText>
                              </FormControl>
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl {...formControlProps} component="fieldset">
                            <FormLabel component="legend">Product Usage</FormLabel>
                            <RadioGroup
                              value={usage}
                              defaultValue={`${props.initialValues.usage}`}
                              aria-label="product-usage"
                              onChange={handleOnUsageChanged}
                            >
                              {
                                Object.keys(GiveCardFormConfig.usage).map((key) => (
                                  <FormControlLabel
                                    key={key}
                                    value={key}
                                    control={<Radio color="primary" />}
                                    label={GiveCardFormConfig.usage[key]} />
                                ))
                              }
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                      <Grid container alignContent="flex-start" spacing={2}>
                        <Grid item xs={12}>
                          <Typography className={classes.title} variant='subtitle2'>
                            Other information
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name="other.isDisabledFriendly" type="checkbox">
                            {({ input }) => (
                              <FormControlLabel
                                disabled={formControlProps.disabled}
                                control={<Checkbox {...input} color="primary" className={classes.checkbox} />}
                                label="Is your facility Disabled-Access Friendly?"
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name="other.isPetFriendly" type="checkbox">
                            {({ input }) => (
                              <FormControlLabel
                                disabled={formControlProps.disabled}
                                control={<Checkbox {...input} color="primary" className={classes.checkbox} />}
                                label="Is your facility Pet Friendly?"
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name="other.hasShaStandard" type="checkbox">
                            {({ input }) => (
                              <FormControlLabel
                                disabled={formControlProps.disabled}
                                control={<Checkbox {...input} color="primary" className={classes.checkbox} />}
                                label="Is your facility SHA standard certified?"
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name="other.requiresShipping" type="checkbox">
                            {({ input }) => (
                              <FormControlLabel
                                disabled={formControlProps.disabled}
                                control={<Checkbox {...input} color="primary" className={classes.checkbox} />}
                                label="Is shipping required for your product?"
                              />
                            )}
                          </Field>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </CardContent>
              {
                props.showFooter && (
                  <CardContent>
                    <GiveCardFooter />
                  </CardContent>
                )
              }
              {
                !props.disableForm && (
                  <CardActions disableSpacing>
                    <Button
                      onClick={props.handleOnBackBtnClick}
                      variant="outlined"
                      color="primary"
                      disabled={props.saving}
                    >
                      Back
                    </Button>
                    {
                      props.saving && (
                        <CircularProgress size={32} className={giveCardFormClasses.rightBtn} />
                      )
                    }
                    <Button
                      className={props.saving ? giveCardFormClasses.spaceLeft : giveCardFormClasses.rightBtn}
                      onClick={event => onSaveDraft(event, handleSubmit)}
                      variant="outlined"
                      color="primary"
                      disabled={props.saving}
                      startIcon={<Save />}
                    >
                      Save Draft
                    </Button>
                    <Button
                      className={giveCardFormClasses.spaceLeft}
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={props.saving}
                    >
                      Save & Next
                    </Button>
                  </CardActions>
                )
              }
            </form>
      )}/>
    </Card>
  )
}

export const defaultOpeningHours = {
  date: {
    start: 0,
    end: 6
  },
  time: {
    start: new Date().setHours(8, 0),
    end: new Date().setHours(20, 0)
  }
}

export default forwardRef(Configuration)
