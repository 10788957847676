import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Chip,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  CircularProgress,
  Slider
} from '@material-ui/core'
import { Save } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { DateTimePicker, DatePicker } from '@material-ui/pickers'
import React, { forwardRef, useEffect } from 'react'
import { Field, Form } from 'react-final-form'

import { composeValidators, errorFieldProp, getFieldErrorMessage, isFieldError, allowNullParse } from '../../utils'
import { maxLength, minValue, number, required } from '../../validations'
import type { GiveCardFormProps } from './create'
import GiveCardFormConfig from './formConfig.json'
import { giveCardFormStyles } from './style'

const useStyles = makeStyles(() => ({
  dateTimeField: {
    width: '100%'
  }
}))

function renderAutocompleteItem (value, getTagProps) {
  return value.map((option, index) => (
    <Chip color="secondary" variant="outlined" label={option.title} {...getTagProps({ index })} />
  ))
}

function renderAutocompleteInput (params) {
  return <TextField {...params} label="Related GiveCards" variant="outlined"/>
}

function AdvanceSetting (props: GiveCardFormProps, ref) {
  // State
  const [project, setProject] = React.useState(null)
  const [relatedGiveCards, setRelatedGiveCards] = React.useState([])
  const [expirationType, setExpirationType] = React.useState(props.initialValues.expirationType)

  // Const
  const classes = useStyles()
  const giveCardFormClasses = giveCardFormStyles()
  const formControlProps = {
    disabled: !!props.disableForm,
    required: true,
    fullWidth: true,
    size: 'small'
  }
  let isSaveDraft = false

  const marks = [
    { value: 0, label: '0%' },
    { value: 30, label: '30%' },
    { value: 50, label: '50%' },
    { value: 70, label: '70%' },
  ]

  const valuetext = (value) => {
    return value ? `${value}%` : '0%'
  }

  // Render
  const getExpirationField = () => {
    switch (expirationType) {
      case 'numberOfDays': return (
        <Grid item xs={12}>
          <Field
            name="numberOfDays"
            validate={composeValidators(required, number, minValue(0))}
          >
            {({ input, meta }) => (
              <TextField
                {...formControlProps}
                {...input}
                id="numberOfDays"
                label="Number of days"
                type="number"
                inputProps={{ min: 0 }}
                {...errorFieldProp(meta)}
              />
            )}
          </Field>
        </Grid>
      )
      case 'datePeriod': return (
        <>
          <Grid item xs={12} sm={6}>
            <Field
              name="datePeriod.startDate"
              validate={required}>
              {({ input, meta }) => (
                <DatePicker
                  className={classes.dateTimeField}
                  {...input}
                  id="date-period-start-date"
                  label="Start Date"
                  disabled={formControlProps.disabled}
                  invalidDateMessage={getFieldErrorMessage(meta)}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="datePeriod.endDate"
              validate={required}>
              {({ input, meta }) => (
                <DatePicker
                  className={classes.dateTimeField}
                  {...input}
                  id="date-period-end-date"
                  label="End Date"
                  disabled={formControlProps.disabled}
                  invalidDateMessage={getFieldErrorMessage(meta)}
                />
              )}
            </Field>
          </Grid>
        </>
      )
      default: return null
    }
  }

  // Functions
  useEffect(() => {
    setExpirationType(props.initialValues.expirationType)
  }, [props.initialValues.expirationType])
  useEffect(() => {
    if (props.initialValues.projectSupportedId && props.projectList) {
      const selectedProject = props.projectList.filter(({ id }) => id === props.initialValues.projectSupportedId )
      setProject(selectedProject?.length ? selectedProject[0] : null)
    }
  }, [props.initialValues.projectSupportedId, props.projectList])
  useEffect(() => {
    const savedRelatedGiveCards = props.initialValues.savedRelatedGiveCards?.map(id => parseInt(id))
    const givecardList = props.givecardList
    if (savedRelatedGiveCards.length && givecardList?.length) {
      const relatedGiveCardsList = givecardList.filter(({ id }) => savedRelatedGiveCards.includes(id))
      setRelatedGiveCards(relatedGiveCardsList)
    }
  }, [props.initialValues.savedRelatedGiveCards, props.givecardList])

  const onSaveDraft = (event, handleSubmit) => {
    isSaveDraft = true
    handleSubmit(event)
  }

  const onSubmit = async (values) => {
    let isConfirmSubmit = true
    if (!isSaveDraft) {
      props.setConformDialogOpen(true)
      isConfirmSubmit = await props.confirmationPromise()
    }
    if (isConfirmSubmit) {
      props.onSubmit({ ...values, expirationType, project, relatedGiveCards }, isSaveDraft)
    } else {
      props.onSubmit({ ...values, expirationType, project, relatedGiveCards }, true)
    }
    isSaveDraft = false
  }

  const validate = (values) => {
    const isPercentageValid = Number(values.percentageOfDonation) +
      Number(values.percentageOfBusiness) +
      Number(values.percentageOfSocialgiver) === 100
    const validationResult = {
      projectSupport: required(project),
    }
    if (!isPercentageValid) {
      validationResult.percentageOfDonation = {
        message: 'Invalid Percentage.'
      }
      validationResult.percentageOfBusiness = {
        message: 'Invalid Percentage.'
      }
      validationResult.percentageOfSocialgiver = {
        message: 'Invalid Percentage.'
      }
    }
    return validationResult
  }

  const handleOnExpirationTypeChanged = (event) => {
    setExpirationType(event.target.value)
  }

  const handleOnProjectChanged = (event, newValue) => {
    setProject(newValue)
  }

  const handleOnRelatedGiveCardsChanged = (event, newValue) => {
    setRelatedGiveCards(newValue)
  }

  return (
    <Card ref={ref} elevation={0}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={props.initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='subtitle2'>
                          Code list Generator
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="minimumAvailableCode"
                          validate={composeValidators(required, number, minValue(0))}
                        >
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              {...input}
                              id="minimumAvailableCode"
                              label="Minimum Available Codes"
                              type="number"
                              inputProps={{ min: 0 }}
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="qtyPerBatch"
                          validate={composeValidators(required, number, minValue(0))}
                        >
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              {...input}
                              id="qtyPerBatch"
                              label="Quantity per Batch"
                              type="number"
                              inputProps={{ min: 0 }}
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='subtitle2'>
                          Expiration Type
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl {...formControlProps} component="fieldset">
                          <RadioGroup
                            value={expirationType}
                            defaultValue={`${props.initialValues.expirationType}`}
                            aria-label="expiration-type"
                            onChange={handleOnExpirationTypeChanged}
                          >
                            {
                              Object.keys(GiveCardFormConfig.expirationType).map((key) => (
                                <FormControlLabel
                                  disabled={formControlProps.disabled}
                                  key={key}
                                  value={key}
                                  control={<Radio color="primary" />}
                                  label={GiveCardFormConfig.expirationType[key]} />
                              ))
                            }
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid container item xs={12} spacing={2}>
                        { getExpirationField() }
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='subtitle2'>
                          Usage Setting
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="showGivecardCodeAsQR"
                          type="checkbox"
                        >
                          {({ input }) => (
                            <FormControlLabel
                              disabled={formControlProps.disabled}
                              label="Show GiveCard Code as QR"
                              control={
                                <Checkbox
                                  {...input}
                                  id="showGivecardCodeAsQR"
                                  color="primary"
                                />
                              }
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="showPinCode"
                          type="checkbox"
                        >
                          {({ input }) => (
                            <FormControlLabel
                              disabled={formControlProps.disabled}
                              label="Show Pin Code"
                              control={
                                <Checkbox
                                  {...input}
                                  id="show-pin-code"
                                  color="primary"
                                />
                              }
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="qrCodeTitle"
                          validate={maxLength(30)}
                          parse={allowNullParse}
                        >
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              required={false}
                              {...input}
                              id="qrCodeTitle"
                              label="QR Code Title"
                              inputProps={{ maxLength: 30 }}
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='subtitle2'>
                          Fund Setting
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="percentageOfDonation"
                          validate={required}
                        >
                          {({ input, meta }) => (
                            <FormControl {...formControlProps} error={isFieldError(meta)}>
                              <Typography id="percentage-donation-slider" gutterBottom>
                                Percentage of Donation
                              </Typography>
                              <Slider
                                disabled={formControlProps.disabled}
                                onChange={(event, value) => input.onChange(value)}
                                value={typeof input.value === 'number' ? input.value : 0}
                                defaultValue={30}
                                getAriaValueText={valuetext}
                                aria-labelledby="percentage-donation-slider"
                                marks={marks}
                                step={5}
                                min={0}
                                max={100}
                                valueLabelDisplay="auto"
                              />
                              <FormHelperText>{getFieldErrorMessage(meta)}</FormHelperText>
                            </FormControl>
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="percentageOfBusiness"
                          validate={required}
                        >
                          {({ input, meta }) => (
                            <FormControl {...formControlProps} error={isFieldError(meta)}>
                              <Typography id="percentage-business-slider" gutterBottom>
                                Percentage of Business
                              </Typography>
                              <Slider
                                disabled={formControlProps.disabled}
                                onChange={(event, value) => input.onChange(value)}
                                value={typeof input.value === 'number' ? input.value : 0}
                                defaultValue={30}
                                getAriaValueText={valuetext}
                                aria-labelledby="percentage-business-slider"
                                marks={marks}
                                step={5}
                                min={0}
                                max={100}
                                valueLabelDisplay="auto"
                              />
                              <FormHelperText>{getFieldErrorMessage(meta)}</FormHelperText>
                            </FormControl>
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="percentageOfSocialgiver"
                          validate={required}
                        >
                          {({ input, meta }) => (
                            <FormControl {...formControlProps} error={isFieldError(meta)}>
                              <Typography id="percentage-socialgiver-slider" gutterBottom>
                                Percentage of Socialgiver
                              </Typography>
                              <Slider
                                disabled={formControlProps.disabled}
                                onChange={(event, value) => input.onChange(value)}
                                value={typeof input.value === 'number' ? input.value : 0}
                                defaultValue={30}
                                getAriaValueText={valuetext}
                                aria-labelledby="percentage-socialgiver-slider"
                                marks={marks}
                                step={5}
                                min={0}
                                max={100}
                                valueLabelDisplay="auto"
                              />
                              <FormHelperText>{getFieldErrorMessage(meta)}</FormHelperText>
                            </FormControl>
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          name="shareCampaignUrl"
                          parse={allowNullParse}
                        >
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              required={false}
                              {...input}
                              id="shareCampaignUrl"
                              label="Share campaign URL"
                              placeholder="Share campaign from thank you page"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name="feeInformation.en"
                          parse={allowNullParse}
                          validate={maxLength(50)}
                        >
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              {...input}
                              required={false}
                              id="feeInformationEN"
                              label="Fee Information (EN)"
                              placeholder="Show in Add To Cart Page"
                              inputProps={{ maxLength: 50 }}
                              helperText="Limit 50 characters"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name="feeInformation.th"
                          parse={allowNullParse}
                          validate={maxLength(50)}
                        >
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              {...input}
                              required={false}
                              id="feeInformationTH"
                              label="Fee Information (TH)"
                              placeholder="Show in Add To Cart Page"
                              inputProps={{ maxLength: 50 }}
                              helperText="Limit 50 characters"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name="prizeText.en"
                          parse={allowNullParse}
                          validate={maxLength(20)}
                        >
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              required={false}
                              {...input}
                              id="prizeTextEN"
                              label="Prize text (EN)"
                              placeholder="Shows Prize instead of Value"
                              inputProps={{ maxLength: 20 }}
                              helperText="Limit 20 characters"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name="prizeText.th"
                          parse={allowNullParse}
                          validate={maxLength(20)}
                        >
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              required={false}
                              {...input}
                              id="prizeTextTH"
                              label="Prize text (TH)"
                              placeholder="Shows Prize instead of Value"
                              inputProps={{ maxLength: 20 }}
                              helperText="Limit 20 characters"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name="qtyLimit"
                          validate={composeValidators(required, number, minValue(0))}
                        >
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              required={false}
                              {...input}
                              id="qtyLimit"
                              label="Quantity limit"
                              type="number"
                              inputProps={{ min: 0 }}
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name="hoursUntilRePurchasable"
                          validate={composeValidators(required, number, minValue(0))}
                        >
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              required={false}
                              {...input}
                              id="hoursUntilRePurchasable"
                              label="Hour until re-purchased"
                              type="number"
                              inputProps={{ min: 0 }}
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="isRequire3ds"
                          type="checkbox"
                        >
                          {({ input }) => (
                            <FormControlLabel
                              disabled={formControlProps.disabled}
                              label="Is require 3ds"
                              control={
                                <Checkbox
                                  {...input}
                                  id="isRequire3ds"
                                  color="primary"
                                />
                              }
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field name="projectSupport">
                          {({ input, meta }) => {
                            const { value, onChange, ...inputProp } = input
                            return (
                              <Autocomplete
                                id="projectSupport"
                                {...formControlProps}
                                {...inputProp}
                                options={
                                  props.projectList?.filter((item) =>
                                    props.projectCategory && props.projectCategory !== 'others'
                                      ? item.support_cause === props.projectCategory
                                      : true
                                  ) || []
                                }
                                loading={!props.projectList}
                                getOptionLabel={(option) => `${option.project_name}`}
                                getOptionSelected={(option, value) => option.id === value.id}
                                value={project}
                                onChange={handleOnProjectChanged}
                                disableClearable
                                renderInput={(params) =>
                                  <TextField {...params} label="Select project support" {...errorFieldProp(meta)}/>
                                }
                              />
                            )
                          }}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="slug"
                          validate={composeValidators(required, maxLength(255))}
                        >
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              {...input}
                              id="Slug"
                              label="Slug"
                              inputProps={{ maxLength: 255 }}
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name="visibility"
                          validate={required}
                        >
                          {({ input, meta }) => (
                            <FormControl {...formControlProps} required={false} error={isFieldError(meta)}>
                              <InputLabel id="visibility">Visibility</InputLabel>
                              <Select
                                {...input}
                                id="visibility"
                                labelId="visibility"
                              >
                                {Object.keys(GiveCardFormConfig.visibility).map((key) => (
                                  <MenuItem key={key} value={key}>{GiveCardFormConfig.visibility[key]}</MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>{getFieldErrorMessage(meta)}</FormHelperText>
                            </FormControl>
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name="invisibleTo"
                        >
                          {({ input, meta }) => (
                            <FormControl {...formControlProps} required={false} error={isFieldError(meta)}>
                              <InputLabel id="invisibleTo">Invisible to</InputLabel>
                              <Select
                                {...input}
                                id="invisibleTo"
                                labelId="invisibleTo"
                              >
                                <MenuItem>None</MenuItem>
                                {Object.keys(GiveCardFormConfig.invisibility).map((key) => (
                                  <MenuItem key={key} value={key}>{GiveCardFormConfig.invisibility[key]}</MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>{getFieldErrorMessage(meta)}</FormHelperText>
                            </FormControl>
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="menuOrder"
                          validate={composeValidators(required, number, minValue(0))}
                        >
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              required={false}
                              {...input}
                              id="menuOrder"
                              label="Menu Order"
                              type="number"
                              inputProps={{ min: 0 }}
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='subtitle2'>
                          Replenish stock
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="replenishStock"
                          type="checkbox"
                        >
                          {({ input }) => (
                            <FormControlLabel
                              disabled={formControlProps.disabled}
                              label="Replenish stock"
                              control={
                                <Checkbox
                                  {...input}
                                  id="replenishStock"
                                  color="primary"
                                />
                              }
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field name="replenishStock" subscription={{ value: true }}>
                          {({ input: { value } }) => value && (
                            <Field
                              name="qtyStock"
                              validate={composeValidators(required, number, minValue(0))}
                            >
                              {({ input, meta }) => (
                                <TextField
                                  {...formControlProps}
                                  {...input}
                                  id="qtyPerBatch"
                                  label="Quantity"
                                  type="number"
                                  inputProps={{ min: 0 }}
                                  {...errorFieldProp(meta)}
                                />
                              )}
                            </Field>
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          name="relatedGiveCards"
                        >
                          {() => (
                            <Autocomplete
                              id="relatedGiveCards"
                              {...formControlProps}
                              multiple
                              autoHighlight
                              options={props.givecardList?.map(item => ({
                                id: item.id,
                                title: item.title
                              })) || []}
                              loading={!props.givecardList}
                              getOptionLabel={(option) => `${option.title}`}
                              getOptionSelected={(option, value) => option.id === value.id}
                              value={relatedGiveCards}
                              onChange={handleOnRelatedGiveCardsChanged}
                              renderTags={renderAutocompleteItem}
                              renderInput={renderAutocompleteInput}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="isEnablePostExpiration"
                          type="checkbox"
                        >
                          {({ input }) => (
                            <FormControlLabel
                              disabled={formControlProps.disabled}
                              label="Enable Post Expiration"
                              control={
                                <Checkbox
                                  {...input}
                                  id="isEnablePostExpiration"
                                  color="primary"
                                />
                              }
                            />
                          )}
                        </Field>
                      </Grid>
                      <Field name="isEnablePostExpiration" subscription={{ value: true }}>
                        {({ input: { value } }) => value && (
                          <Grid item xs={12}>
                            <Field
                              name="postExpirator"
                              validate={required}>
                              {({ input, meta }) => (
                                <DateTimePicker
                                  className={classes.dateTimeField}
                                  {...input}
                                  id="post-expirator-picker"
                                  ampm={false}
                                  label="GiveCard Expiration"
                                  disablePast
                                  disabled={formControlProps.disabled}
                                  minutesStep={5}
                                  invalidDateMessage={getFieldErrorMessage(meta)}
                                />
                              )}
                            </Field>
                          </Grid>
                        )}
                      </Field>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
            {
              !props.disableForm && (
                <CardActions disableSpacing>
                  <Button
                    onClick={props.handleOnBackBtnClick}
                    variant="outlined"
                    color="primary"
                    disabled={props.saving}
                  >
                    Back
                  </Button>
                  {
                    props.saving && (
                      <CircularProgress size={32} className={giveCardFormClasses.rightBtn} />
                    )
                  }
                  <Button
                    className={props.saving ? giveCardFormClasses.spaceLeft : giveCardFormClasses.rightBtn}
                    onClick={event => onSaveDraft(event, handleSubmit)}
                    variant="outlined"
                    color="primary"
                    disabled={props.saving}
                    startIcon={<Save/>}
                  >
                    Save Draft
                  </Button>
                  <Button
                    className={giveCardFormClasses.spaceLeft}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={props.saving}
                  >
                    Submit
                  </Button>
                </CardActions>
              )
            }
          </form>
        )}/>
    </Card>
  )
}

export const defaultExpirator = new Date().setHours(0, 0)

export default forwardRef(AdvanceSetting)
