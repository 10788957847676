import {
  makeStyles,
  Card,
  Grid,
  Paper,
  CardContent,
  Typography,
  Button,
  CardActions,
  FormHelperText,
  FormControl,
  CircularProgress,
} from '@material-ui/core'
import { Save } from '@material-ui/icons'
import React, { forwardRef, useEffect, useMemo, useRef } from 'react'
import { useNotify } from 'react-admin'
import { Field, Form } from 'react-final-form'
import ImageUploader from 'react-images-upload'

import * as Api from '../../providers/api'
import { getFieldErrorMessage, isFieldError, isDataURL } from '../../utils'
import { required } from '../../validations'
import type { GiveCardFormProps } from './create'
import { GiveCardFooter } from './giveCardFooter'
import ImagePreview from './imagePreview'
import { giveCardFormStyles } from './style'

const previewStyle = {
  objectFit: 'cover',
  pointerEvents: 'none',
  userSelect: 'none',
}

const useStyles = makeStyles((theme) => ({
  imageUploader: {
    '& .fileContainer': {
      textAlign: 'center',
      padding: theme.spacing(2),
      marginTop: 0,
      marginBottom: 0,
    },
    '& .chooseFileButton': {
      background: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
      pointerEvents: props => props.isUploading ? 'none' : 'unset',
      filter: props => props.isUploading ? 'grayscale(90%)' : 'unset',
      '&:hover': {
        background: theme.palette.primary.main,
      }
    },
  },
  logoPreview: {
    ...previewStyle,
    borderRadius: 29,
  },
  featuredPreview: {
    ...previewStyle,
    borderRadius: theme.shape.borderRadius
  },
}))

function GCImageUploader (props: GiveCardFormProps, ref) {
  // State
  const [galleryImages, setGalleryImages] = React.useState(props.initialValues?.galleryImages || [])
  const [uploadedPhotos, setUploadedPhotos] = React.useState(props.initialValues)
  const [uploadingPhotos, setUploadingPhotos] = React.useState({})

  const isUploading = useMemo(() => {
    return Object.keys(uploadingPhotos).some((k) => uploadingPhotos[k])
  }, [uploadingPhotos])

  // Const
  const notify = useNotify()
  const classes = useStyles({ isUploading })
  const galleryImagesPreview = useRef()
  const galleryImageUploaderRef = useRef()
  const giveCardFormClasses = giveCardFormStyles()
  const maxImageSize = 1024 * 1024 * 5 // 5mb
  const heroSize = [
    { w: 480, h: 212 },
    { w: 376, h: 212 },
    { w: 262.5, h: 212 },
    { w: 212, h: 212 },
  ]
  let isSaveDraft = false

  // Functions
  useEffect(() => {
    setUploadedPhotos(props.initialValues)
  }, [props.initialValues])

  useEffect(() => {
    setGalleryImages(props.initialValues?.galleryImages || [])
  }, [props.initialValues.galleryImages])

  const onSaveDraft = (event, handleSubmit) => {
    isSaveDraft = true
    handleSubmit(event)
  }

  const onSubmit = async (values) => {
    let isConfirmSubmit = true
    const formData = { ...values, galleryImages }
    const saveData = {}
      Object.keys({ ...values, galleryImages })
      .forEach(key => {
        saveData[key] = formData[key].map(_data => {
          const _uploadedPhoto = uploadedPhotos[key].find(up => up.name === _data.name)
          return {
            ..._data,
            src: _uploadedPhoto.src
          }
        })
      })
    if (!isSaveDraft && props.isLastTab) {
      props.setConformDialogOpen(true)
      isConfirmSubmit = await props.confirmationPromise()
    }
    if (isConfirmSubmit) {
      props.onSubmit(saveData, isSaveDraft)
    } else {
      props.onSubmit(saveData, true)
    }
    isSaveDraft = false
  }

  const validate = () => {
    // const errors = {}
    // return errors
  }

  const handleOnImagesChanged = (files, pictures, input) => {
    const values = pictures.map((picture, i) => ({ src: picture, name: files[i].name }))
    input.onChange(values)
    uploadPhotos(input.name, values.map((v, i) => ({ ...v, file: files[i] })))
      .catch(console.log)
  }

  const handleGalleryImagesChanged = (files, pictures) => {
    const _pictures = pictures.filter(p => isDataURL(p))
    const values = files
      .map((file, i) => ({ src: _pictures[i], name: file.name }))
      .filter(p => {
        return !galleryImages.length || galleryImages.findIndex(gp => p.name === gp.name) === -1
      })
    setGalleryImages(galleryImages.concat(values))
    return galleryImages.concat(values)
  }

  const handleGalleryImagesUpdated = (values) => {
    setGalleryImages(values)
  }

  const handleOnGalleryImageRemoved = (picture) => {
    const photos = galleryImages.filter(photo => photo.src !== picture)
    setGalleryImages(photos)
    if (isDataURL(picture)) {
      // eslint-disable-next-line babel/no-unused-expressions
      galleryImageUploaderRef?.current?.removeImage(picture)
    }
  }

  const uploadPhotos = async (fieldName, values) => {
    const uploadedPhotosName = uploadedPhotos[fieldName]?.map(p => p.name) || []
    const promises = values
      .filter(value => !uploadedPhotosName.length ? true : uploadedPhotosName.indexOf(value.name) === -1)
      .map(({ file, name }, index) => Api.uploadGiveCardImage({ type: fieldName, image: file })
        .then(resp => ({ index, imageName: name, fieldName, ...resp }))
        .catch((error) => notify(`upload fail: ${error.message}`, 'warning'))
    )

    if (promises.length) {
      updateUploadingPhotos(fieldName, true)
      Promise.all(promises)
        .then(values => {
          let _uploadedPhoto = { ...uploadedPhotos }
          values.forEach(value => {
            _uploadedPhoto[value.fieldName] = [
              ...(_uploadedPhoto[value.fieldName] || []),
              { src: value.path, name: value.imageName }
            ]
          })
          setUploadedPhotos(_uploadedPhoto)
          updateUploadingPhotos(fieldName, false)
        })
        .catch((e) => notify(`Error: ${e.errorMessage || e}`, 'warning'))

    }
  }

  const updateUploadingPhotos = (field, isUploading) => {
    let uploading = { ...uploadingPhotos }
    uploading[field] = isUploading
    setUploadingPhotos(uploading)
  }

  const getImages = (fieldName, images) => {
    return images.length ? images: []
  }

  return (
    <Card ref={ref} elevation={0}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={props.initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                    <Grid container alignContent="stretch" alignItems="stretch" justify="center" spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='subtitle1'>Logo Image</Typography>
                      </Grid>
                      {
                        !props.disableForm && (
                          <Grid item xs={12} sm={6}>
                            <Field name="logoImage" validate={required}>
                              {({ input, meta }) => (
                                <FormControl fullWidth required error={isFieldError(meta)}>
                                  <ImageUploader
                                    id="logo-image"
                                    {...input}
                                    className={classes.imageUploader}
                                    onChange={(files, pictures) => handleOnImagesChanged(files, pictures, input)}
                                    singleImage
                                    imgExtension={['.jpg', '.png', '.jpeg']}
                                    label="Max file size: 5mb, accepted: jpg, jpeg, png"
                                    buttonText="Upload image"
                                    maxFileSize={maxImageSize}
                                  />
                                  <FormHelperText>{getFieldErrorMessage(meta)}</FormHelperText>
                                </FormControl>
                              )}
                            </Field>
                          </Grid>
                        )
                      }
                      <Grid item xs={12} sm={props.disableForm ? 12: 6}>
                        <Field name="logoImage" subscription={{ value: true }}>
                          {({ input: { name, value: logoImage } }) => (
                            <ImagePreview
                              disabled={props.disableForm}
                              itemClassName={classes.logoPreview}
                              uploading={uploadingPhotos[name]}
                              label={'Logo Image'}
                              images={getImages(name, logoImage)}/>
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                    <Grid container alignContent="stretch" alignItems="stretch" justify="center" spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='subtitle1'>Featured Photo</Typography>
                      </Grid>
                      {
                        !props.disableForm && (
                          <Grid item xs={12} sm={6}>
                            <Field name="featuredImage" validate={required}>
                              {({ input, meta }) => (
                                <FormControl fullWidth required error={isFieldError(meta)}>
                                  <ImageUploader
                                    id="featured-photo"
                                    {...input}
                                    className={classes.imageUploader}
                                    onChange={(files, pictures) => handleOnImagesChanged(files, pictures, input)}
                                    singleImage
                                    imgExtension={['.jpg', '.png', '.jpeg']}
                                    label="Max file size: 5mb, accepted: jpg, jpeg, png"
                                    buttonText="Upload image"
                                    maxFileSize={maxImageSize}
                                  />
                                  <FormHelperText>{getFieldErrorMessage(meta)}</FormHelperText>
                                </FormControl>
                              )}
                            </Field>
                          </Grid>
                        )
                      }
                      <Grid item xs={12} sm={props.disableForm ? 12: 6}>
                        <Field name="featuredImage" subscription={{ value: true }}>
                          {({ input: { name, value: featuredImage } }) => (
                            <ImagePreview
                              disabled={props.disableForm}
                              itemClassName={classes.featuredPreview}
                              uploading={uploadingPhotos[name]}
                              label="Featured Photo"
                              imageSize={heroSize}
                              images={getImages(name, featuredImage)}/>
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                    <Grid container alignContent="stretch" alignItems="stretch" justify="center" spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='subtitle1'>Product Gallery Photos</Typography>
                      </Grid>
                      {
                        !props.disableForm && (
                          <Grid item xs={12}>
                            <Field name="galleryImages" validate={required}>
                              {({ input, meta }) => (
                                <FormControl fullWidth required error={isFieldError(meta)}>
                                  <ImageUploader
                                    ref={galleryImageUploaderRef}
                                    id="gallery-photos"
                                    {...input}
                                    className={classes.imageUploader}
                                    onChange={(files, pictures) => {
                                      const _pictures = handleGalleryImagesChanged(files, pictures)
                                      input.onChange(_pictures)
                                      uploadPhotos(
                                        input.name,
                                        _pictures
                                          .filter(p => isDataURL(p.src))
                                          .map((p, i) => ({ ...p, file: files[i] }))
                                      ).catch((e) => notify(`Error: ${e.errorMessage || e}`, 'warning'))
                                    }}
                                    buttonText="Upload Images"
                                    imgExtension={['.jpg', '.png', '.jpeg']}
                                    label="Max file size: 5mb, accepted: jpg, jpeg, png"
                                    maxFileSize={maxImageSize}
                                  />
                                  <FormHelperText>{getFieldErrorMessage(meta)}</FormHelperText>
                                </FormControl>
                              )}
                            </Field>
                          </Grid>
                        )
                      }
                      <Grid item xs={12}>
                        {
                          <ImagePreview
                            disabled={props.disableForm}
                            ref={galleryImagesPreview}
                            itemClassName={classes.featuredPreview}
                            uploading={uploadingPhotos.galleryImages}
                            label="Product Gallery Photos"
                            imageSize={heroSize}
                            dndAble
                            images={galleryImages.length ? galleryImages: []}
                            onUpdated={handleGalleryImagesUpdated}
                            onRemove={handleOnGalleryImageRemoved}
                          />
                        }
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
            {
              props.showFooter && (
                <CardContent>
                  <GiveCardFooter />
                </CardContent>
              )
            }
            {
              !props.disableForm && (
                <CardActions disableSpacing>
                  <Button
                    onClick={props.handleOnBackBtnClick}
                    variant="outlined"
                    color="primary"
                    disabled={props.saving}
                  >
                    Back
                  </Button>
                  {
                    props.saving && (
                      <CircularProgress size={32} className={giveCardFormClasses.rightBtn} />
                    )
                  }
                  <Button
                    className={props.saving ? giveCardFormClasses.spaceLeft : giveCardFormClasses.rightBtn}
                    onClick={event => onSaveDraft(event, handleSubmit)}
                    variant="outlined"
                    color="primary"
                    disabled={props.saving}
                    startIcon={<Save/>}
                  >
                    Save Draft
                  </Button>
                  <Button
                    className={giveCardFormClasses.spaceLeft}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={props.saving}
                  >
                    { props.isLastTab ? 'Submit' : 'Save & Next' }
                  </Button>
                </CardActions>
              )
            }
          </form>
        )}/>
    </Card>
  )
}

export default forwardRef(GCImageUploader)
