import { aor as ra } from 'aor-language-thai'
import merge from 'lodash/merge'

export default merge(
  {},
  { ra },
  {
    /* Start extending default React Admin Translation */
    ra: {
      page: {
        empty: '[th]No %{name} yet.',
        loading: '[th]Loading',
        invite: '[th]Do you want to add one?'
      },
      message: {
        loading: '[th]The page is loading, just a moment please'
      },
      action: {
        export: '[th]export',
        unselect: '[th]Unselect',
      },
      navigation: {
        no_results: '[th]No results found'
      }
    },
    resources: {
      givecards: {
        title: 'รายการ GiveCards',
        empty: '[th] No GiveCards yet.',
        invite: '[th] Do you want to create one?',
        footerText: 'สอบถามข้อมูลเพิ่มเติมกรุณาติดต่อ e-mail: Partnership@socialgiver.com\r\n' +
          'โทร 065 017 7841'
      },
      business: {
        title: 'รายการ Business',
        empty: '[th] No Business yet.',
        invite: '[th] Do you want to create one?'
      },
      users: {
        title: 'รายการ Users',
        empty: '[th] No Users yet.',
        invite: '[th] Do you want to create one?'
      }
    },
    /* End extending default React Admin Translation */

    socialgiver: {
      welcome: 'ยินดีต้อนรับสู่ Socialgiver',
      subTitle: 'มาร่วมสร้างความเปลี่ยนแปลงที่ดี และขับเคลื่อนสังคมแห่งการให้ไปด้วยกัน\r\n\r\n' +
        'สอบถามข้อมูลเพิ่มเติมกรุณาติดต่อ e-mail: Partnership@socialgiver.com\r\n' +
        'โทร 065 017 7841'
    }
  },
)
