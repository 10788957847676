import { UserManager } from '../../auth'

export async function request (_config) {
  const { withAuth = true, ...config } = _config
  if (withAuth) {
    const token = await UserManager.getCurrentUser()
      .then((user) => user.getIdToken())
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
  } else {
    delete config.headers['Authorization']
  }
  return config
}
