import React from 'react'
import { Edit } from 'react-admin'

import UserForm from './userForm'

export default function UserEdit ({ staticContext, ...props }) {
  return (
    <Edit {...props} title={`Edit User ${props.id}`}>
      <UserForm/>
    </Edit>
  )
}
