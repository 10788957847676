import {
  Button,
  Card,
  CardContent,
  CardActions,
  makeStyles,
  Paper,
  Grid,
  TextField,
  CardHeader,
  Typography,
  Box,
  Avatar,
  IconButton,
  Collapse
} from '@material-ui/core'
import { SendRounded, CheckCircleRounded, CheckCircleOutlined, ExpandMore } from '@material-ui/icons'
import clsx from 'clsx'
import Moment from 'moment'
import React, { useEffect, useMemo } from 'react'
import { useMutation, useNotify, useRefresh } from 'react-admin'
import { Field, Form } from 'react-final-form'

import { composeValidators, errorFieldProp } from '../../utils'
import { maxLength, required } from '../../validations'
import { giveCardFormStyles } from '../givecards/style'

const useStyles = makeStyles((theme) => ({
  issuerAvatar: {
    backgroundColor: (props) => props.isResolved
      ? theme.palette.success.main
      : theme.palette.primary.light
  },
  issueItem: {
    marginTop: theme.spacing(2)
  },
  doneIcon: {
    color: theme.palette.success.main,
  },
  issueTitle: {
    fontSize: '1.125rem',
    whiteSpace: 'pre-wrap',
  },
  issueMessage: {
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    borderWidth: 1,
    padding: theme.spacing(2),
  },
  messageItem: {
    fontSize: '1.25rem',
    whiteSpace: 'pre-wrap',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))

function IssueItem ({ issue, hasReviewPermission }) {
  // Mutation
  const [resolve, { loading: resolving, error: resolveError, data: resolveResp  }] = useMutation({
    type: 'update',
    resource: 'issues',
    payload: { id: issue.id, data: { status: 'resolved' } }
  })

  const [reply, { loading: replying, error: replyError, data: replyResp  }] = useMutation()

  const isResolved = useMemo(() => issue.status === 'resolved' || resolveResp, [issue.status, resolveResp])

  // State
  const [expanded, setExpanded] = React.useState(!isResolved)

  // Const
  const classes = useStyles({ isResolved })
  const notify = useNotify()
  const refresh = useRefresh()
  const giveCardFormClasses = giveCardFormStyles()
  const formControlProps = {
    required: true,
    fullWidth: true,
  }

  // Effect
  useEffect(() => {
    if (resolveError) {
      notify(`Error: ${resolveError.errorMessage || resolveError}`, 'warning')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolveError])

  useEffect(() => {
    if (replyError) {
      notify(`Error: ${replyError.errorMessage || replyError}`, 'warning')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replyError])

  useEffect(() => {
    if (resolveResp) {
      setExpanded(false)
      notify(`${issue.title} resolved.`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolveResp])

  useEffect(() => {
    if (replyResp) {
      refresh()
    }
  }, [refresh, replyResp])

  // Functions
  const getFirstStr = (str) => str?.substr(0, 1)?.toUpperCase() || null

  const handleOnReplyBtnClick = (values) => {
    reply({
      type: 'create',
      resource: `issues/${issue.id}/messages`,
      payload: { data: { message: values.message } }
    })
  }

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Card className={classes.issueItem} variant="outlined">
      <CardHeader
        avatar={
          <Avatar className={classes.issuerAvatar} variant="rounded" aria-label="issuer">{getFirstStr(issue.user?.displayName)}</Avatar>
        }
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMore />
          </IconButton>
        }
        title={issue.title}
        subheader={`by: ${issue.user?.displayName || ''}`}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Form
          onSubmit={handleOnReplyBtnClick}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <CardContent>
                <Box>
                  <Grid container justify="flex-start" alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography variant="caption">
                        {`Issue Date: ${Moment(issue.createdAt).format('LLL')}`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {
                        isResolved && (
                          <IconButton
                            className={classes.doneIcon}
                            size="small"
                            aria-label="issue-resolved">
                            <CheckCircleOutlined />
                          </IconButton>
                        )
                      }
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Typography className={classes.issueTitle} component="p">
                    {issue.detail}
                  </Typography>
                </Box>
                <Box mt={2} hidden={isResolved && !issue.messages.length}>
                  <Grid container spacing={2}>
                    {
                      issue.messages.length > 0 && (
                          <Grid container item xs={12} spacing={1}>
                            {
                              issue.messages.map((message, index) => (
                                <Grid item xs={12} key={message.id}>
                                  <Paper variant="outlined" className={classes.issueMessage}>
                                    <Box mb={2} display="flex">
                                      <Avatar>{getFirstStr(message.user?.displayName)}</Avatar>
                                      <Box ml={2}>
                                        <Typography variant="body2">{message.user?.displayName}</Typography>
                                        <Typography variant="caption">
                                          {
                                            Moment(message.createdAt).format('LLL')
                                          }
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Typography className={classes.messageItem}>{message.message}</Typography>
                                  </Paper>
                                </Grid>
                              ))
                            }
                          </Grid>
                        )
                    }
                    {
                      !isResolved && (
                        <Grid item xs={12}>
                          <Field name="message" validate={composeValidators(required, maxLength(1000))}>
                            {({ input, meta }) => (
                              <TextField
                                {...formControlProps}
                                {...input}
                                id="issue-item-message"
                                label="Message"
                                variant="outlined"
                                multiline
                                rowsMax={10}
                                inputProps={{ maxLength: 1000 }}
                                {...errorFieldProp(meta)}
                              />
                            )}
                          </Field>
                        </Grid>
                      )
                    }
                  </Grid>
                </Box>
              </CardContent>
              {
                !isResolved && (
                  <CardActions disableSpacing>
                    {
                      hasReviewPermission && (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={resolve}
                          disabled={resolving}
                          startIcon={<CheckCircleRounded/>}
                        >
                          Resolved
                        </Button>
                      )
                    }
                    <Button
                      className={giveCardFormClasses.rightBtn}
                      type="submit"
                      variant="outlined"
                      color="primary"
                      disabled={replying}
                      startIcon={<SendRounded/>}
                    >
                      Reply
                    </Button>
                  </CardActions>
                )
              }
            </form>
          )}/>
      </Collapse>
    </Card>
  )
}

export default IssueItem
