import React from 'react'
import { AppBar } from 'react-admin'

import CustomUserMenu from './userMenu'

export default function CustomAppBar (props) {
  return (
    <AppBar {...props} userMenu={<CustomUserMenu />} />
  )
}

