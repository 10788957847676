import React from 'react'
import { Login, LoginForm } from 'react-admin'

import { useColorScheme } from '../hooks'
import { BG1, BG2 } from '../images'
import Theme from '../theme'
import { getRandomInt } from '../utils'

function CustomLogin (props) {
  const colorScheme = useColorScheme()
  return (
    <Login
      {...props}
      backgroundImage={[BG1, BG2][getRandomInt(0, 1)]}
      loginForm={<LoginForm/>}
      theme={Theme[colorScheme]}
    />
  )
}

export default CustomLogin
