import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Stepper,
  Step,
  StepButton,
  Paper,
  Typography,
  Box,
  Collapse,
  Grid,
  TextField,
  CircularProgress
} from '@material-ui/core'
import { CloseRounded, DoneRounded, Save, ImportExportRounded } from '@material-ui/icons'
import React, { useEffect, useMemo, useRef } from 'react'
import {
  Query,
  Loading,
  Error,
  useShowController,
  useRedirect,
  useNotify,
  useDataProvider,
  useRefresh,
  usePermissions,
  useAuthProvider
} from 'react-admin'
import { Field, Form } from 'react-final-form'

import * as Api from '../../providers/api'
import { composeValidators, errorFieldProp } from '../../utils'
import { maxLength, required } from '../../validations'
import { getDefaultValue, getGiveCardForms } from './create'
import type { GiveCardFormProps } from './create'
import { giveCardFormStyles } from './style'

export default function GiveCardShow ({ permissions, ...props }) {
  const {
    basePath,
    defaultTitle,
    loaded,
    record,
  } = useShowController(props)

  // State
  const [activeStep, setActiveStep] = React.useState(0)
  const [businessId, setBusinessId] = React.useState('add')
  const [showIssueForm, setShowIssueForm] = React.useState(false)
  const [projectList, setProjectList] = React.useState(null)
  const [givecardList, setGiveCardList] = React.useState(null)
  const [saving, setSaving] = React.useState(false)

  // Const
  const giveCardFormClasses = giveCardFormStyles()
  const formControlProps = {
    required: true,
    fullWidth: true,
  }
  const authProvider = useAuthProvider()
  const redirect = useRedirect()
  const notify = useNotify()
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const userPermissions = usePermissions()
  const isAdvanceSettingAvailable = useMemo(
    () => userPermissions.loaded && authProvider.checkPermissions(authProvider.ACTIONS.GIVECARD_ADVANCE, userPermissions.permissions),
    [authProvider, userPermissions.loaded, userPermissions.permissions],
  )
  const businessFormRef = useRef()
  const configurationFormRef = useRef()
  const detailsFormRef = useRef()
  const advanceSettingFormRef = useRef()
  const GiveCardForms = getGiveCardForms(isAdvanceSettingAvailable)[activeStep]

  useEffect(() => {
    if (loaded && record) {
      setBusinessId(record.businessId)
    }
  }, [loaded, record])

  useEffect(() => {
    if (activeStep === 4 && !projectList) {
      Api.getProjectList({ langCode: 'en' })
        .then(value => {
          setProjectList(value)
        })
        .catch(() => {
          setProjectList([])
        })
    }
  }, [activeStep, projectList])

  useEffect(() => {
    if (activeStep === 4 && !givecardList) {
      Api.getGiveCardList({ langCode: 'en' })
        .then(value => {
          setGiveCardList(value)
        })
        .catch(() => {
          setGiveCardList([])
        })
    }
  }, [activeStep, givecardList])

  // Functions
  const getFormRef = () => {
    switch (activeStep) {
      case 0:
        return businessFormRef
      case 1:
        return detailsFormRef
      case 2:
        return configurationFormRef
      case 4:
        return advanceSettingFormRef
      default:
        return null
    }
  }

  const getInitialValues = () => {
    if (!loaded) return null
    switch (activeStep) {
      case 0:
        return getDefaultValue(activeStep)
      case 1:
        return record.details || getDefaultValue(activeStep)
      case 2:
        return record.config || getDefaultValue(activeStep)
      case 3:
        return record.images || getDefaultValue(activeStep)
      case 4:
        return record.advanceSetting || getDefaultValue(activeStep)
      default:
        return null
    }
  }

  const handleOnStepItemClick = (step) => () => {
    setActiveStep(Math.min(step, getGiveCardForms(isAdvanceSettingAvailable).length - 1))
  }

  const handleOnBackBtnClick = () => {
    setActiveStep(Math.max(activeStep - 1, 0))
  }

  const handleOnNextBtnClick = () => {
    setActiveStep(Math.min(activeStep + 1, getGiveCardForms(isAdvanceSettingAvailable).length - 1))
  }

  const handleOnRejectBtnClick = () => {
    setShowIssueForm(true)
  }

  const handleOnCancelBtnClick = () => {
    setShowIssueForm(false)
  }

  const handleOnApproveBtnClick = () => {
    setSaving(true)
    dataProvider.update('givecards', {
      id: record.id,
      data: {
        status: 'approved'
      },
      previousData: {
        status: record.status
      }
    })
      .then(() => {
        setSaving(false)
        notify(`GiveCard Id: ${record.id} approved.`)
        refresh()
      })
      .catch((e) => {
        notify(`Error: ${e.errorMessage || e}`, 'warning')
        setSaving(false)
      })
  }

  const handleOnExportBtnClick = () => {
    setSaving(true)
    Api.exportToWP({ id: record.id })
      .then(() => {
        setSaving(false)
        notify(`GiveCard Id: ${record.id} exported.`)
        refresh()
      })
      .catch((e) => {
        notify(`Error: ${e.errorMessage || e}`, 'warning')
        setSaving(false)
      })
  }

  const onSubmit = (values) => {
    setSaving(true)
    dataProvider.create('issues', {
      data: { ...values, type: 'givecard', modelId: record.id }
    })
      .then(() => {
        setSaving(false)
        redirect('list', basePath)
        notify(`GiveCard Id: ${record.id} rejected.`)
      })
      .catch((e) => {
        notify(`Error: ${e.errorMessage || e}`, 'warning')
        setSaving(false)
      })
  }

  if (!loaded || !record) {
    return <Loading/>
  }

  // Setup form props
  const contentProps: GiveCardFormProps = {
    disableForm: true,
    initialValues: getInitialValues(activeStep),
    isLastTab: getGiveCardForms(isAdvanceSettingAvailable).length - 1 === activeStep,
  }

  if (activeStep === 0) {
    contentProps.setBusinessId = setBusinessId
    contentProps.selectedBusinessId = businessId
  }

  if (activeStep === 4) {
    contentProps.givecardList = givecardList
    contentProps.projectList = projectList
  }

  return (
    <Query type='getOne' resource='businesses' payload={{ id: record.businessId }}>
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading/>
        }
        if (error) {
          return <Error/>
        }

        if (activeStep === 0) {
          contentProps.businessList = [data]
        }

        return (
          <Card>
            <CardHeader title={defaultTitle}/>
            <CardContent>
              <Stepper
                activeStep={activeStep}
                nonLinear
                alternativeLabel>
                {getGiveCardForms(isAdvanceSettingAvailable).map(({ title }, index) => (
                  <Step key={title}>
                    <StepButton onClick={handleOnStepItemClick(index)}>{title}</StepButton>
                  </Step>
                ))}
              </Stepper>
              {loaded && GiveCardForms?.component && (
                <GiveCardForms.component {...contentProps} ref={getFormRef()}/>
              )}
            </CardContent>
            {
              record &&
              !['draft', 'published', 'sample'].includes(record.status) &&
              authProvider.checkPermissions(authProvider.ACTIONS.GIVECARD_REVIEW, permissions) && (
                <CardContent>
                  <Paper className={giveCardFormClasses.paperItem} variant="outlined">
                    <Typography>Review GiveCard</Typography>
                    <Box mt={1}>
                      <Form
                        onSubmit={onSubmit}
                        render={({ handleSubmit }) => (
                          <form onSubmit={handleSubmit} noValidate autoComplete="off">
                            <Collapse in={showIssueForm}>
                              <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <Field name="title" validate={required}>
                                      {({ input, meta }) => (
                                        <TextField
                                          {...formControlProps}
                                          {...input}
                                          id="issue-title"
                                          label="Reason"
                                          {...errorFieldProp(meta)}
                                        />
                                      )}
                                    </Field>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Field name="detail" validate={composeValidators(required, maxLength(1000))}>
                                      {({ input, meta }) => (
                                        <TextField
                                          {...formControlProps}
                                          {...input}
                                          id="issue-detail"
                                          label="Description"
                                          variant="outlined"
                                          multiline
                                          rowsMax={10}
                                          inputProps={{ maxLength: 1000 }}
                                          {...errorFieldProp(meta)}
                                        />
                                      )}
                                    </Field>
                                  </Grid>
                                </Grid>
                                <Box display="flex" mt={2}>
                                  <Button
                                    color="primary"
                                    variant="text"
                                    onClick={handleOnCancelBtnClick}
                                    disabled={saving}
                                  >
                                    Cancel
                                  </Button>
                                  {
                                    saving && (
                                      <CircularProgress size={32} className={giveCardFormClasses.rightBtn}/>
                                    )
                                  }
                                  <Button
                                    className={saving ? giveCardFormClasses.spaceLeft : giveCardFormClasses.rightBtn}
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={saving}
                                    startIcon={<Save/>}
                                  >
                                    Submit
                                  </Button>
                                </Box>
                              </Paper>
                            </Collapse>
                          </form>
                        )}/>
                    </Box>
                    <Box mt={1} display="flex">
                      <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<CloseRounded/>}
                        onClick={handleOnRejectBtnClick}
                        disabled={showIssueForm || saving}
                      >
                        Reject
                      </Button>
                      <Button
                        className={giveCardFormClasses.spaceLeft}
                        color="primary"
                        variant="contained"
                        startIcon={<DoneRounded/>}
                        onClick={handleOnApproveBtnClick}
                        disabled={record?.status === 'approved' || saving}
                      >
                        Approve
                      </Button>
                      {
                        record?.status === 'approved' && !record?.wpEnId && (
                          <Button
                            className={giveCardFormClasses.rightBtn}
                            color="primary"
                            variant="contained"
                            startIcon={<ImportExportRounded/>}
                            onClick={handleOnExportBtnClick}
                            disabled={saving}
                          >
                            Export To WP
                          </Button>
                        )
                      }
                    </Box>
                  </Paper>
                </CardContent>
              )
            }
            <CardActions disableSpacing>
              <Button
                onClick={handleOnBackBtnClick}
                variant="outlined"
                color="primary"
                disabled={activeStep === 0}
              >
                Back
              </Button>
              <Button
                className={giveCardFormClasses.rightBtn}
                onClick={handleOnNextBtnClick}
                variant="outlined"
                color="primary"
                disabled={contentProps.isLastTab}
              >
                Next
              </Button>
            </CardActions>
          </Card>
        )
      }}
    </Query>
  )
}
