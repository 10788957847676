import SettingsIcon from '@material-ui/icons/Settings'
import React, { useState, useEffect } from 'react'
import { UserMenu, MenuItemLink, useAuthProvider } from 'react-admin'

export default function CustomUserMenu (props) {
  const authProvider = useAuthProvider()
  const [user, setUser] = useState(null)

  useEffect(
    () => {
      authProvider.getCurrentUser()
        .then(setUser)
    },
    [authProvider],
  )

  return (
    <UserMenu {...props}>
      {
        user && (
          <MenuItemLink
            to={`/users/${user.uid}`}
            primaryText="Edit Account"
            leftIcon={<SettingsIcon/>}
          />
        )
      }
    </UserMenu>
  )
}
