import {
  Button,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Box,
  Grid,
  Typography,
  TextField,
  IconButton,
  List,
  ListItem,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  FormControl,
  FormHelperText,
  Chip,
  Avatar
} from '@material-ui/core'
import { Add, DeleteOutline, ExpandMore } from '@material-ui/icons'
import {
  ToggleButtonGroup,
  ToggleButton,
  Autocomplete
} from '@material-ui/lab'
import arrayMutators from 'final-form-arrays'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Loading, useNotify, usePermissions, useRefresh, useDataProvider } from 'react-admin'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import ImageUploader from 'react-images-upload'

import CustomRoutes from '../../customRoutes'
import { Auth } from '../../providers'
import * as Api from '../../providers/api'
import { convertToSlug, errorFieldProp, getFieldErrorMessage, isFieldError } from '../../utils'
import { required } from '../../validations'
import ImagePreview from '../givecards/imagePreview'
import { giveCardFormStyles } from '../givecards/style'
import GCMap, { defaultMapProps } from './gcMap'
import dataAutoComplete from './givecardHighlight.json'
import GivecardIconList from './givecardIconList'

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    width: '100%',
    padding: theme.spacing(1),
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    borderWidth: 1,
  },
  imageUploader: {
    '& .fileContainer': {
      textAlign: 'center',
      padding: theme.spacing(2),
      marginTop: 0,
      marginBottom: 0,
    },
    '& .chooseFileButton': {
      background: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
      pointerEvents: props => props.isUploading ? 'none' : 'unset',
      filter: props => props.isUploading ? 'grayscale(90%)' : 'unset',
      '&:hover': {
        background: theme.palette.primary.main,
      }
    },
  },
  previewLayout: {
    width: '100%',
    borderStyle: 'dashed',
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.secondary.main,
    borderWidth: 1,
  },
  leftForm: {
    marginBottom: 'auto',
  },  
  textCapitalize: {  
    textTransform: 'capitalize'
  },
  mapLayout: {
    width: '100%',
    height: '440px',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    background: theme.palette.text.secondary,
  },
  iconsField: {
  },
  toggleBtn: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
}))

const SubmitAction = {
  verify: 'verify',
  confirm: 'confirm'
}

const Menu = {
  setIcon: 'setIcon',
  resetCache: 'resetCache',
  chooseGCIcon: 'chooseGCIcon',
  givecardMeta: 'givecardMeta',
  default: 'none'
}

const renderAutocompleteItem = (value, getTagProps) => value?.map((option, index) => (
  <Chip
    avatar={<Avatar variant="circle" alt={option.name} src={option.path} />}
    color="default"
    label={option.name?.split('.')[0]}
    {...getTagProps({ index })} />
)) || null

const renderAutocompleteInput = (params) => (
  <TextField
    {...params}
    label="Icons"
    variant="outlined"
    size="small"
    margin="none"/>
)

function WpUtilities () {
  // State
  const [loading, setLoading] = useState(false)
  const [activeMenu, setActiveMenu] = useState(Menu.default)
  const [uploadingPhotos, setUploadingPhotos] = React.useState({})
  const [previewGiveCardItems, setPreviewGiveCardItems] = useState(null)
  const [giveCardIconList, setGiveCardIconList] = useState(null)
  const [initialValues, setInitialValues] = useState({})
  const [contentLang, setContentLang] = useState('en')

  // Memo
  const isUploading = useMemo(() => {
    return Object.keys(uploadingPhotos).some((k) => uploadingPhotos[k])
  }, [uploadingPhotos])

  // Const
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const classes = useStyles({ isUploading })
  const refresh = useRefresh()
  const giveCardFormClasses = giveCardFormStyles()
  const { checkPermissions, ACTIONS } = Auth
  const { loaded: permissionLoaded, permissions } = usePermissions()
  const maxImageSize = 1024 * 1024 * 5 // 5mb
  const formControlProps = {
    fullWidth: true,
    required: true,
    size: 'small',
    variant: 'outlined'
  }

  const optionsTh = () => {
    var data = []
    Object.keys(dataAutoComplete.th).forEach(key => {
      dataAutoComplete.th[key].forEach(r => {
        data.push({ category: key, title: r })
      })
    })
    return data
  }

  const optionsEn = () => {
    var data = []
    Object.keys(dataAutoComplete.en).forEach(key => {
      dataAutoComplete.en[key].forEach(r => {
        data.push({ category: key, title: r })
      })
    })
    return data
  }

  // Memo
  const hasWPAdminPermission = useMemo(() => {
    if (permissionLoaded) {
      return checkPermissions(ACTIONS.WP_ADMIN, permissions)
    } else {
      return false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionLoaded, permissions])

  const previewGiveCardItem = useMemo(() => () => {
    return previewGiveCardItems && previewGiveCardItems.length
      ? previewGiveCardItems[0]
      : null
  }, [previewGiveCardItems])

  const hasPreviewItem = useMemo(() => () => {
      return previewGiveCardItems?.length > 0
    }
    , [previewGiveCardItems])

  const isSingleProduct = useMemo(() => () => {
      return previewGiveCardItems && previewGiveCardItems.length
        ? previewGiveCardItems[0].parentId === 0
        : false
    }
    , [previewGiveCardItems])

  // Effect
  useEffect(() => {
    if (!giveCardIconList && activeMenu === Menu.chooseGCIcon) {
      dataProvider.getList('images',
        {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'id', order: 'DESC' },
          filter: { key: 'givecard-icon' }
        })
        .then(({ data }) => {
          setGiveCardIconList(data)
        })
        .catch((e) => {
          setGiveCardIconList([])
          notify(`Error: ${e.errorMessage || e}`, 'warning')
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenu, giveCardIconList])

  useEffect(() => { setPreviewGiveCardItems(null) }, [activeMenu])

  // Functions
  const getImages = (fieldName, images) => {
    return images.length ? images: []
  }

  const handleExpandClick = (panel) => (event, isExpanded) => {
    setActiveMenu(isExpanded ? panel : Menu.default)
  }

  const handleOnImagesChanged = (files, pictures, input) => {
    const values = pictures.map((picture, i) => ({ src: picture, name: files[i].name, file: files[i] }))
    input.onChange(values)
  }

  const handleSubmitBtnClick = (setFormValue, action) => () => {
    setFormValue('submitAction', action)
    setFormValue('setFormValue', setFormValue)
  }

  const handleOnClearPreviewData = () => {
    setInitialValues({})
    setPreviewGiveCardItems([])
  }

  const handleOnGCMapLocationChanged = (latLng) => {
    setInitialValues({
      givecardMeta: {
        ...initialValues.givecardMeta,
        ...latLng
      }
    })
  }

  const setGiveCardIcon = async (values) => {
    const { givecardIcon, iconName } = values
    updateUploadingPhotos('givecardIcon', true)
    const uploadIcons = givecardIcon.map(({ file }) => Api.uploadGiveCardIcon({
      image: file,
      iconName: convertToSlug(iconName)
    }))
    Api.logAction({ action: 'upload givecard icon' })
      .then((ignored) => {})
    await Promise.all(uploadIcons)
      .then(() => {
        notify(`${iconName} uploaded.`)
        refresh()
      })
      .catch((error) => notify(`upload fail: ${error.message}`, 'warning'))
    updateUploadingPhotos('givecardIcon', false)
  }

  const chooseGCIcon = async (values) => {
    if (values.chooseGiveCardIcon) {
      switch (values.submitAction) {
        case SubmitAction.confirm:
          await Api.updateGiveCardIcons({ givecards: values.chooseGiveCardIcon?.map(({ id, icons }) => ({
              id,
              icons: icons.map(({ name }) => name).join(',')
          })) })
            .then(() => {
              setPreviewGiveCardItems([])
              notify('Successfully update GiveCard icons.')
            })
            .catch((e) => notify(`Error: ${e.errorMessage || e}`, 'warning'))

          Api.logAction({ action: 'set givecard icon' })
            .then((ignored) => {})
          break
        case SubmitAction.verify:
          await Api.getGiveCardByIds({ ids: values.chooseGiveCardIcon?.map(({ id }) => id) })
            .then((resp) => {
              resp[0].title = (resp[0].id === resp[0].en.id_with_lang) ? resp[0].en.title : resp[0].th.title || ''              
              setPreviewGiveCardItems(resp)
              if (giveCardIconList && giveCardIconList.length) {
                resp.forEach((item, index) => {
                  const iconOptionItem = giveCardIconList.filter(({ name }) => item.icons?.split(',')?.includes(name))
                  if (iconOptionItem.length) {
                    values.setFormValue(`chooseGiveCardIcon[${index}].icons`, iconOptionItem)
                  }
                })
              }
            })
            .catch((e) => notify(`Error: ${e.errorMessage || e}`, 'warning'))
          break
        default: break
      }
    }
  }

  const updateGiveCardMeta = async (values, dirtyFields) => {
    if (values.givecardMeta) {
      switch (values.submitAction) {
        case SubmitAction.confirm:
          const { givecardMeta } = values
          const updateData = {
            id: givecardMeta.actual_id,
            lat: givecardMeta.lat,
            lng: givecardMeta.lng,
            en: {},
            th: {}
          }
          delete dirtyFields['givecardMeta.id']
          
          Object.keys(dirtyFields).forEach(k => {
            if (dirtyFields[k]) {
              const metaKey = k.split('.')[1]
              const highlightKey = 'highlight'
              const locTextKey = 'locText'
              const isGCHighlightMeta = metaKey.startsWith(highlightKey)

              if (isGCHighlightMeta && (!updateData.en[highlightKey] || !updateData.th[highlightKey])) {
                updateData.en[highlightKey] = givecardMeta.highlight.map(text => text.en)
                updateData.th[highlightKey] = givecardMeta.highlight.map(text => text.th)
              } else if (givecardMeta[metaKey] && !isGCHighlightMeta) {
                if (metaKey === 'locTexten' || metaKey === 'locTextth') {
                  updateData.en[locTextKey] = givecardMeta.locTexten
                  updateData.th[locTextKey] = givecardMeta.locTextth
                }
                else updateData[metaKey] = givecardMeta[metaKey]
              }
            }
          })

          Api.logAction({ action: 'update givecard meta', meta: updateData })
            .then((ignored) => {})
          setLoading(true)

          await Api.updateGiveCardMeta({ givecard: updateData })
            .then(() => {
              handleOnClearPreviewData()
              notify('Successfully update GiveCard meta.')
            })
            .catch((e) => notify(`Error: ${e.errorMessage || e}`, 'warning'))

          setLoading(false)
          break
        case SubmitAction.verify:
          setLoading(true)
          await Api.getGiveCardByIds({ ids: [values.givecardMeta.id] })
            .then((resp) => {
              const mapHighlight = (highlight) => {
                return highlight
                ? JSON.parse(highlight).map(h => {
                  let text = h
                  try { text = decodeURIComponent(h) } catch (ignored) { }
                  return text
                })
                : resp[0].post_parent === 0
                ? ['', '']
                : []
              }
              const en = mapHighlight(resp[0].en.highlight)
              const th = mapHighlight(resp[0].th.highlight)                
              var highlight = []

              for (var index = 0 ; index < ((en.length > th.length) ? en.length : th.length) ; index++) {
                highlight.push({ en: en[index] || '', th: th[index] || '' })
              }
              const gcMeta = resp.map(r => ({
                id: r.id || 0,
                actual_id: r.en.id_with_lang || 0,
                parentId: r.post_parent || 0,
                menuOrder: r.menu_order || 0,
                title: (r.id === r.en.id_with_lang) ? r.en.title : r.th.title || '',
                locTexten: r.en.loc_display_text || '',
                locTextth: r.th.loc_display_text || '',
                night: parseInt(r.hotel_night || 0),
                guest: parseInt(r.hotel_guest || 0),
                highlight: highlight,
                stock: r.stock || 0,
                lat: parseFloat(r.loc_lat || defaultMapProps.defaultCenter.lat),
                lng: parseFloat(r.loc_lng || defaultMapProps.defaultCenter.lng)
              }))
              if (gcMeta.length) {
                setInitialValues({
                  givecardMeta: gcMeta[0]
                })
                setPreviewGiveCardItems(gcMeta)
              }
            })
            .catch(() => notify('Givecard not found.', 'warning'))
          setLoading(false)
          break
        default: break
      }
    }
  }

  const updateUploadingPhotos = (field, isUploading) => {
    let uploading = { ...uploadingPhotos }
    uploading[field] = isUploading
    setUploadingPhotos(uploading)
  }

  const validate = (values) => {
    const validationResult = {}
    switch (activeMenu) {
      case Menu.setIcon:
        validationResult.givecardIcon = required(values.givecardIcon)
        validationResult.iconName = required(values.iconName)
        break
      case Menu.givecardMeta:
        const { givecardMeta = {} } = values
        let givecardMetaError = {
          id: required(givecardMeta.id),
        }
        if (hasPreviewItem() && Object.keys(givecardMeta).length) {
          if (isSingleProduct()) {
            givecardMetaError.locTexten = required(givecardMeta.locTexten)
            givecardMetaError.locTextth = required(givecardMeta.locTextth)
            givecardMetaError.night = required(givecardMeta.night)
            givecardMetaError.guest = required(givecardMeta.guest)
            givecardMetaError.highlight = (givecardMeta.highlight || []).map(h => ({ en: required(h.en), th: required(h.th) }))
          }
          givecardMetaError.menuOrder = required(givecardMeta.menuOrder)
          givecardMetaError.stock = required(givecardMeta.stock)
          givecardMetaError.lat = required(givecardMeta.lat)
          givecardMetaError.lng = required(givecardMeta.lng)
        }
        validationResult.givecardMeta = givecardMetaError
        break
      default: break
    }
    return validationResult
  }

  const handleContentLangToggle = (event, newLang) => {
    if (newLang !== null) {
      setContentLang(newLang)
    }
  }

  // Callback
  const isActive = useCallback(
    (menu) => activeMenu === menu,
    [activeMenu],
  )

  const callResetCache = useCallback(
    async () => {
      if (loading) return
      setLoading(true)

      await Api.resetCache()
        .then(() => notify('Successfully reset cache.'))
        .catch((e) => notify(`Error: ${e.errorMessage || e}`, 'warning'))

      Api.logAction({ action: 'reset cache' })
        .then((ignored) => {})

      setLoading(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading],
  )

  const onSubmit = useCallback( async (values, form) => {
      if (loading) return
      setLoading(true)
      switch (activeMenu) {
        case Menu.setIcon:
          await setGiveCardIcon(values)
          break
        case Menu.chooseGCIcon:
          await chooseGCIcon(values)
          break
        case Menu.givecardMeta:
          await updateGiveCardMeta(values, form.getState().dirtyFields)
          break
        default: break
      }
      setLoading(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, activeMenu, giveCardIconList],
  )

  // Show Loading...
  if (!permissionLoaded) return <Loading/>

  if (!hasWPAdminPermission) return (
    <Card>
      <CardHeader title={CustomRoutes.wpUtilities.label}/>
      <CardContent>
        <Typography>Permission Denied</Typography>
      </CardContent>
    </Card>
  )

  return (
    <Card>
      <CardHeader title={CustomRoutes.wpUtilities.label}/>
      <CardContent>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          mutators={{ ...arrayMutators }}
          render={({ handleSubmit, form: { mutators: { push }, change: setFormValue } }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <Accordion expanded={isActive(Menu.setIcon)} onChange={handleExpandClick(Menu.setIcon)}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel-icon-content"
                  id="panel-icon-header"
                >
                  <Typography>Set Icon</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {
                      activeMenu === Menu.setIcon && (
                        <Grid item xs={12}>
                          <GivecardIconList/>
                        </Grid>
                      )
                    }
                    <Grid item xs={12}>
                      <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="iconName">
                        {({ input, meta }) => (
                          <TextField
                            {...formControlProps}
                            {...input}
                            id="gc-icon-image"
                            label="Icon Name"
                            {...errorFieldProp(meta)}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="givecardIcon">
                        {({ input, meta }) => (
                          <FormControl fullWidth required error={isFieldError(meta)}>
                            <ImageUploader
                              id="logo-image"
                              {...input}
                              className={classes.imageUploader}
                              onChange={(files, pictures) => handleOnImagesChanged(files, pictures, input)}
                              singleImage
                              imgExtension={['.png']}
                              label="Max file size: 5mb, accepted: png 1:1 256x256"
                              buttonText="Upload image"
                              maxFileSize={maxImageSize}
                            />
                            <FormHelperText>{getFieldErrorMessage(meta)}</FormHelperText>
                          </FormControl>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="givecardIcon" subscription={{ value: true }}>
                        {({ input: { name, value: logoImage } }) => (
                          <ImagePreview
                            itemClassName={classes.logoPreview}
                            uploading={uploadingPhotos[name]}
                            label={'GiveCard Icon'}
                            imageSize={[{ w: 256, h: 256 }]}
                            images={getImages(name, logoImage)}/>
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </AccordionDetails>
                <Divider/>
                <AccordionActions>
                  <Button
                    type="submit"
                    variant="text"
                    color="primary"
                    disabled={loading || uploadingPhotos['givecardIcon']}
                  >
                    Add Icon
                  </Button>
                </AccordionActions>
              </Accordion>
              <FieldArray name="chooseGiveCardIcon">
                {({ fields }) =>(
                  <Accordion expanded={isActive(Menu.chooseGCIcon)} onChange={handleExpandClick(Menu.chooseGCIcon)}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel-givecard-icon-content"
                      id="panel-givecard-icon-header"
                    >
                      <Typography>Select GiveCard Icon</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box width="100%">
                        <Grid container spacing={2}>
                          {
                            fields.map((name, index) => (
                              <Grid container item xs={12} alignItems="flex-start" spacing={2} key={`${name}-${index}`}>
                                <Field name={`${name}.id`} subscription={{ value: true }}>
                                  {({ input: { value } }) => {
                                    const previewItem = previewGiveCardItems?.find(item => item.id === parseInt(value))
                                    return value && previewItem && (
                                      <Grid item xs={12}>
                                        <Typography variant="subtitle2">{previewItem.title}</Typography>
                                      </Grid>
                                    )
                                  }}
                                </Field>
                                <Grid item xs={4}>
                                  <Field name={`${name}.id`}>
                                    {({ input, meta }) => (
                                      <TextField
                                        {...formControlProps}
                                        {...input}
                                        id="gc-id"
                                        label="GiveCard ID"
                                        {...errorFieldProp(meta)}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={7}>
                                  <Field name={`${name}.icons`}>
                                    {({ input }) => previewGiveCardItems && (
                                      <Autocomplete
                                        id="icons"
                                        className={classes.iconsField}
                                        {...input}
                                        onChange={(event, newValue) => {
                                          input.onChange(newValue)
                                        }}
                                        size="medium"
                                        fullWidth
                                        limitTags={4}
                                        multiple
                                        options={giveCardIconList}
                                        loading={!giveCardIconList}
                                        getOptionLabel={option => `${option.name?.split('.')[0]}`}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        renderTags={renderAutocompleteItem}
                                        renderOption={option => (
                                          <>
                                            <Avatar variant="circle" alt={option.name} src={option.path} />
                                            <Box ml={1}>
                                              <Typography>{option.name?.split('.')[0]}</Typography>
                                            </Box>
                                          </>
                                        )}
                                        renderInput={renderAutocompleteInput}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid container item xs={1}>
                                  <IconButton
                                    className={giveCardFormClasses.rightBtn}
                                    size="small"
                                    aria-label="remove"
                                    disabled={loading}
                                    onClick={() => fields.remove(index)}>
                                    <DeleteOutline />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ))
                          }
                        </Grid>
                      </Box>
                    </AccordionDetails>
                    <Divider />
                    <AccordionActions disableSpacing>
                      <Grid container item xs={12}>
                        <Button
                          variant="text"
                          size="small"
                          disabled={loading}
                          onClick={() => push(fields.name, { icons: [] })}
                          startIcon={<Add />}>
                          Add
                        </Button>
                        {
                          isActive(Menu.chooseGCIcon) && previewGiveCardItems?.length ? (
                                <Button
                                  className={giveCardFormClasses.rightBtn}
                                  type="submit"
                                  variant="text"
                                  onClick={handleSubmitBtnClick(setFormValue, SubmitAction.confirm)}
                                  color="primary">
                                  Confirm
                                </Button>
                          ) : null
                        }
                        <Field name="chooseGiveCardIcon" subscription={{ value: true }}>
                          {({ input: { value } }) =>  (value.length ? (
                            <Button
                              className={isActive(Menu.chooseGCIcon) && previewGiveCardItems?.length
                                ? giveCardFormClasses.spaceLeft
                                : giveCardFormClasses.rightBtn
                              }
                              type="submit"
                              variant="text"
                              disabled={loading}
                              onClick={handleSubmitBtnClick(setFormValue, SubmitAction.verify)}
                            >
                              Verify
                            </Button>
                          ) : null )}
                        </Field>
                      </Grid>
                    </AccordionActions>
                  </Accordion>
                )}
              </FieldArray>
              <Accordion expanded={isActive(Menu.resetCache)} onChange={handleExpandClick(Menu.resetCache)}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel-resetcache-content"
                  id="panel-resetcache-header"
                >
                  <Typography>Reset Cache</Typography>
                </AccordionSummary>
                <AccordionActions>
                  <Button
                    variant="text"
                    color="primary"
                    disabled={loading}
                    onClick={callResetCache}
                  >
                    reset cache
                  </Button>
                </AccordionActions>
              </Accordion>
              <Accordion expanded={isActive(Menu.givecardMeta)} onChange={handleExpandClick(Menu.givecardMeta)}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel-givecard-meta-content"
                  id="panel-givecard-meta-header"
                >
                  <Typography>Update Givecard Meta</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box width="100%">
                    <Grid container spacing={2}>
                      <Grid item xs={10}>
                        <Field name="givecardMeta.id">
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              {...input}
                              disabled={isActive(Menu.givecardMeta) && hasPreviewItem()}
                              id="gc-id"
                              label="GiveCard ID"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      {
                        isActive(Menu.givecardMeta) && hasPreviewItem() ? (
                          <>
                            <Grid item xs={1}>
                              <Button
                                variant="outlined"
                                disabled={loading}
                                onClick={handleOnClearPreviewData}
                              >
                                Clear Data
                              </Button>
                            </Grid>
                            <Grid className={classes.leftForm} container item xs={6} alignItems="flex-start" spacing={2}>
                              <Grid item xs={6}>
                                <Field name="givecardMeta.lat">
                                  {({ input, meta }) => (
                                    <TextField
                                      {...formControlProps}
                                      required={false}
                                      {...input}
                                      variant="outlined"
                                      id="gc-loc-lat"
                                      label="Lat"
                                      disabled
                                      {...errorFieldProp(meta)}
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={6}>
                                <Field name="givecardMeta.lng">
                                  {({ input, meta }) => (
                                    <TextField
                                      {...formControlProps}
                                      required={false}
                                      {...input}
                                      variant="outlined"
                                      id="gc-loc-lng"
                                      label="Lng"
                                      disabled
                                      {...errorFieldProp(meta)}
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={12}>
                                <div className={classes.mapLayout}>
                                  {
                                    hasPreviewItem() ? (
                                      <GCMap
                                        location={{ lat: initialValues?.givecardMeta?.lat, lng: initialValues?.givecardMeta?.lng }}
                                        onLocationChanged={handleOnGCMapLocationChanged}
                                      />
                                    ) : null
                                  }
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container item xs={6}>
                              <List className={classes.previewLayout} dense>
                                <ListItem>
                                  <Grid container item xs={12} alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12}>
                                      <Typography variant="subtitle2">{previewGiveCardItem().title}</Typography>
                                      {
                                        !isSingleProduct()
                                          ? (<Typography variant="caption">{`ParentId: ${previewGiveCardItem().parentId}`}</Typography>)
                                          : null
                                      }
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Field name="givecardMeta.stock">
                                        {({ input, meta }) => (
                                          <TextField
                                            {...formControlProps}
                                            {...input}
                                            type="number"
                                            id="gc-stock"
                                            label="Stock"
                                            inputProps={{ min: 0 }}
                                            {...errorFieldProp(meta)}
                                          />
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Field name="givecardMeta.menuOrder">
                                        {({ input, meta }) => (
                                          <TextField
                                            {...formControlProps}
                                            {...input}
                                            type="number"
                                            id="gc-menu-order"
                                            label="MenuOrder"
                                            {...errorFieldProp(meta)}
                                          />
                                        )}
                                      </Field>
                                    </Grid>
                                    {
                                      isSingleProduct()
                                        ? (
                                          <>
                                            <Grid item xs={6}>
                                              <Field name="givecardMeta.night">
                                                {({ input, meta }) => (
                                                  <TextField
                                                    {...formControlProps}
                                                    {...input}
                                                    type="number"
                                                    id="gc-night"
                                                    label="Night"
                                                    inputProps={{ min: 0 }}
                                                    {...errorFieldProp(meta)}
                                                  />
                                                )}
                                              </Field>
                                            </Grid>
                                            <Grid item xs={6}>
                                              <Field name="givecardMeta.guest">
                                                {({ input, meta }) => (
                                                  <TextField
                                                    {...formControlProps}
                                                    {...input}
                                                    type="number"
                                                    id="gc-guest"
                                                    label="Guest"
                                                    inputProps={{ min: 0 }}
                                                    {...errorFieldProp(meta)}
                                                  />
                                                )}
                                              </Field>
                                            </Grid>
                                            <Grid item xs={12}>
                                              <ToggleButtonGroup
                                                value={contentLang}
                                                exclusive
                                                size="small"
                                                onChange={handleContentLangToggle}
                                                aria-label="content language"
                                              >
                                                {['en', 'th'].map(value => (
                                                  <ToggleButton
                                                    key={value}
                                                    value={value}
                                                    className={classes.toggleBtn}
                                                    aria-label={`${value} language`}
                                                  >
                                                    <Typography variant="button" color={contentLang === value ? 'textPrimary' : 'textSecondary'}>
                                                      {value}
                                                    </Typography>
                                                  </ToggleButton>
                                                ))}
                                              </ToggleButtonGroup>
                                            </Grid>
                                            <Grid item xs={12}>
                                              <Field name={`givecardMeta.locText${contentLang}`}>
                                                {({ input, meta }) => (
                                                  <TextField
                                                    {...formControlProps}
                                                    {...input}
                                                    id="gc-loc-text"
                                                    label='Location Text'
                                                    {...errorFieldProp(meta)}
                                                  />
                                                )}
                                              </Field>
                                            </Grid>
                                            <Grid item xs={12}>
                                              <FieldArray name={'givecardMeta.highlight'}>
                                                {({ fields }) => (
                                                  <Box width="100%">
                                                    <Grid container spacing={2}>
                                                      {
                                                        fields.map((name, index) => (
                                                          <Grid container item xs={12} alignItems="flex-start" spacing={1} key={`${name}-${index}`}>
                                                            <Grid item xs={11}>
                                                              <Field name={`${name}.${contentLang}`}>
                                                                {({ input, meta }) => (
                                                                  <Autocomplete
                                                                    {...formControlProps}
                                                                    freeSolo
                                                                    classes={{
                                                                      groupLabel: classes.textCapitalize,
                                                                    }}
                                                                    options={contentLang === 'en' ? optionsEn() : optionsTh()}
                                                                    onChange={(event, newValue) => {
                                                                      input.onChange(newValue != null ? newValue.title : '')
                                                                    }}
                                                                    onInput={e => {
                                                                      e.target.value = e.target.value.slice(0, contentLang === 'en' ? 26 : 37)
                                                                    }}
                                                                    onBlur={(newValue) => {
                                                                      input.onChange(newValue)
                                                                    }}
                                                                    groupBy={(option) => option.category}
                                                                    getOptionLabel={(option) => option.title}
                                                                    value={{ title: input.value }}
                                                                    renderInput={(params) =>
                                                                      <TextField
                                                                        {...params}
                                                                        id="gc-highlight-text"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        margin="none"
                                                                        label="Highlight"
                                                                        {...errorFieldProp(meta)}
                                                                      />}
                                                                  />
                                                                )}
                                                              </Field>
                                                            </Grid>
                                                            {
                                                              index > 1 && (
                                                                <Grid container item xs={1}>
                                                                  <IconButton
                                                                    className={giveCardFormClasses.rightBtn}
                                                                    size="small"
                                                                    aria-label="remove"
                                                                    disabled={loading}
                                                                    onClick={() => fields.remove(index)}>
                                                                    <DeleteOutline />
                                                                  </IconButton>
                                                                </Grid>
                                                              )
                                                            }
                                                          </Grid>
                                                        ))
                                                      }
                                                      <Grid item xs={12}>
                                                        <Button
                                                          variant="text"
                                                          size="small"
                                                          disabled={loading}
                                                          onClick={() => push(fields.name, {})}
                                                          startIcon={<Add />}>
                                                          Add
                                                        </Button>
                                                      </Grid>
                                                    </Grid>
                                                  </Box>
                                                )}
                                              </FieldArray>
                                            </Grid>
                                            <Grid item xs={12}>
                                              <Typography variant="body1" color="primary">
                                                Please input your highlight both in Eng and Thai.
                                              </Typography>
                                            </Grid>
                                          </>
                                        ) : null
                                    }
                                  </Grid>
                                </ListItem>
                              </List>
                            </Grid>
                          </>
                        ) : null
                      }
                    </Grid>
                  </Box>
                </AccordionDetails>
                <AccordionActions>
                  {
                    isActive(Menu.givecardMeta) && hasPreviewItem() ? (
                      <Button
                        className={giveCardFormClasses.rightBtn}
                        type="submit"
                        variant="text"
                        onClick={handleSubmitBtnClick(setFormValue, SubmitAction.confirm)}
                        disabled={loading}
                        color="primary">
                        Confirm
                      </Button>
                    ) : (
                      <Button
                        className={giveCardFormClasses.rightBtn}
                        type="submit"
                        variant="text"
                        disabled={loading}
                        onClick={handleSubmitBtnClick(setFormValue, SubmitAction.verify)}
                      >
                        Verify
                      </Button>
                    )
                  }
                </AccordionActions>
              </Accordion>
            </form>
          )}/>
      </CardContent>
    </Card>
  )
}

export default WpUtilities
