import { Permissions, Roles } from '@socialgiver/user-permissions'

import * as Utils from '../utils'
import Firebase from './firebase'

const Database = Firebase.database()
const DatabaseRef = Database.ref()

function getUserInformation (authUser) {
  return DatabaseRef
    .child(`Users/${authUser.uid}`)
    .once('value')
    .then((data) => data?.val())
}

const UserManager = function () {
  let userCb, permissionCb
  let currentUser = new Promise(function (resolve) {
    userCb = resolve
  })
  let permissions = new Promise(function (resolve) {
    permissionCb = resolve
  })

  Firebase.auth()
    .onAuthStateChanged(function (user) {
      if (userCb) {
        userCb(user)
        userCb = undefined
      }

      if (currentUser instanceof Promise || currentUser !== user) {
        if (user) {
          permissions = getUserInformation(user)
            .then((userInformation) => {
              if (userInformation.role !== undefined) {
                userInformation.roles = [userInformation.role]
                delete userInformation.role
              }
              const { permissions = [], roles = [] } = userInformation

              const obj = {}

              function walk (objOrArr) {
                if (Array.isArray(objOrArr)) {
                  objOrArr.forEach(walk)
                } else {
                  obj[objOrArr] = 1
                }
              }

              permissions.forEach(walk)
              roles.forEach((role) =>
                Roles[role.toLowerCase()]?.forEach(walk)
              )
              return obj
            })
        } else {
          permissions = Promise.resolve([])
        }

        if (permissionCb) {
          permissions.then(permissionCb)
          permissionCb = undefined
        }
      }

      currentUser = user
    })

  return {
    getUserPermissions: async () => permissions,
    getCurrentUser: async () => currentUser,
  }
}()

function checkPermissions (action, permissions) {
  if (!permissions) return false
  switch (action) {
    case ACTIONS.WP_ADMIN:
      return permissions[Permissions.WP_ADMIN]
    case ACTIONS.GIVECARD_LIST_ALL:
      return permissions[Permissions.GIVECARD_LIST_ALL]
    case ACTIONS.GIVECARD_LIST:
      return Utils._validatePermission(
        [
          Permissions.GIVECARD_LIST_OWN,
          Permissions.GIVECARD_LIST_ALL,
        ],
        permissions,
      )
    case ACTIONS.GIVECARD_SHOW:
      return Utils._validatePermission(
        [
          Permissions.GIVECARD_SHOW_OWN,
          Permissions.GIVECARD_SHOW_ALL,
        ],
        permissions,
      )
    case ACTIONS.GIVECARD_EDIT:
      return Utils._validatePermission(
        [
          Permissions.GIVECARD_EDIT_OWN,
          Permissions.GIVECARD_EDIT_ALL,
        ],
        permissions,
      )
    case ACTIONS.GIVECARD_EDIT_ALL:
      return permissions[Permissions.GIVECARD_EDIT_ALL]
    case ACTIONS.GIVECARD_CREATE:
      return permissions[Permissions.GIVECARD_CREATE]
    case ACTIONS.GIVECARD_ADVANCE:
      return permissions[Permissions.GIVECARD_ADVANCE]
    case ACTIONS.USER_ADMIN:
      return permissions[Permissions.USER_EDIT_ALL]
    case ACTIONS.USER_LIST:
      return Utils._validatePermission(
        [
          Permissions.USER_LIST_ALL,
        ],
        permissions,
      )
    case ACTIONS.USER_SHOW:
      return Utils._validatePermission(
        [
          Permissions.USER_SHOW_OWN,
          Permissions.USER_SHOW_ALL,
        ],
        permissions,
      )
    case ACTIONS.USER_EDIT:
      return Utils._validatePermission(
        [
          Permissions.USER_EDIT_OWN,
          Permissions.USER_EDIT_ALL,
        ],
        permissions,
      )
    case ACTIONS.USER_CREATE:
      return true
    case ACTIONS.BUSINESS_LIST_ALL:
      return permissions[Permissions.BUSINESS_LIST_ALL]
    case ACTIONS.BUSINESS_LIST:
      return Utils._validatePermission(
        [
          Permissions.BUSINESS_LIST_OWN,
          Permissions.BUSINESS_LIST_ALL,
        ],
        permissions,
      )
    case ACTIONS.BUSINESS_EDIT:
      return Utils._validatePermission(
        [
          Permissions.BUSINESS_EDIT_OWN,
          Permissions.BUSINESS_EDIT_ALL,
        ],
        permissions,
      )
    case ACTIONS.BUSINESS_CREATE:
      return Utils._validatePermission(
        [
          Permissions.BUSINESS_CREATE,
        ],
        permissions,
      )
    case ACTIONS.GIVECARD_REVIEW:
      return permissions[Permissions.GIVECARD_REVIEW]
    case ACTIONS.ISSUE_LIST:
      return Utils._validatePermission(
        [
          Permissions.ISSUE_LIST_ALL,
          Permissions.ISSUE_LIST_OWN,
        ],
        permissions,
      )
    default:
      return false
  }
}

export { UserManager }
export const ACTIONS = {
  WP_ADMIN: 'ap-admin',
  GIVECARD_LIST: 'givecard-list',
  GIVECARD_LIST_ALL: 'givecard-list-all',
  GIVECARD_SHOW: 'givecard-show',
  GIVECARD_EDIT: 'givecard-edit',
  GIVECARD_EDIT_ALL: 'givecard-edit-all',
  GIVECARD_CREATE: 'givecard-create',
  GIVECARD_ADVANCE: 'givecard-advance',
  GIVECARD_REVIEW: 'givecard-review',
  USER_ADMIN: 'user-admin',
  USER_LIST: 'user-list',
  USER_SHOW: 'user-show',
  USER_EDIT: 'user-edit',
  USER_CREATE: 'user-create',
  BUSINESS_LIST_ALL: 'business-list-all',
  BUSINESS_LIST: 'business-list',
  BUSINESS_EDIT: 'business-edit',
  BUSINESS_CREATE: 'business-create',
  ISSUE_LIST: 'issue-list',
}

export default {
  login: async function ({ username: email, password, ...p }) {
    console.log(email, p)
    return Firebase.auth().signInWithEmailAndPassword(email, password)
      .then((userCredential) => userCredential.user)
      .then(function (user) {
        if (!user) {
          throw new Error(email, p)
        }
      })
  },
  logout: async function () {
    return Firebase.auth().signOut()
  },
  checkAuth: async function (...p) {
    console.log('checkAuth', p)
    return UserManager.getCurrentUser()
      .then(function (user) {
        if (!user) {
          // eslint-disable-next-line no-throw-literal
          throw null
        }
      })
  },
  getCurrentUser: () => {
    return UserManager.getCurrentUser()
  },
  getPermissions: (route) => {
    console.log('getPermissions', route)
    return UserManager.getUserPermissions()
  },
  checkPermissions,
  ACTIONS,
  // check auth error
  checkError: async function ({ status, ...p }) {
    console.log('checkError', status, p)
    if (status === 401 || status === 403) {
      await Firebase.auth().signOut()
      throw new Error('Unauthorized')
    }
  },
}
