import { makeStyles } from '@material-ui/core'
import GoogleMapReact from 'google-map-react'
import React, { useEffect, useRef } from 'react'

const defaultMapProps = {
  defaultCenter: {
    lat: 13.7245601,
    lng: 100.4930264
  },
  defaultZoom: 17,
}

const useStyles = makeStyles((theme) => ({
  textSearch: {
    marginTop: '16px',
    padding: '8px',
    background: 'white',
    fontSize: '0.75rem',
    width: '80%',
    borderRadius: '4px',
    border: '2px solid #5A6469'
  }
}))

function GCMap ({ location: { lat, lng }, onLocationChanged }) {
  const classes = useStyles()
  const getPosition = () => {
    return lat && lng ? {
      lat: lat,
      lng: lng
    } : {
      lat: defaultMapProps.defaultCenter.lat,
      lng: defaultMapProps.defaultCenter.lng
    }
  }

  // setup map
  const mapRef = useRef(), markerRef = useRef(), locSearchRef = useRef()

  // Effect
  useEffect(() => {
    const position = getPosition()
    if (mapRef?.current && markerRef?.current) {
      mapRef.current.panTo(position)
      markerRef.current.setPosition(position)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng])

  const renderMarkers = (map, maps) => {
    mapRef.current = map
    const position = getPosition()
    let marker = new maps.Marker({
      position: position,
      map,
    })
    map.setCenter(position)
    markerRef.current = marker
    map.addListener('click', (mapsMouseEvent) => {
      if (onLocationChanged) {
        onLocationChanged(mapsMouseEvent.latLng.toJSON())
      }
    })

    // Set up AutoComplete
    const options = {
      componentRestrictions: { country: 'th' },
      fields: ['geometry', 'name'],
      origin: map.getCenter(),
    }
    map.controls[maps.ControlPosition.TOP_CENTER].push(locSearchRef.current)
    const autocomplete = new maps.places.Autocomplete(locSearchRef.current, options)
    autocomplete.bindTo('bounds', map)
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      if (place?.geometry?.location) {
        onLocationChanged(place?.geometry?.location.toJSON())
      }
    })

    return marker
  }

  return (
    <>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_API_KEY,
          libraries:['places'],
        }}
        defaultCenter={{
          lat: defaultMapProps.defaultCenter.lat,
          lng: defaultMapProps.defaultCenter.lng
        }}
        defaultZoom={defaultMapProps.defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      />
      <input
        ref={locSearchRef}
        id="pac-input"
        className={classes.textSearch}
        type="text"
        placeholder="Enter a location"
      />
    </>
  )
}

export { defaultMapProps }

export default GCMap
