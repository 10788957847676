import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  toolbar: {
    marginTop: 0,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    backgroundColor: 'white'
  },
  saveBtn: {
    marginLeft: 'auto'
  }
}))
