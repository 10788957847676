import { useMediaQuery } from '@material-ui/core'
import { Dashboard, AssistantOutlined, ViewList, PieChartRounded } from '@material-ui/icons'
import React, { useMemo } from 'react'
import { MenuItemLink, getResources, usePermissions, useAuthProvider } from 'react-admin'
import { useSelector } from 'react-redux'

import CustomRoutes from '../../customRoutes'

const filterResources = ['issues', 'givecards/samples', 'images']

function Menu ({ onMenuClick, logout }) {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const open = useSelector(state => state.admin.ui.sidebarOpen)
  const resources = useSelector(getResources)

  const commonProps = {
    onClick: onMenuClick,
    sidebarIsOpen: open,
  }
  const authProvider = useAuthProvider()
  const userPermissions = usePermissions()

  const hasPermission = useMemo(
    () => ({
      wpAdmin: userPermissions.loaded && authProvider.checkPermissions(authProvider.ACTIONS.WP_ADMIN, userPermissions.permissions),
      users: userPermissions.loaded && authProvider.checkPermissions(authProvider.ACTIONS.USER_LIST, userPermissions.permissions),
      givecards: userPermissions.loaded && authProvider.checkPermissions(authProvider.ACTIONS.GIVECARD_LIST, userPermissions.permissions),
    }),
    [authProvider, userPermissions.loaded, userPermissions.permissions]
  )

  return (
    <div>
      <MenuItemLink
        to="/"
        primaryText="Dashboard"
        leftIcon={<Dashboard />}
        {...commonProps}
      />
      {resources.filter((resource) => !filterResources.includes(resource.name)).map(resource => (
        hasPermission[resource.name] !== false && (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={resource.options?.label || resource.name}
            leftIcon={resource.icon ? <resource.icon /> : <ViewList />}
            {...commonProps}
          />
        )
      ))}
      {
        hasPermission.wpAdmin && <>
          <MenuItemLink
            to={CustomRoutes.wpUtilities.path}
            primaryText={CustomRoutes.wpUtilities.label}
            leftIcon={<AssistantOutlined />}
            {...commonProps}
          />
          <MenuItemLink
            to={CustomRoutes.sgReport.path}
            primaryText={CustomRoutes.sgReport.label}
            leftIcon={<PieChartRounded />}
            {...commonProps}
          />
        </>
      }
      {isXSmall && logout}
    </div>
  )
}

export default Menu
