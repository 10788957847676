import { Card, CardContent, CardHeader, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import { useTranslate } from 'react-admin'

const useStyles = makeStyles(() => ({
  bodyText: {
    whiteSpace: 'pre-wrap',
    fontSize: '1.125rem'
  },
}))

function Dashboard () {
  // Const
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <Card>
      <CardHeader title={translate('socialgiver.welcome')} />
      <CardContent>
        <Typography className={classes.bodyText} variant="body1">
          {translate('socialgiver.subTitle')}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default Dashboard
