import { Button, Card, CardContent, CardHeader, CardActions } from '@material-ui/core'
import React, { useMemo } from 'react'
import { Query, Loading, Error, useRedirect, usePermissions } from 'react-admin'

import CustomRoutes from '../../customRoutes'
import { Auth } from '../../providers'
import IssueItem from './IssueItem'

export default function GiveCardIssue ({ match: { params }, ...props }) {
  // State

  // Const
  const redirect = useRedirect()
  const { checkPermissions, ACTIONS } = Auth
  const { loaded: permissionLoaded, permissions } = usePermissions()
  const payload = {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'id', order: 'ASC' },
    filter: { type: 'givecard', modelId: params.id },
  }

  const hasReviewPermission =  useMemo(() => {
    if (permissionLoaded) {
      return checkPermissions(ACTIONS.GIVECARD_REVIEW, permissions)
    } else {
      return false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionLoaded, permissions])

  // Functions
  const handleOnBackBtnClick = () => {
    redirect('/givecards')
  }

  return (
    <Query type='getList' resource='issues' payload={payload}>
      {({ data, loading, error }) => {
        if (loading) { return <Loading /> }
        if (error) { return <Error /> }
        if (!data.length) {
          redirect('/givecards')
        }
        return (
          <Card>
            <CardHeader title={`${CustomRoutes.giveCardIssue.label} #${params.id}`}/>
            <CardContent>
              {
                data.map(issue => (
                  <IssueItem
                    key={issue.id}
                    issue={issue}
                    hasReviewPermission={hasReviewPermission}/>
                ))
              }
            </CardContent>
            <CardActions>
              <Button color="primary" variant="text" onClick={handleOnBackBtnClick}>
                Back
              </Button>
            </CardActions>
          </Card>
        )
      }}
    </Query>
  )
}
