import { Grid, CardContent, CardHeader, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import React from 'react'

const prepData = (record, links) => {
  const newData = {}

  record.forEach((items, index) => {
    newData[links[index]] = {}
    items.forEach((item) => {
      if (!newData[links[index]][item.platform]) {
        newData[links[index]][item.platform] = []
        newData[links[index]][item.platform].push({ event: item.event, count: item.count })
      } else newData[links[index]][item.platform].push({ event: item.event, count: item.count })
    })
  })

  return newData
}

function ReportDDL ({ record, links }) {
  const newData = prepData(record, links)

  return (
    <Grid container spacing={2}>
      {
        Object.keys(newData).map((link, index) => (
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={link}
                titleTypographyProps={{
                  variant: 'body1'
                }}
              />
              <CardContent>
                <Grid container item xs={12} spacing={1}>
                  {
                    Object.keys(newData[link]).map((platform, index) => (
                      <Grid item xs={6} md={4} key={`platform-${platform}-${index}`}>
                        <Card elevation={1}>
                          <CardHeader
                            title={platform}
                            titleTypographyProps={{
                              variant: 'subtitle2'
                            }}
                          />
                          <CardContent>
                            {
                              newData[link][platform].map((event, index) =>
                                <Grid key={`event-${event}-${index}`} container>
                                  <Grid item xs={6}>
                                    <Typography variant="body2">
                                      {event.event}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography variant="subtitle2" align="right">
                                      {event.count}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )
                            }
                          </CardContent>
                        </Card>
                      </Grid>
                    ))
                  }
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))
      }
    </Grid>
  )
}

export default ReportDDL
