import { Box, Card } from '@material-ui/core'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import { Create, Notification, useNotify, useRedirect } from 'react-admin'

import { useColorScheme } from '../../hooks'
import { JoinFreeEN, BG1, BG2 } from '../../images'
import Theme from '../../theme'
import { getRandomInt } from '../../utils'
import UserForm from './userForm'

const createStyles = makeStyles(() => ({
  noActions: {
    margin: 0,
    '& .MuiCard-root': {
      boxShadow: 'none'
    }
  }
}))

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
    backgroundImage: `url(${[BG1, BG2][getRandomInt(0, 1)]})`,
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
  logo: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  card: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    margin: '8vmin',
    width: 300,
    minWidth: 300,
    height: 'fit-content',
    '-ms-overflow-style': 'none', // IE and Edge
    'scrollbar-width': 'none',
    '&:-webkit-scrollbar': {
      display: 'none',
    }
  },
}))

export default function CreateUser (props) {
  // Const
  const colorScheme = useColorScheme()
  const classes = useStyles()
  const createClasses = createStyles()
  const redirect = useRedirect()
  const notify = useNotify()
  const { staticContext, ...createProps } = { ...props, basePath: '/users', resource: 'users' }

  // Functions
  const onSuccess = () => {
    redirect('login')
  }

  const onFailure = (error) => {
    notify(typeof error === 'string' ? error : error.message, 'warning')
  }

  return (
    <ThemeProvider theme={Theme[colorScheme]}>
      <div className={classes.root}>
        <Card className={classes.card}>
          <Box position="relative">
            <img
              className={classes.logo}
              src={JoinFreeEN}
              alt="sg-logo"/>
          </Box>
          <Create
            {...createProps}
            classes={createClasses}
            onSuccess={onSuccess}
            onFailure={onFailure}
          >
            <UserForm nolayout="true" margin="dense" register="true"/>
          </Create>
        </Card>
        <Notification />
      </div>
    </ThemeProvider>
  )
}


