import merge from 'lodash/merge'
import raEn from 'ra-language-english'

export default merge(
  {},
  raEn,
  {
    resources: {
      givecards: {
        title: 'List of GiveCards',
        empty: 'No GiveCards yet.',
        invite: 'Do you want to create one?',
        footerText: 'For additional support, please contact e-mail: Partnership@socialgiver.com\r\n' +
          'Tel. +66 65 017 7841 ',
      },
      business: {
        title: 'List of Business',
        empty: 'No Business yet.',
        invite: 'Do you want to create one?'
      },
      users: {
        title: 'List of Users',
        empty: 'No Users yet.',
        invite: 'Do you want to create one?'
      }
    },
    socialgiver: {
      welcome: 'Welcome to Socialgiver!',
      subTitle: 'One good deed leads to another and another. Let\'s make a difference together!\r\n\r\n' +
        'For additional support, please contact e-mail: Partnership@socialgiver.com\r\n' +
        'Tel. +66 65 017 7841 '
    }
  },
)
