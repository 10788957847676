import React from 'react'
import { Layout } from 'react-admin'

import CustomAppBar from './appBar'
import CustomMenu from './menu'

function CustomLayout (props) {
  return (
    <Layout
      {...props}
      menu={CustomMenu}
      appBar={CustomAppBar}
    />
  )
}

export default CustomLayout
