import { Box, Typography } from '@material-ui/core'
import { People } from '@material-ui/icons'
import UserPermissions from '@socialgiver/user-permissions'
import React, { useMemo } from 'react'
import {
  BooleanInput,
  PasswordInput,
  SaveButton,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  Toolbar,
  email,
  maxLength,
  required,
  useAuthProvider,
  usePermissions
} from 'react-admin'

import { styles } from './style'

const defaultFormValues = { isActive: true, roles: ['vendor'] }

const CreateUserToolbar = ({ hasAdminPermission, nolayout, register, ...props }) => {
  const classes = styles()

  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton
        className={!nolayout ? classes.saveBtn : null}
        fullWidth={!!nolayout}
        label={register ? 'Register': 'Save'}
        submitOnEnter={false}
        icon={<People />}/>
    </Toolbar>
  )
}

const Remark = () => (
  <Box width={268} mt={1}>
    <Typography variant="subtitle2" color="primary">
      Join us to spread a lifestyle of giving across Thailand!
    </Typography>
  </Box>
)

export const validation = {
  name: [required(), maxLength(50)],
  surname: [required(), maxLength(50)],
  email: [required(), email()],
  password: [required(), passwordValidation],
}

export function passwordValidation (value, allValues) {
  if (allValues.password !== allValues.confirmPassword) {
    return 'Password is mismatched'
  } else {
    return null
  }
}

export default function UserForm (props) {
  const authProvider = useAuthProvider()
  const userPermissions = usePermissions()

  const isRegistering = !!props.register
  const isCreating = !props.record?.id

  const hasAdminPermission = useMemo(
    () => userPermissions.loaded && authProvider.checkPermissions(authProvider.ACTIONS.USER_ADMIN, userPermissions.permissions),
    [authProvider, userPermissions.loaded, userPermissions.permissions]
  )

  const roleChoices = Object.keys(UserPermissions.Roles)
    .filter(role => role !== 'default')
    .map(role => ({
      id: role,
      name: `${role.charAt(0).toUpperCase()}${role.slice(1)}`
    }))

  const inputProps = {
    fullWidth: true,
    variant: 'standard',
  }

  return (
    <SimpleForm
      {...props}
      submitOnEnter={false}
      initialValues={defaultFormValues}
      toolbar={
        <CreateUserToolbar
          hasAdminPermission={hasAdminPermission}
          nolayout={props.nolayout}
          register={isRegistering}
        />
      }
    >
      {
        hasAdminPermission && !isCreating && (
          <>
            <TextInput {...inputProps} source="id" email="Id" disabled/>
            <TextInput {...inputProps} source="wpUserId" email="Wordpress Id" disabled/>
            <TextInput {...inputProps} source="sessionId" email="Session Id" disabled/>
          </>
        )
      }
      <TextInput {...inputProps} source="name" label="Name" validate={validation.name}/>
      <TextInput {...inputProps} source="surname" label="Surname" validate={validation.surname}/>
      <TextInput {...inputProps} source="email" email="Email Address" validate={validation.email} disabled={!isCreating && !isRegistering}/>
      {
        isRegistering || isCreating ? (
          <>
            <PasswordInput {...inputProps} source="password" validate={validation.password}/>
            <PasswordInput {...inputProps} source="confirmPassword" validate={validation.password}/>
          </>
        ) : null
      }
      {
        hasAdminPermission && (
          <>
            <SelectArrayInput
              {...inputProps}
              source="roles"
              choices={roleChoices}
            />
            <BooleanInput {...inputProps} source="isActive" label="Active"/>
          </>
        )
      }
      {
        !!isRegistering && <Remark />
      }
    </SimpleForm>
  )
}
