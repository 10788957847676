import React from 'react'
import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  Filter,
  EditButton,
  TextInput,
  DateField
} from 'react-admin'

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput alwaysOn label="Search by id" source="id"/>
  </Filter>
)

export default function UserList (props) {
  return (
    <List {...props} title='resources.users.title' filters={<UserFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
      <Datagrid rowClick="toggleSelection">
        <TextField source="id"/>
        <TextField source="displayName" label="Name" sortable={false}/>
        <FunctionField
          source="roles"
          render={(user) => user.roles?.join(',') || 'none'}
          sortable={false}
        />
        <DateField source="createdAt" label="Register Date"/>
        <EditButton/>
      </Datagrid>
    </List>
  )
}
