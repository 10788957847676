import {
  Button,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Grid,
  Paper,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  IconButton,
  Box
} from '@material-ui/core'
import { Add, DeleteOutline, CloseOutlined, ExpandMore } from '@material-ui/icons'
import {
  Autocomplete
} from '@material-ui/lab'
import { DatePicker } from '@material-ui/pickers'
import arrayMutators from 'final-form-arrays'
import Moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { Loading, useNotify, usePermissions } from 'react-admin'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'

import CustomRoutes from '../../customRoutes'
import { Auth } from '../../providers'
import * as Api from '../../providers/api'
import { getFieldErrorMessage, errorFieldProp } from '../../utils'
import { required } from '../../validations'
import { giveCardFormStyles } from '../givecards/style'
import ReportDDL from './reportDDL'
import ReportExporter from './reportExporter'
import ReportTable from './reportTable'

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    width: '100%',
    padding: theme.spacing(1),
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    borderWidth: 1,
  },
  dateTimeField: {
    width: '100%'
  },
  textField: {
    width: '100%'
  },
  selectField: {
    width: '100%'
  }
}))

const SubmitAction = {
  view: 'view',
  download: 'download'
}

const Menu = {
  donationReport: {
    title: 'donationReport',
    component: ReportTable
  },
  newUserEmailReport: {
    title: 'newUserEmailReport',
    component: ReportTable
  },
  orderUtmReport: {
    title: 'orderUtmReport',
    component: ReportTable
  },
  ddlReport: {
    title: 'ddlReport',
    component: ReportDDL
  },
  default: {
    title: 'none',
    component: ReportTable
  }
}

const durationOptions = [
  { title: 'Last 7 Days', value: '7' },
  { title: 'Last 30 Days', value: '30' },
  { title: 'Last 6 Months', value: '180' },
  { title: 'Last 1 Year', value: '366' },
]

function SGReport () {
  // State
  const [loading, setLoading] = useState(false)
  const [reportCaption, setReportCaption] = useState('')
  const [activeMenu, setActiveMenu] = useState(Menu.default.title)
  const [viewList, setViewList] = useState([])
  const [linkList, setLinkList] = useState([])
  const [initialValues] = useState({
    donationReport: {
      startDate: new Date(),
      endDate: new Date(),
    },
    newUserEmailReport: {
      startDate: new Date(),
    },
    orderUtmReport: {
      startDate: new Date(),
      endDate: new Date(),
    },
    ddlReport: {
      linkUrl: [''],
      duration: ''
    }
  })

  // Memo
  // ...

  // Const
  const notify = useNotify()
  const classes = useStyles()
  const { checkPermissions, ACTIONS } = Auth
  const { loaded: permissionLoaded, permissions } = usePermissions()
  const giveCardFormClasses = giveCardFormStyles()
  // const formControlProps = {
  //   fullWidth: true,
  //   required: true,
  //   size: 'small',
  //   variant: 'outlined'
  // }
  const dateFormat = 'yyyy-MM-DD'
  const startFormat = 'YYYY-MM-DD 00:00:00'
  const endFormat = 'YYYY-MM-DD 23:59:59'

  // Memo
  const hasWPAdminPermission = useMemo(() => {
    if (permissionLoaded) {
      return checkPermissions(ACTIONS.WP_ADMIN, permissions)
    } else {
      return false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionLoaded, permissions])

  // Effect
  // ...

  // Functions
  const handleExpandClick = (panel) => (event, isExpanded) => {
    setActiveMenu(isExpanded ? panel : Menu.default.title)
    handleOnCloseBtnClick()
  }

  const handleSubmitBtnClick = (setFormValue, action) => () => {
    setFormValue('submitAction', action)
    setFormValue('setFormValue', setFormValue)
  }

  const handleOnCloseBtnClick = () => {
    setViewList([])
    setLinkList([])
  }

  const validate = (values) => {
    const validationResult = {}
    switch (activeMenu) {
      case Menu.donationReport.title:
        validationResult.startDate = required(values.donationReport.startDate)
        validationResult.endDate = required(values.donationReport.endDate)
        break
      case Menu.newUserEmailReport.title:
        validationResult.startDate = required(values.newUserEmailReport.startDate)
        break
      case Menu.orderUtmReport.title:
        validationResult.startDate = required(values.orderUtmReport.startDate)
        validationResult.endDate = required(values.orderUtmReport.endDate)
        break
      case Menu.ddlReport.title:
        const { ddlReport = {} } = values
        let ddlReportMeta = {}
        ddlReportMeta.linkUrl = (ddlReport.linkUrl || []).map(r => r = required(r))
        ddlReportMeta.duration = required(ddlReport.duration)
        validationResult.ddlReport = ddlReportMeta
        break
      default: break
    }
    return validationResult
  }

  // Callback
  const isActive = useCallback(
    (menu) => activeMenu === menu,
    [activeMenu],
  )

  const onSubmit = useCallback( async (values) => {
      if (loading) return
      setViewList([])
      setLinkList([])
      setLoading(true)
      switch (activeMenu) {
        case Menu.donationReport.title:
          await exportDonationReport(values)
          break
        case Menu.newUserEmailReport.title:
          await exportNewUserEmailReportReport(values)
          break
        case Menu.orderUtmReport.title:
          await exportOrderUtmReport(values)
          break
        case Menu.ddlReport.title:
          await exportDDLReport(values)
          break
        default: break
      }
      setLoading(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, activeMenu],
  )

  const exportDonationReport = async (values) => {
    const startDate = Moment(values.donationReport.startDate).startOf('day').format(startFormat)
    const endDate = Moment(values.donationReport.endDate).endOf('day').format(endFormat)
    await Api.donationsReport({ startDate, endDate })
      .then((resp) => {
        if (resp.length) {
          const reportName = 'Donations Report'
          switch (values.submitAction) {
            case SubmitAction.view:
              setReportCaption(reportName)
              setViewList(resp)
              break
            case SubmitAction.download:
                notify('Downloading donations report...')
                ReportExporter.exportSimpleReport(resp, reportName)
              break
            default: break
          }
        } else {
          notify('Not found.')
        }
      })
      .catch((e) => notify(`Error: ${e.errorMessage || e}`, 'warning'))
  }

  const exportNewUserEmailReportReport = async (values) => {
    const startDate = Moment(values.newUserEmailReport.startDate).startOf('day').format(startFormat)
    await Api.newUserEmailReport({ startDate })
      .then((resp) => {
        if (resp.length) {
          const reportName = 'NewUserEmail Report'
          switch (values.submitAction) {
            case SubmitAction.view:
              setReportCaption(reportName)
              setViewList(resp)
              break
            case SubmitAction.download:
                notify('Downloading new user email report...')
                ReportExporter.exportSimpleReport(resp, reportName)
              break
            default: break
          }
        } else {
          notify('Not found.')
        }
      })
      .catch((e) => notify(`Error: ${e.errorMessage || e}`, 'warning'))
  }

  const exportOrderUtmReport = async (values) => {
    const startDate = Moment(values.orderUtmReport.startDate).format(startFormat)
    const endDate = Moment(values.orderUtmReport.endDate).format(endFormat)
    await Api.orderUtmReport({ startDate, endDate })
      .then((resp) => {
        if (resp.length) {
          const reportName = 'OrderUtm Report'
          switch (values.submitAction) {
            case SubmitAction.view:
              setReportCaption(reportName)
              setViewList(resp)
              break
            case SubmitAction.download:
              notify('Downloading Order utm report...')
              ReportExporter.exportSimpleReport(resp, reportName)
              break
            default: break
          }
        } else {
          notify('Not found.')
        }
      })
      .catch((e) => notify(`Error: ${e.errorMessage || e}`, 'warning'))
  }

  const exportDDLReport = async (values) => {
    const linkUrl = values.ddlReport.linkUrl
    const encodedLinkUrl = linkUrl.map(resp => encodeURIComponent(resp))
    const duration = values.ddlReport.duration

    await Api.ddlReport({ encodedLinkUrl, duration })
      .then((resp) => {
        if (resp[0].length) {
          const reportName = 'DDL Report'
          switch (values.submitAction) {
            case SubmitAction.view:
              setReportCaption(reportName)
              setViewList(resp)
              setLinkList(linkUrl)
              break
            case SubmitAction.download:
              notify('Downloading DDL report...')
              ReportExporter.exportDDLReport(resp, linkUrl, reportName)
              break
            default: break
          }
        } else {
          notify('Not found.')
        }
      })
      .catch((e) => notify(`${e.errorMessage || e}`, 'warning'))
  }

  // Show Loading...
  if (!permissionLoaded) return <Loading/>

  if (!hasWPAdminPermission) return (
    <Card>
      <CardHeader title={CustomRoutes.sgReport.label}/>
      <CardContent>
        <Typography>Permission Denied</Typography>
      </CardContent>
    </Card>
  )

  const sgReportTableProps = {
    donationReport: { caption: reportCaption },
    newUserEmailReport: { caption: reportCaption },
    orderUtmReport: { caption: reportCaption },
    ddlReport: { links: linkList },
    default: { caption: reportCaption }
  }

  const SGReportTable = Menu[activeMenu]?.component
  return (
    <Card>
      <CardHeader title={CustomRoutes.sgReport.label}/>
      <CardContent>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initialValues}
          mutators={{ ...arrayMutators }}
          render={({ handleSubmit, form: { mutators: { push }, change: setFormValue } }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <Accordion expanded={isActive(Menu.donationReport.title)} onChange={handleExpandClick(Menu.donationReport.title)}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel-donation-report-content"
                  id="panel-donation-report-header"
                >
                  <Typography>Donations Report</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field name="donationReport.startDate">
                        {({ input, meta }) => (
                          <DatePicker
                            className={classes.dateTimeField}
                            {...input}
                            id="date-period-start-date"
                            format={dateFormat}
                            label="Form"
                            disabled={loading}
                            invalidDateMessage={getFieldErrorMessage(meta)}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="donationReport.endDate">
                        {({ input, meta }) => (
                          <DatePicker
                            className={classes.dateTimeField}
                            {...input}
                            id="date-period-end-date"
                            format={dateFormat}
                            label="To"
                            disabled={loading}
                            invalidDateMessage={getFieldErrorMessage(meta)}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </AccordionDetails>
                <AccordionActions>
                  <Button
                    type="submit"
                    variant="text"
                    color="primary"
                    disabled={loading}
                    onClick={handleSubmitBtnClick(setFormValue, SubmitAction.view)}
                  >
                    View
                  </Button>
                  <Button
                    type="submit"
                    variant="text"
                    color="primary"
                    disabled={loading}
                    onClick={handleSubmitBtnClick(setFormValue, SubmitAction.download)}
                  >
                    Download
                  </Button>
                </AccordionActions>
              </Accordion>
              <Accordion expanded={isActive(Menu.newUserEmailReport.title)} onChange={handleExpandClick(Menu.newUserEmailReport.title)}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel-donation-report-content"
                  id="panel-donation-report-header"
                >
                  <Typography>New user email Report</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">Email list for newsletters</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="newUserEmailReport.startDate">
                        {({ input, meta }) => (
                          <DatePicker
                            className={classes.dateTimeField}
                            {...input}
                            id="date-period-start-date"
                            format={dateFormat}
                            label="Form"
                            disabled={loading}
                            invalidDateMessage={getFieldErrorMessage(meta)}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </AccordionDetails>
                <AccordionActions>
                  <Button
                    type="submit"
                    variant="text"
                    color="primary"
                    disabled={loading}
                    onClick={handleSubmitBtnClick(setFormValue, SubmitAction.view)}
                  >
                    View
                  </Button>
                  <Button
                    type="submit"
                    variant="text"
                    color="primary"
                    disabled={loading}
                    onClick={handleSubmitBtnClick(setFormValue, SubmitAction.download)}
                  >
                    Download
                  </Button>
                </AccordionActions>
              </Accordion>
              <Accordion expanded={isActive(Menu.orderUtmReport.title)} onChange={handleExpandClick(Menu.orderUtmReport.title)}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel-donation-report-content"
                  id="panel-donation-report-header"
                >
                  <Typography>Order Utm Report</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field name="orderUtmReport.startDate">
                        {({ input, meta }) => (
                          <DatePicker
                            className={classes.dateTimeField}
                            {...input}
                            id="date-period-start-date"
                            format={dateFormat}
                            label="Form"
                            disabled={loading}
                            invalidDateMessage={getFieldErrorMessage(meta)}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="orderUtmReport.endDate">
                        {({ input, meta }) => (
                          <DatePicker
                            className={classes.dateTimeField}
                            {...input}
                            id="date-period-end-date"
                            format={dateFormat}
                            label="To"
                            disabled={loading}
                            invalidDateMessage={getFieldErrorMessage(meta)}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </AccordionDetails>
                <AccordionActions>
                  <Button
                    type="submit"
                    variant="text"
                    color="primary"
                    disabled={loading}
                    onClick={handleSubmitBtnClick(setFormValue, SubmitAction.view)}
                  >
                    View
                  </Button>
                  <Button
                    type="submit"
                    variant="text"
                    color="primary"
                    disabled={loading}
                    onClick={handleSubmitBtnClick(setFormValue, SubmitAction.download)}
                  >
                    Download
                  </Button>
                </AccordionActions>
              </Accordion>
              <Accordion expanded={isActive(Menu.ddlReport.title)} onChange={handleExpandClick(Menu.ddlReport.title)}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel-donation-report-content"
                  id="panel-donation-report-header"
                >
                  <Typography>DDL Report</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field name="ddlReport.duration">
                        {({ input, meta }) => (
                          <Autocomplete
                            freeSolo
                            options={durationOptions}
                            getOptionLabel={(option) => option.value}
                            onBlur={(newValue) => {
                              input.onChange(newValue)
                            }}
                            renderOption={(option) => (
                              <React.Fragment>
                                {option.title}
                              </React.Fragment>
                            )}
                            renderInput={(params) =>
                              <TextField
                                {...params}
                                id="duration-text"
                                type="number"
                                size="small"
                                margin="none"
                                label="Duration"
                                {...errorFieldProp(meta)}
                              />}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <FieldArray name="ddlReport.linkUrl">
                      {({ fields }) => (
                      <Box width="100%">
                          <Grid container spacing={2}>
                            {
                              fields.map((name, index) => (
                                <Grid container item xs={12} spacing={1} key={`${name}-${index}`}>
                                  <Grid item xs={11}>
                                    <Field name={`${name}`}>
                                      {({ input, meta }) => (
                                        <TextField
                                          required
                                          className={classes.textField}
                                          {...input}
                                          id="link-url"
                                          label="Link Url"
                                          disabled={loading}
                                          {...errorFieldProp(meta)}
                                        />
                                      )}
                                    </Field>
                                  </Grid>
                                  {
                                      index > 0 && (
                                      <Grid container item xs={1} justify="flex-start" alignItems="flex-end" direction="row">
                                        <IconButton
                                          size="small"
                                          aria-label="remove"
                                          disabled={loading}
                                          onClick={() => fields.remove(index)}>
                                          <DeleteOutline />
                                        </IconButton>
                                      </Grid>
                                    )
                                  }
                                </Grid>
                              ))
                            }
                            <Grid item xs={12}>
                              <Button
                                variant="text"
                                size="small"
                                disabled={loading}
                                onClick={() => push(fields.name, '')}
                                startIcon={<Add />}>
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                      </Box>
                      )}
                      </FieldArray>
                    </Grid>
                  </Grid>
                </AccordionDetails>
                <AccordionActions>
                  <Button
                    type="submit"
                    variant="text"
                    color="primary"
                    disabled={loading}
                    onClick={handleSubmitBtnClick(setFormValue, SubmitAction.view)}
                  >
                    View
                  </Button>
                  <Button
                    type="submit"
                    variant="text"
                    color="primary"
                    disabled={loading}
                    onClick={handleSubmitBtnClick(setFormValue, SubmitAction.download)}
                  >
                    Download
                  </Button>
                </AccordionActions>
              </Accordion>
            </form>
          )}/>
      </CardContent>
      {
        viewList.length ? (
          <CardContent>
            <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
              <Grid container item xs={12}>
                <IconButton
                  className={giveCardFormClasses.rightBtn}
                  size="small"
                  aria-label="close"
                  onClick={handleOnCloseBtnClick}>
                  <CloseOutlined />
                </IconButton>
              </Grid>
              <Box mt={1}>
                {
                  SGReportTable ? <SGReportTable record={viewList} {...sgReportTableProps[activeMenu]}/> : null
                }
              </Box>
            </Paper>
          </CardContent>
        ) : null
      }
    </Card>
  )
}

export default SGReport
