import UserCreate from './create'
import CreateUser from './createUser'
import UserEdit from './userEdit'
import UserList from './userList'

export default {
  create: UserCreate,
  list: UserList,
  edit: UserEdit,
  CreateUser,
}
