import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

function ReportTable ({ record, caption= null }) {
  const classes = useStyles()
  const keys = Object.keys(record[0])

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="caption table">
        { caption ? <caption>{caption}</caption> : null }
        <TableHead>
          <TableRow>
            {
              keys.map((value, index) => <TableCell align="center" key={`report-header-cell-${index}`}>{value}</TableCell>)
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {record.map((row, index) => (
            <TableRow key={`report-row-${index}`}>
              {
                keys.map(value => <TableCell key={`report-${value}-${index}`} align="left">{row[value]}</TableCell>)
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ReportTable
