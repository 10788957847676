import { Button } from '@material-ui/core'
import jsonExport from 'jsonexport/dist'
import get from 'lodash/get'
import React, { cloneElement, useMemo } from 'react'
import {
  List,
  Filter,
  TopToolbar,
  Datagrid,
  TextField,
  EditButton,
  EmailField,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  usePermissions,
  TextInput,
  ExportButton,
  downloadCSV
} from 'react-admin'
import { Link } from 'react-router-dom'

import { Auth } from '../../providers'

const businessExporter = business => {
  const businessForExport = business.map(data => {
    const {
      id,
      taxId,
      name,
      contact: { name: ContactName, email },
      accountantContact: { phoneNumber: accountantPhoneNumber, name: accountantName, email: accountantEmail },
      givecardsCount
    } = data
    return {
      id,
      'Name': name,
      'Contact Name': ContactName,
      'Contact Email': email,
      'TaxId': taxId,
      'Accountant PhoneNumber': accountantPhoneNumber,
      'Accountant Name': accountantName,
      'Accountant Email': accountantEmail,
      'GiveCards Count': givecardsCount || '0'
    }
  })
  jsonExport(businessForExport, {
    headers: []
  }, (err, csv) => {
    downloadCSV(csv, 'business') // download as 'business.csv` file
  })
}

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    currentSort,
    total,
    permanentFilter,
    hasListAllPermission,
    ...rest
  } = props
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath}/>
      { hasListAllPermission ? (
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={{ ...filterValues, ...permanentFilter }}
          exporter={businessExporter}
          maxResults={maxResults}
        />
      ) : null }
    </TopToolbar>
  )
}

const BusinessFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Id" source="id" alwaysOn />
    <TextInput label="Business Name" source="name" alwaysOn/>
    <TextInput label="Contact Email" source="contact.email" alwaysOn/>
  </Filter>
)

const LinkToGiveCardCount = ({ source, record = {} }) => {
  return record ? (
    <Button
      color="primary"
      variant="text"
      component={Link}
      to={{
        pathname: '/givecards',
        search: `filter=${JSON.stringify({ business: { id: record.id } })}`,
      }}
    >
      <span>{get(record, source) || 0}</span>
    </Button>
  ) : null
}

function BusinessList (props) {
  // Const
  const { checkPermissions, ACTIONS } = Auth
  const { loaded: permissionLoaded, permissions } = usePermissions()

  // Memo
  const hasListAllPermission = useMemo(() => {
    if (permissionLoaded) {
      return checkPermissions(ACTIONS.BUSINESS_LIST_ALL, permissions)
    } else {
      return false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionLoaded, permissions])

  return (
    <List
      {...props}
      title='resources.business.title'
      actions={<ListActions hasListAllPermission={hasListAllPermission}/>}
      filters={<BusinessFilter/>}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid>
        <TextField source="id"/>
        <TextField source="taxId"/>
        <TextField source="name"/>
        <TextField source="contact.name" label="Contact Name" sortable={false}/>
        <EmailField source="contact.email" label="Contact Email" sortable={false}/>
        {
          hasListAllPermission ? (<LinkToGiveCardCount source="givecardsCount" label="GiveCards Count"/>) : null
        }
        <EditButton/>
      </Datagrid>
    </List>
  )
}

export default BusinessList
