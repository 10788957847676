import React from 'react'
import { Create } from 'react-admin'

import UserForm from './userForm'

export default function UserCreate (props) {
  return (
    <Create {...props}>
      <UserForm />
    </Create>
  )
}

