import Moment from 'moment'

import { defaultOpeningHours } from '../../components/givecards/configuration'
import { getDefaultValue } from '../../components/givecards/create'

export function givecardsSingleToApi (params) {
  const result = {}
  if (params.id) {
    result.id = params.id
  }
  if (params.status) {
    result.status = params.status
  }

  if (params.category) {
    result.category = params.category
  }

  // 1
  if (params.businessId) {
    result.businessId = params.businessId
  }

  // 2
  if (params.branches) {
    result.items = params.branches
  }

  if (params.en) {
    const { desc, shortDesc, additionalPromo, awards, ...en } = params.en
    result.en = en
    result.en.description = desc || ''
    result.en.shortBusinessDescription = shortDesc || ''
    result.en.awards = awards || ''
    result.en.additionalPromotions = additionalPromo || ''
    result.en.conditions = params.conditions.map((c) => ({ value: c.en }))
    result.en.hashtags = params.hashtags.en.join(',')
  }
  if (params.th) {
    const { desc, shortDesc, additionalPromo, awards, ...th } = params.th
    result.th = th
    result.th.description = desc || ''
    result.th.shortBusinessDescription = shortDesc || ''
    result.th.awards = awards || ''
    result.th.additionalPromotions = additionalPromo || ''
    result.th.conditions = params.conditions.map((c) => ({ value: c.th }))
    result.th.hashtags = params.hashtags.th.join(',')
  }

  if (params.alwaysOpen !== undefined || result.openingHours) {
    result.alwaysOpen = !!params.alwaysOpen
    result.openingHours = (result.alwaysOpen)
      ? []
      : params.openingHours
        .map(({ date, time }) => (
          {
            dateStart: date.start,
            dateEnd: date.end,
            timeStart: Moment(time.start).format('HH:mm:ss'),
            timeEnd: Moment(time.end).format('HH:mm:ss'),
          }
        ))
  }

  // 3
  if (params.usage) {
    result.usage = params.usage
  }
  if (params.other || params.projectCategory) {
    result.meta = (params.other) ? { ...params.other } : {}
    if (params.projectCategory) {
      result.meta.projectCategory = params.projectCategory
    }
  }

  // 4
  if (params.featuredImage && params.featuredImage.length) {
    result.featuredImage = params.featuredImage
      .map(({ src }) => ({ path: src }))
      [0]
  }
  if (params.logoImage && params.logoImage.length) {
    result.logoImage = params.logoImage
      .map(({ src }) => ({ path: src }))
      [0]
  }
  if (params.galleryImages && params.galleryImages.length) {
    result.galleryImages = params.galleryImages
      .map(({ src }) => ({ path: src }))
  }

  // 5
  if (params.minimumAvailableCode !== undefined) {
    result.minimumAvailableCode = params.minimumAvailableCode
  }
  if (params.qtyPerBatch !== undefined) {
    result.quantityPerBatch = params.qtyPerBatch
  }
  if (params.expirationType) {
    result.expirationType = params.expirationType
    switch (params.expirationType) {
      case 'datePeriod':
        result.availablePeriodFrom = params.datePeriod.startDate
        result.availablePeriodUntil = params.datePeriod.endDate
        break
      case 'numberOfDays':
        result.numberOfExpirationDays = params.numberOfDays
        break
      case 'none':
      default:
        break
    }
  }
  if (params.showGivecardCodeAsQR !== undefined) {
    result.displayQrCode = params.showGivecardCodeAsQR
  }
  if (params.qrCodeTitle !== undefined) {
    result.qrCodeTitle = params.qrCodeTitle
  }
  if (params.showPinCode !== undefined) {
    result.displayCode = params.showPinCode
  }

  if (params.percentageOfDonation !== undefined) {
    result.percentDonation = params.percentageOfDonation / 100
  }
  if (params.percentageOfBusiness !== undefined) {
    result.percentBusiness = params.percentageOfBusiness / 100
  }
  if (params.percentageOfSocialgiver !== undefined) {
    result.percentSocialgiver = params.percentageOfSocialgiver / 100
  }
  if (params.shareCampaignUrl !== undefined) {
    result.shareCampaignUrl = params.shareCampaignUrl
  }

  if (params.feeInformation?.en !== undefined) {
    if (!result.en) {
      result.en = {}
    }
    result.en.feeInformation = params.feeInformation.en
  }
  if (params.feeInformation?.th !== undefined) {
    if (!result.th) {
      result.th = {}
    }
    result.th.feeInformation = params.feeInformation.th
  }
  if (params.prizeText?.en !== undefined) {
    if (!result.en) {
      result.en = {}
    }
    result.en.prizeText = params.prizeText.en
  }
  if (params.prizeText?.th !== undefined) {
    if (!result.th) {
      result.th = {}
    }
    result.th.prizeText = params.prizeText.th
  }

  if (params.qtyLimit !== undefined) {
    result.quantityLimit = params.qtyLimit
  }
  if (params.hoursUntilRePurchasable !== undefined) {
    result.hoursUntilRepurchasable = params.hoursUntilRePurchasable
  }
  if (params.isRequire3ds !== undefined) {
    result.requires3ds = params.isRequire3ds
  }
  if (params.project !== undefined) {
    result.projectSupportedId = params.project.id
  }
  if (params.slug !== undefined) {
    result.slug = params.slug
  }
  if (params.visibility !== undefined) {
    result.visibility = params.visibility
  }
  if (params.invisibleTo !== undefined) {
    result.invisibleTo = params.invisibleTo
  }
  if (params.menuOrder !== undefined) {
    result.order = params.menuOrder
  }

  if (params.replenishStock !== undefined) {
    result.autoReplenishStock = params.replenishStock
  }
  if (params.qtyStock !== undefined) {
    result.autoReplenishStockQuantity = params.qtyStock
  }
  if (params.relatedGiveCards !== undefined) {
    result.relatedGivecards = params.relatedGiveCards.map((gc) => gc.id).join(',')
  }
  if (params.isEnablePostExpiration !== undefined) {
    result.autoExpireDate = (params.isEnablePostExpiration) ? params.postExpirator : null
  }

  return result
}

export function givecardsSingleFromApi (params) {
  const result = {}
  const gcDetails = { ...getDefaultValue(1) }
  const gcConfig = { ...getDefaultValue(2) }
  const gcImages = { ...getDefaultValue(3) }
  const gcAdvanceSetting = { ...getDefaultValue(4) }

  result.id = params.id
  result.status = params.status
  result.issues = params.issues

  if (params.business) {
    result.business = params.business
  }

  // 1
  result.businessId = params.businessId

  // 2
  if (params.items?.length > 0) {
    gcDetails.branches = params.items
  }
  gcDetails.category = params.category

  const { hashtags: enHashtags = '', conditions: enConditions = [], description: enDescription, shortBusinessDescription: enShortBusinessDescription, additionalPromotions: enAdditionalPromotions, ...en } = (params.en || {})
  const { hashtags: thHashtags = '', conditions: thConditions = [], description: thDescription, shortBusinessDescription: thShortBusinessDescription, additionalPromotions: thAdditionalPromotions, ...th } = (params.th || {})

  gcDetails.en = en
  gcDetails.th = th

  gcDetails.en.desc = enDescription
  gcDetails.th.desc = thDescription

  gcDetails.en.shortDesc = enShortBusinessDescription
  gcDetails.th.shortDesc = thShortBusinessDescription

  gcDetails.en.additionalPromo = enAdditionalPromotions
  gcDetails.th.additionalPromo = thAdditionalPromotions

  gcDetails.hashtags = {
    en: enHashtags.split(',').filter(h => h) || [],
    th: thHashtags.split(',').filter(h => h) || [],
  }

  const conditionLength = Math.max(params.en?.conditions?.length, params.th?.conditions?.length) || 0
  gcDetails.conditions = [...Array(conditionLength)].map(() => ({}))
  enConditions.forEach((cond, i) => { gcDetails.conditions[i].en = cond.value })
  thConditions.forEach((cond, i) => { gcDetails.conditions[i].th = cond.value })

  gcDetails.alwaysOpen = !!params.alwaysOpen
  gcDetails.openingHours = (gcDetails.alwaysOpen)
    ? [defaultOpeningHours]
    : (params.openingHours)
      ? params.openingHours
        .map(({ dateStart, dateEnd, timeStart, timeEnd }) => (
          {
            date: {
              start: dateStart,
              end: dateEnd,
            },
            time: {
              start: Moment(timeStart, 'HH:mm:ss'),
              end: Moment(timeEnd, 'HH:mm:ss'),
            },
          }
        ))
      : [defaultOpeningHours]
  result.details = gcDetails

  // 3
  if (params.usage) {
    gcConfig.usage = params.usage
  }
  const { projectCategory, ...metaOther } = (params.meta || {})
  gcConfig.other = metaOther
  if (projectCategory) {
    gcConfig.projectCategory = projectCategory
  }
  result.config = gcConfig

  // 4
  if (params.featuredImage) {
    const { path, id, name } = params.featuredImage
    gcImages.featuredImage = [{ src: path, id, name }]
  } else {
    gcImages.featuredImage = []
  }
  if (params.business && params.business.logoImage) {
    const { path, id, name } = params.business.logoImage
    gcImages.logoImage = [{ src: path, id, name }]
  } else {
    gcImages.logoImage = []
  }
  if (params.galleryImages) {
    gcImages.galleryImages = params.galleryImages
      .map(({ path, id, name }) => ({ src: path, id, name }))
  } else {
    gcImages.galleryImages = []
  }
  result.images = gcImages

  // 5
  if (params.minimumAvailableCode !== undefined) {
    gcAdvanceSetting.minimumAvailableCode = params.minimumAvailableCode
  }
  if (params.quantityPerBatch !== undefined) {
    gcAdvanceSetting.qtyPerBatch = params.quantityPerBatch
  }
  if (params.expirationType) {
    gcAdvanceSetting.expirationType = params.expirationType
    switch (params.expirationType) {
      case 'datePeriod':
        gcAdvanceSetting.datePeriod = {
          startDate: params.availablePeriodFrom,
          endDate: params.availablePeriodUntil,
        }
        break
      case 'numberOfDays':
        gcAdvanceSetting.numberOfDays = params.numberOfExpirationDays
        break
      case 'none':
      default:
        break
    }
  }

  if (params.displayQrCode !== undefined) {
    gcAdvanceSetting.showGivecardCodeAsQR = params.displayQrCode
  }
  if (params.qrCodeTitle !== undefined) {
    gcAdvanceSetting.qrCodeTitle = params.qrCodeTitle
  }
  if (params.displayCode !== undefined) {
    gcAdvanceSetting.showPinCode = params.displayCode
  }

  if (params.percentDonation !== undefined) {
    gcAdvanceSetting.percentageOfDonation = params.percentDonation * 100
  }
  if (params.percentBusiness !== undefined) {
    gcAdvanceSetting.percentageOfBusiness = params.percentBusiness * 100
  }
  if (params.percentSocialgiver !== undefined) {
    gcAdvanceSetting.percentageOfSocialgiver = params.percentSocialgiver * 100
  }
  if (params.shareCampaignUrl) {
    gcAdvanceSetting.shareCampaignUrl = params.shareCampaignUrl
  }

  if (params.en?.feeInformation || params.th?.feeInformation) {
    gcAdvanceSetting.feeInformation = {
      en: params.en?.feeInformation || '',
      th: params.th?.feeInformation || '',
    }
  }
  if (params.en?.prizeText || params.th?.prizeText) {
    gcAdvanceSetting.prizeText = {
      en: params.en?.prizeText || '',
      th: params.th?.prizeText || '',
    }
  }


  if (params.quantityLimit) {
    gcAdvanceSetting.qtyLimit = params.quantityLimit
  }
  if (params.hoursUntilRepurchasable) {
    gcAdvanceSetting.hoursUntilRePurchasable = params.hoursUntilRepurchasable
  }
  if (params.requires3ds !== undefined) {
    gcAdvanceSetting.isRequire3ds = params.requires3ds
  }
  if (params.projectSupportedId) {
    gcAdvanceSetting.projectSupportedId = params.projectSupportedId
  }
  if (params.slug) {
    gcAdvanceSetting.slug = params.slug
  }
  if (params.visibility) {
    gcAdvanceSetting.visibility = params.visibility
  }
  if (params.invisibleTo) {
    gcAdvanceSetting.invisibleTo = params.invisibleTo
  }
  if (params.order !== undefined) {
    gcAdvanceSetting.menuOrder = params.order
  }

  if (params.autoReplenishStock !== undefined) {
    gcAdvanceSetting.replenishStock = params.autoReplenishStock
  }
  if (params.autoReplenishStockQuantity !== undefined) {
    gcAdvanceSetting.qtyStock = params.autoReplenishStockQuantity
  }
  if (params.relatedGivecards !== undefined) {
    gcAdvanceSetting.savedRelatedGiveCards = params.relatedGivecards.split(',')
  }
  if (params.autoExpireDate !== undefined) {
    gcAdvanceSetting.isEnablePostExpiration = !!params.autoExpireDate
    if (gcAdvanceSetting.isEnablePostExpiration) {
      gcAdvanceSetting.postExpirator = params.autoExpireDate
    }
  }

  result.advanceSetting = gcAdvanceSetting

  return result
}

export function givecardsMultipleFromApi (params) {
  return params.map(givecardsSingleFromApi)
}
