import jsonServerProvider from 'ra-data-json-server'

import Api from './api/index'
import * as Transformers from './api/transformer'

const provider = jsonServerProvider(process.env.REACT_APP_API_URL, Api)

export default {
  getList: async function (resource, params) {
    const response = await provider.getList(resource, params)
    const respTransformerFn = Transformers[`${resource}MultipleFromApi`]
    if (respTransformerFn) {
      response.data = respTransformerFn(response.data)
    }
    return response
  },
  getOne: async function (resource, params) {
    const response = await provider.getOne(resource, params)
    const respTransformerFn = Transformers[`${resource}SingleFromApi`]
    if (respTransformerFn) {
      response.data = respTransformerFn(response.data)
    }
    return response
  },
  getMany: async function (resource, params) {
    const response = await provider.getMany(resource, params)
    const respTransformerFn = Transformers[`${resource}MultipleFromApi`]
    if (respTransformerFn) {
      response.data = respTransformerFn(response.data)
    }
    return response
  },
  getManyReference: async function (resource, params) {
    const response = await provider.getManyReference(resource, params)
    const respTransformerFn = Transformers[`${resource}SingleFromApi`]
    if (respTransformerFn) {
      response.data = respTransformerFn(response.data)
    }
    return response
  },
  update: async function (resource, params) {
    const reqTransformerFn = Transformers[`${resource}SingleToApi`]
    if (reqTransformerFn) {
      params.data = reqTransformerFn(params.data)
    }
    const response = await provider.update(resource, params)
    const respTransformerFn = Transformers[`${resource}SingleFromApi`]
    if (respTransformerFn) {
      response.data = respTransformerFn(response.data)
    }
    return response
  },
  updateMany: async function (resource, params) {
    const reqTransformerFn = Transformers[`${resource}SingleToApi`]
    if (reqTransformerFn) {
      params.data = reqTransformerFn(params.data)
    }
    const response = await provider.updateMany(resource, params)
    const respTransformerFn = Transformers[`${resource}MultipleFromApi`]
    if (respTransformerFn) {
      response.data = respTransformerFn(response.data)
    }
    return response
  },
  create: async function (resource, params) {
    const reqTransformerFn = Transformers[`${resource}SingleToApi`]
    if (reqTransformerFn) {
      params.data = reqTransformerFn(params.data)
    }
    const response = await provider.create(resource, params)
    const respTransformerFn = Transformers[`${resource}SingleFromApi`]
    if (respTransformerFn) {
      response.data = respTransformerFn(response.data)
    }
    return response
  },
  delete: async function (resource, params) {
    return provider.delete(resource, params)
  },
  deleteMany: async function (resource, params) {
    return provider.deleteMany(resource, params)
  },
}
