import {
  Card,
  CardHeader,
  Box,
  Link,
  Button,
  IconButton,
  Typography,
  makeStyles,
  Tooltip,
} from '@material-ui/core'
import { ReportRounded, Visibility } from '@material-ui/icons'
import jsonExport from 'jsonexport/dist'
import keyBy from 'lodash/keyBy'
import React, { cloneElement, useMemo } from 'react'
import {
  ListBase,
  TopToolbar,
  CreateButton,
  Datagrid,
  TextField,
  useListContext,
  sanitizeListRestProps,
  EditButton,
  ReferenceField,
  useRedirect,
  ListToolbar,
  BulkActionsToolbar,
  Pagination,
  useListController,
  ListContextProvider,
  useTranslate,
  BulkDeleteButton,
  Query,
  Filter,
  TextInput,
  SelectInput,
  ExportButton,
  usePermissions,
  downloadCSV
} from 'react-admin'

import { Auth } from '../../providers'

const useStyles = makeStyles((theme) => ({
  issueIcon: {
    color: ({ hasOpenIssue }) => hasOpenIssue >= 1
      ? theme.palette.warning.main
      : theme.palette.secondary.light,
  }
}))

const givecardExporter = givecards => {
  const givecardsForExport = givecards.map(data => {
    const { id, business: { name: BusinessName }, details: { en: { shortDesc }, category } } = data
    return { id, 'Business Name': BusinessName, Title: shortDesc, Category: category }
  })
  jsonExport(givecardsForExport, {
    headers: []
  }, (err, csv) => {
    downloadCSV(csv, 'givecards') // download as 'givecards.csv` file
  })
}

const GiveCardsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="GiveCard Id" source="id" alwaysOn/>
    <SelectInput source="status" label="Status" allowEmpty emptyText="Show All" choices={[
      { id: 'draft', name: 'Draft' },
      { id: 'pending', name: 'Pending' },
      { id: 'rejected', name: 'Rejected' },
      { id: 'approved', name: 'Approved' },
      { id: 'published', name: 'Published' },
      { id: 'sample', name: 'Sample' },
    ]} alwaysOn/>
    <TextInput label="Business Id" source="business.id" />
    <TextInput label="Business Name" source="business.name" alwaysOn/>
  </Filter>
)

const ListActions = (props) => {
  const {
    className,
    filters,
    maxResults,
    currentSort,
    total,
    permanentFilter,
    hasListAllPermission,
    ...rest
  } = props
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath}/>
      { hasListAllPermission ? (
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={{ ...filterValues, ...permanentFilter }}
          exporter={givecardExporter}
          maxResults={maxResults}
        />
      ) : null }
    </TopToolbar>
  )
}

const GiveCardBulkActionButtons = props => (
  <>
    <BulkDeleteButton {...props} />
  </>
)

const StatusButton = ({ record }) => {
  const issueCount = record?.issues?.length || 0
  const openIssueCount = record?.issues?.filter(issue => issue.status === 'open').length || 0
  const classes = useStyles({ hasOpenIssue: openIssueCount })
  const redirect = useRedirect()

  const handleOnBtnClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    redirect(`/givecards/${record.id}/issues`)
  }

  return record ? (
    <Box display="flex">
      <Box my="auto">
        <Typography variant="body2">{record.status}</Typography>
      </Box>
      {
        issueCount >= 1 && (
          <Box my="auto" ml={1}>
            <Tooltip title={openIssueCount} placement="top" arrow>
              <Link
                underline="none"
                href={`${document.location.origin}/givecards/${record.id}/issues`}
              >
                <IconButton
                  className={classes.issueIcon}
                  size="small"
                  aria-label="issue"
                  onClick={handleOnBtnClick}
                >
                  <ReportRounded/>
                </IconButton>
              </Link>
            </Tooltip>
          </Box>
        )
      }
    </Box>
  ) : null
}

const PreviewButton = ({ record }) => {
  return record ? (
    <Link
      underline="none"
      href={`${process.env.REACT_APP_WEB_URL}/shop/${record.id}?preview`}
      target="_blank"
    >
      <Button color="secondary" variant="text" startIcon={<Visibility/>}>
        Preview
      </Button>
    </Link>
  ) : null
}

const ConditionalEmailField = ({ permissions, ...props }) => {
  const { checkPermissions, ACTIONS } = Auth
  const isEditAll = permissions && checkPermissions(ACTIONS.GIVECARD_EDIT_ALL, permissions)
  const isEditAble = props.record && ['draft', 'rejected'].includes(props.record.status)
  return (
    isEditAll || isEditAble
      ? <EditButton {...props}/>
      : null
  )
}

const GiveCardList = props => {
  // Const
  const { checkPermissions, ACTIONS } = Auth
  const { loaded: permissionLoaded, permissions } = usePermissions()
  const translate = useTranslate()

  const sampleGiveCardPayload = {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'id', order: 'DESC' },
  }

  // Memo
  const hasListAllPermission = useMemo(() => {
    if (permissionLoaded) {
      return checkPermissions(ACTIONS.GIVECARD_LIST_ALL, permissions)
    } else {
      return false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionLoaded, permissions])

  return (
    <ListBase
      {...props}
      exporter={givecardExporter}
    >
      <ListToolbar
        filters={<GiveCardsFilter/>}
        actions={<ListActions hasListAllPermission={hasListAllPermission}/>}
      />
      <Query type='getList' resource={`${props.resource}/samples`} payload={sampleGiveCardPayload}>
        {({ data, loading, error }) => {
          if (loading || error || !data.length) return null
          return (
            <Box mb={2}>
              <Card variant="outlined">
                <CardHeader title="Sample GiveCards"/>
                <ListContextProvider value={{
                  resource: `${props.resource}/samples`,
                  basePath: `/${props.resource}`,
                  data: keyBy(data, 'id'),
                  ids: data.map(({ id }) => id),
                  currentSort: {},
                  selectedIds: [],
                }}>
                  <Datagrid rowClick="show">
                    <TextField sortable={false} source="id"/>
                    <TextField sortable={false} source="business.name" label="Business Name"/>
                    <TextField sortable={false} source="en.shortBusinessDescription" label="Title"/>
                    <TextField sortable={false} source="category" label="Category"/>
                    <PreviewButton/>
                  </Datagrid>
                </ListContextProvider>
              </Card>
            </Box>
          )
        }}
      </Query>
      <Card>
        <CardHeader title={translate('resources.givecards.title')}/>
        <BulkActionsToolbar>
          <GiveCardBulkActionButtons/>
        </BulkActionsToolbar>
        <ListContextProvider value={useListController(props)}>
          <Datagrid rowClick="show" hasBulkActions>
            <TextField source="id"/>
            <ReferenceField label="Business Name" source="business.id" reference="businesses" sortable={false}>
              <TextField source="name"/>
            </ReferenceField>}
            <TextField source="details.en.shortDesc" label="Title" sortable={false}/>
            <TextField source="details.category" label="Category" sortable={false}/>
            <StatusButton label="Status"/>
            <ConditionalEmailField permissions={props.permissions}/>
            <PreviewButton/>
          </Datagrid>
        </ListContextProvider>
        <Pagination/>
      </Card>
    </ListBase>
  )
}

export default GiveCardList
