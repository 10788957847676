export function arrayIntersect (a1, a2) {
  return a1.filter((x) => a2.includes(x))
}

export function _validatePermission (requiredPermissions, userPermissions) {
  if (!requiredPermissions?.length) return true

  for (let rp of requiredPermissions) {
    if (userPermissions[rp]) return true
  }
  return false
}

export function convertToSlug (text) {
  return text
    ?.toLowerCase()
    ?.replace(/ /g, '-')
    ?.replace(/[^\w-]+/g, '')
}

export function getRandomInt (min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function isDataURL (s) {
  return !!s?.match(/^data:((?:\w+\/(?:(?!;).)+)?)((?:;[\w\W]*?[^;])*),(.+)$/gmu)
}

// Form Utils
export const composeValidators = (...validators) => value => (
    validators.reduce((error, validator) => error || validator(value), undefined)
)

export const isFieldError = ({ error, modified, submitFailed }) => {
  return (submitFailed || modified) && !!error
}

export const getFieldErrorMessage = (meta) => {
  return isFieldError(meta) ? meta.error?.message || meta.error || '' : ''
}

export const errorFieldProp = ({ error, modified, submitFailed }) => {
  return (submitFailed || modified) ? {
    error: !!error,
    helperText: error?.message || error || null
  } : {}
}

export const allowNullParse = (str) => str
