import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

let jsonFile
switch (process.env.REACT_APP_ENV) {
  case 'production':
    jsonFile = 'socialgiver-live.json'
    break
  case 'qa':
    jsonFile = 'socialgiver-qa.json'
    break
  case 'sandbox':
    jsonFile = 'socialgiver-qa.json'
    break
  default:
    jsonFile = 'socialgiver-qa.json'
    break
}

const firebaseConfig = require(`./${jsonFile}`)

firebase.initializeApp(firebaseConfig)

export default firebase
