import jsonExport from 'jsonexport/dist'
import { downloadCSV } from 'react-admin'

const exportDDLReport = (record, links, name) => {
  var newData = []  

  record.forEach((items, index) => {
    var newRecord = {}
    newRecord['url'] = links[index]
    items.forEach((item) => {
      newRecord[item.platform + '_' + item.event] = item.count
    })    
    newData.push(newRecord) 
  })
  
  jsonExport(newData, (err, csv) => {    
    downloadCSV(csv, name)
  })
}

const exportSimpleReport = (rows, name) => {
  jsonExport(rows, {
    headers: []
  }, (err, csv) => {
    downloadCSV(csv, name)
  })
}

export default {
  exportSimpleReport: exportSimpleReport,
  exportDDLReport: exportDDLReport
}
