import {
  Container,
  Grid,
  Paper,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Box,
  Backdrop,
  Typography,
  makeStyles,
  Button,
  Link as MUILink
} from '@material-ui/core'
import { Visibility, Publish } from '@material-ui/icons'
import React, { useEffect, forwardRef, useImperativeHandle } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { giveCardFormStyles } from './style'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    textAlign: 'center',
    padding: theme.spacing(1),
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    borderWidth: 1,
    height: 'auto',
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    maxHeight: 124,
    objectFit: 'cover',
    pointerEvents: 'none',
    userSelect: 'none',
    borderRadius: theme.shape.borderRadius,
    filter: props => props.uploading ? 'opacity(0.1)' : 'unset',
    transition: 'filter 500ms ease',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  textLeft: {
    textAlign: 'left'
  }
}))

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const transformToPreviewImage = (values) => {
  return values.map((value, index) => {
    const { src, ...img } = value
    return { ...img, index, id: `${value.name}-${index}` }
  })
}

const transformToImagesProps = (values, images) => {
  return values.map(image => {
    const { id, index, ...img } = image
    return {
      ...img,
      src: images[index].src
    }
  })
}

function ImagePreview (props, ref) {
  const { images, itemClassName, label, imageSize = [{ w: 58, h: 58 }], dndAble, uploading = false,
    onUpdated, onRemove, disabled
  } = props

  // State
  const [previewImages, setPreviewImages] = React.useState(transformToPreviewImage(images))
  const [openPreview, setOpenPreview] = React.useState(false)
  const [showingPreviewImg, setShowingPreviewImg] = React.useState(null)

  // Const
  const classes = useStyles({ uploading })
  const giveCardFormClasses = giveCardFormStyles()

  // Functions
  useEffect(() => setPreviewImages(transformToPreviewImage(images)), [images])

  useImperativeHandle(ref, () => ({
    getImages: () => {
      return transformToImagesProps(previewImages, images)
    }
  }))

  const getImageSrc = (previewImg) => {
    return images.length > previewImg.index ? images[previewImg.index].src : null
  }

  const onDragEnd = (result) => {
    // the only one that is required
    if (!result.destination) {
      return
    }
    const items = reorder(
      previewImages,
      result.source.index,
      result.destination.index
    )
    if (onUpdated) {
      onUpdated(transformToImagesProps(items, images))
    } else {
      setPreviewImages(items)
    }
  }

  const handleOnRemove = (previewImage) => {
    if (onRemove) onRemove(getImageSrc(previewImage))
  }

  const showPreview = (previewImg) => {
    setShowingPreviewImg(previewImg)
    setOpenPreview(true)
  }

  const closePreview = () => {
    setOpenPreview(false)
  }

  return (
    <Paper ref={ref} variant="outlined" className={classes.root}>
      {
        images.length ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="previewImageDroppable" isDropDisabled={!dndAble || disabled}>
              {(provided) => (
                <Grid
                  container
                  spacing={2}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {previewImages.map((previewImg, index) => (
                    <Draggable key={previewImg.id} draggableId={previewImg.id} index={index} isDragDisabled={!dndAble || disabled}>
                      {(provided) => (
                        <Grid
                          item
                          xs={12}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Paper className={giveCardFormClasses.paperItem} variant="outlined">
                            <Grid container justify="flex-start" alignItems="flex-start" spacing={1}>
                              <Grid item xs={5} sm={4}>
                                <img
                                  className={classes.thumbnail}
                                  src={getImageSrc(previewImg)}
                                  alt={previewImg.name}
                                />
                              </Grid>
                              <Grid
                                container
                                item
                                xs={7}
                                sm={8}
                                direction="column"
                                justify="space-between"
                                alignItems="flex-start"
                                spacing={1}
                              >
                                <Grid item>
                                  <Typography variant="subtitle1" className={classes.textLeft}>{previewImg.name?.slice(-22)}</Typography>
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    startIcon={uploading ? <Publish/> : <Visibility/>}
                                    onClick={() => showPreview(previewImg)}
                                  >
                                    { uploading ? 'Uploading...' : 'Preview' }
                                  </Button>
                                </Grid>
                                {
                                  disabled ? (
                                    <Grid item>
                                      <MUILink
                                        underline="none"
                                        href={getImageSrc(previewImg)}
                                        target="_blank"
                                      >
                                        <Button color="secondary" variant="text">
                                          Download
                                        </Button>
                                      </MUILink>
                                    </Grid>
                                  ) : null
                                }
                                {
                                  dndAble && !disabled && (
                                    <Grid item>
                                      <Button
                                        variant="text"
                                        size="small"
                                        onClick={() => handleOnRemove(previewImg)}
                                      >
                                        Remove
                                      </Button>
                                    </Grid>
                                  )
                                }
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <Box margin="auto" padding={2}>
            {label && <Typography color="textPrimary" variant="subtitle1">{label}</Typography>}
            <Typography color="textSecondary" variant="body2">Preview</Typography>
            <Typography color="textSecondary" variant="caption">
              {
                imageSize.map((s) => `${s.w}x${s.h}px`).join(', ')
              }
            </Typography>
          </Box>
        )
      }
      <Backdrop className={classes.backdrop} open={openPreview}>
        <Container maxWidth="lg">
          <Card>
            <CardHeader title={`Preview: ${showingPreviewImg?.name}`}/>
            <CardContent>
              <Box maxHeight="80vh" overflow="scroll">
                {
                  showingPreviewImg && imageSize.map((s, i) => (
                    <Box display="inline-block" m={0.5} key={`${showingPreviewImg.name}-${i}`}>
                      <img
                        className={itemClassName}
                        style={{ width: s.w, height: s.h, objectFit: 'cover' }}
                        src={getImageSrc(showingPreviewImg)}
                        alt={showingPreviewImg.name}
                      />
                      <Typography variant="caption" display="block">{`${s.w}x${s.h}px`}</Typography>
                    </Box>
                  ))
                }
              </Box>
            </CardContent>
            <CardActions>
              <Box ml="auto">
                <Button
                  onClick={closePreview}
                  variant="text"
                  color="primary"
                >
                  Close
                </Button>
              </Box>
            </CardActions>
          </Card>
        </Container>
      </Backdrop>
    </Paper>
  )
}

export default forwardRef(ImagePreview)
