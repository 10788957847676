function snakeCase (str) {
  return str
    .replace(/([^_])?(give)?([A-Z0-9]+)/g, function (substr, leading, skip, match) { return (skip) ? substr : `${leading}_${match.toLowerCase()}` })
}

function camelCase (str) {
  return str
    .replace(/(.)_([a-z0-9])/g, function (substr, leading, match) { return leading + match.toUpperCase() })
}

function convertObjectKeyWithFunction (obj, func, maxDepth = -1, currentDepth = 0) {
  if (maxDepth === currentDepth) return obj
  if (Array.isArray(obj)) {
    return obj.map((item) => convertObjectKeyWithFunction(item, func, maxDepth, currentDepth))
  } else if (obj?.constructor === Object) {
    const result = {}
    Object.keys(obj).forEach((key) => { result[func(key)] = convertObjectKeyWithFunction(obj[key], func, maxDepth, currentDepth + 1) })
    return result
  } else if (obj?.constructor === Error) {
    return { fuck:1 }
  } else {
    return obj
  }
}

function convertCamelCaseKeyToSnakeCase (obj, depth) {
  return convertObjectKeyWithFunction(obj, snakeCase, depth)
}

function convertSnakeCaseKeyToCamelCase (obj, depth) {
  return convertObjectKeyWithFunction(obj, camelCase, depth)
}

// ////////////////////////////////////////////////////////////////

export async function request (config) {
  config.params = convertCamelCaseKeyToSnakeCase(config.params)
  if (config.data) {
    config.data = convertCamelCaseKeyToSnakeCase(config.data)
  }
  return config
}

export function response (response) {
  return convertSnakeCaseKeyToCamelCase(response)
}
