import {
  maxLength as _maxLength,
  required as _required,
  email as _email,
  number as _number,
  minValue as _minValue,
  regex as _regex
} from 'react-admin'

export const required = _required('Required')
export const email = _email('Must be an email')
export const number = _number('Must be a number')
export const minValue = (v) => _minValue(v, `Should be more than or equal ${v}`)
export const maxLength = (l) => _maxLength(l, `Should be at most ${l} characters`)
export const regex = (p, m) => _regex(p, m)
export const thaiRegex = _regex(/^[ก-๙0-9()-/ ]*$/m, 'Please fill in data in Thai')
export const numberRegex = _regex(/^[0-9-]*$/m, 'Must be a number')
