import Issue from '../issues/giveCardIssue'
import Create from './create'
import List from './list'
import Show from './show'

export default {
  create: Create,
  edit: Create,
  list: List,
  show: Show,
  issue: Issue,
}
