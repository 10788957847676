import { useState, useEffect } from 'react'

function useColorScheme () {
  const matches = window.matchMedia?.('(prefers-color-scheme: dark)').matches
  const [colorScheme, setColorScheme] = useState(matches ? 'dark' : 'light')

  useEffect(function () {
    function listener (e) {
      setColorScheme(e.matches ? 'dark' : 'light')
    }

    const matchedQuery = window.matchMedia('(prefers-color-scheme: dark)')

    // eslint-disable-next-line babel/no-unused-expressions
    matchedQuery.addEventListener
      ? matchedQuery.addEventListener('change', listener)
      : matchedQuery.addListener(listener)

    return function () {
      // eslint-disable-next-line babel/no-unused-expressions
      matchedQuery.removeEventListener
        ? matchedQuery.removeEventListener('change', listener)
        : matchedQuery.removeListener(listener)
    }
  })

  return colorScheme
}

export default useColorScheme
