import { Card, CardHeader } from '@material-ui/core'
import React from 'react'
import { useCreateController, useEditController } from 'react-admin'

import Business from '../givecards/business'

function BusinessEdit (props) {
  const controller = !props.id ? useCreateController : useEditController
  const {
    defaultTitle,
    record,
    save,
  } = controller(props)
  const handleSaveBtn = async (values) => {
    save(values, 'list')
  }

  return (
    <Card>
      <CardHeader title={defaultTitle}/>
      <Business
        // Ref: givecards/create -> getInitialValues(0)
        initialValues={!props.id ? { isSameAsPrimary: false, payments: [{}] } : record}
        onSubmit={handleSaveBtn}
        businessList={[]}
        selectedBusinessId="add"
        useController={true}
      />
    </Card>
  )
}

export default BusinessEdit
