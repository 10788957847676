import {
  Button,
  Card,
  Paper,
  CardActions,
  CardContent,
  Grid,
  Chip,
  makeStyles,
  TextField,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormLabel,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@material-ui/core'
import { Save, Add, HighlightOff, DeleteOutline } from '@material-ui/icons'
import {
  ToggleButtonGroup,
  ToggleButton,
  Autocomplete
} from '@material-ui/lab'
import { KeyboardTimePicker } from '@material-ui/pickers'
import arrayMutators from 'final-form-arrays'
import React, { forwardRef, useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'

import { allowNullParse, composeValidators, errorFieldProp, getFieldErrorMessage, isFieldError } from '../../utils.js'
import { required, maxLength, number, minValue } from '../../validations'
import GCConditions from './conditions.json'
import { defaultOpeningHours } from './configuration'
import { GiveCardFormProps } from './create'
import GiveCardFormConfig from './formConfig.json'
import { GiveCardFooter } from './giveCardFooter'
import { giveCardFormStyles } from './style'

const useStyles = makeStyles((theme) => ({
  toggleBtn: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  dateTimeField: {
    width: 120,
  },
  btnGrid: {
    padding: theme.spacing(1),
  },
}))

function renderAutocompleteItem (value, getTagProps) {
  return value.map((option, index) => (
    <Chip color="secondary" label={option} {...getTagProps({ index })} />
  ))
}

function renderAutocompleteInput (params) {
  return <TextField {...params} label="Hashtags" variant="outlined" margin="dense"/>
}

function Details (props: GiveCardFormProps, ref) {
  // State
  const [contentLang, setContentLang] = React.useState('en')
  const [hashtags, setHashtags] = React.useState(props.initialValues.hashtags)

  // Const
  const classes = useStyles()
  const giveCardFormClasses = giveCardFormStyles()
  const formControlProps = {
    disabled: !!props.disableForm,
    required: true,
    fullWidth: true,
    size: 'small'
  }
  let isSaveDraft = false
  let isEngLang = contentLang === 'en'
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  // Functions
  useEffect(() => {
    setHashtags(props.initialValues.hashtags)
  }, [props.initialValues.hashtags])

  const onSaveDraft = (event, handleSubmit) => {
    isSaveDraft = true
    handleSubmit(event)
  }

  const onSubmit = (values) => {
    props.onSubmit({ ...values, hashtags }, isSaveDraft)
    isSaveDraft = false
  }

  const validate = (values) => {
    const errors = {}
    const { en, th } = values
    // TODO: Verify all fields
    if (!en || !th) {
      errors.tmp = { contentLang: 1 }
    }
    return errors
  }

  const handleContentLangToggle = (event, newLang) => {
    if (newLang !== null) {
      setContentLang(newLang)
    }
  }

  const handleOnHashtagsChanged = (event, newValue) => {
    const newVal = {}
    newVal[contentLang] = newValue
    setHashtags({ ...hashtags, ...newVal })
  }

  return (
    <Card ref={ref} elevation={0}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={props.initialValues}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, form: { mutators: { push } } }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ToggleButtonGroup
                    value={contentLang}
                    exclusive
                    size="small"
                    onChange={handleContentLangToggle}
                    aria-label="content language"
                  >
                    {['en', 'th'].map(value => (
                      <ToggleButton
                        key={value}
                        value={value}
                        className={classes.toggleBtn}
                        aria-label={`${value} language`}
                      >
                        <Typography variant="button" color={contentLang === value ? 'textPrimary' : 'textSecondary'}>
                          {value}
                        </Typography>
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
                {
                  !props.disableForm && (
                    <Grid item xs={12}>
                      <Typography variant="body1" color="primary">
                        Please fill in your business details both in Eng and Thai.
                      </Typography>
                    </Grid>
                  )
                }
                <Grid item xs={12} sm={7}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Field name="category" validate={required}>
                              {({ input, meta }) => (
                                <FormControl {...formControlProps} error={isFieldError(meta)}>
                                  <InputLabel id="givecard-category">GiveCard Category</InputLabel>
                                  <Select
                                    {...input}
                                    labelId="givecard-category"
                                    id="category"
                                  >
                                    {
                                      Object.keys(GiveCardFormConfig.category).map((key) => (
                                        <MenuItem key={key} value={key}>{GiveCardFormConfig.category[key]}</MenuItem>))
                                    }
                                  </Select>
                                  <FormHelperText>{getFieldErrorMessage(meta)}</FormHelperText>
                                </FormControl>
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Field name={`${contentLang}.shortDesc`} validate={composeValidators(required, maxLength(100))}>
                              {({ input, meta }) => (
                                <TextField
                                  {...formControlProps}
                                  {...input}
                                  id="short-desc"
                                  label="Short Business Description"
                                  inputProps={{ maxLength: 100 }}
                                  helperText="Limit 100 characters"
                                  {...errorFieldProp(meta)}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Field name="branches" subscription={{ value: true }}>
                            {({ input: { value } }) =>  (value.length > 1 ? (
                              <Grid item xs={12}>
                                <Field name={`${contentLang}.title`} validate={composeValidators(required, maxLength(100))}>
                                  {({ input, meta }) => (
                                    <TextField
                                      {...formControlProps}
                                      {...input}
                                      id="title"
                                      label="GiveCard Title"
                                      inputProps={{ maxLength: 100 }}
                                      {...errorFieldProp(meta)}
                                    />
                                  )}
                                </Field>
                              </Grid>
                            ) : null )}
                          </Field>
                          <Grid item xs={12}>
                            <Field name={`${contentLang}.desc`} validate={composeValidators(required, maxLength(1000))}>
                              {({ input, meta }) => (
                                <TextField
                                  {...formControlProps}
                                  {...input}
                                  size="medium"
                                  id="desc"
                                  label="Description"
                                  variant="outlined"
                                  multiline
                                  rowsMax={36}
                                  inputProps={{ maxLength: 1000 }}
                                  {...errorFieldProp(meta)}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Field name={`${contentLang}.productIncludes`} validate={required}>
                              {({ input, meta }) => (
                                <TextField
                                  {...formControlProps}
                                  {...input}
                                  size="medium"
                                  id="givecard-include"
                                  placeholder="What will customers get from using this GiveCard?"
                                  variant="outlined"
                                  multiline
                                  rowsMax={18}
                                  {...errorFieldProp(meta)}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Field name={`${contentLang}.address`} validate={required}>
                              {({ input, meta }) => (
                                <TextField
                                  {...formControlProps}
                                  {...input}
                                  size="medium"
                                  id="address"
                                  label="Address"
                                  variant="outlined"
                                  multiline
                                  rowsMax={6}
                                  {...errorFieldProp(meta)}
                                />
                              )}
                            </Field>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                        <Typography variant='subtitle2' className={classes.title}>
                          Condition
                        </Typography>
                        <Field name="category" subscription={{ value: true }}>
                          {({ input: { value } }) => (
                            <FieldArray name="conditions" defaultValue={GCConditions[value]}>
                              {({ fields }) => (
                                <Grid container spacing={2}>
                                  {
                                    fields.map((name, index) => (
                                      <Grid container item spacing={1} alignItems="baseline" key={`${name}-${index}`}>
                                        <Grid item xs={11}>
                                          <Field
                                            name={`${name}.${contentLang}`}
                                            validate={required}>
                                            {({ input, meta }) => (
                                              <TextField
                                                {...formControlProps}
                                                {...input}
                                                id="condition"
                                                multiline
                                                rowsMax={6}
                                                {...errorFieldProp(meta)}
                                              />
                                            )}
                                          </Field>
                                        </Grid>
                                        {
                                          !props.disableForm && (
                                            <Grid item xs={1}>
                                              <IconButton
                                                size="small"
                                                aria-label="remove"
                                                onClick={() => fields.remove(index)}>
                                                <HighlightOff/>
                                              </IconButton>
                                            </Grid>
                                          )
                                        }
                                      </Grid>
                                    ))
                                  }
                                  {
                                    !props.disableForm && (
                                      <Grid container item xs={12}>
                                        <Button
                                          className={giveCardFormClasses.rightBtn}
                                          variant="text"
                                          size="small"
                                          color="primary"
                                          disabled={props.disableForm}
                                          onClick={() => push(fields.name, {})}
                                          startIcon={<Add/>}>
                                          Add Condition
                                        </Button>
                                      </Grid>
                                    )
                                  }
                                </Grid>
                              )}
                            </FieldArray>
                          )}
                        </Field>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                        <Typography className={giveCardFormClasses.fieldGroupTitle} variant="subtitle2">Opening hours</Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Field name="alwaysOpen" type="checkbox">
                              {({ input }) => (
                                <FormControlLabel
                                  disabled={formControlProps.disabled}
                                  control={<Checkbox {...input} color="primary"/>}
                                  label="Always open"
                                />
                              )}
                            </Field>
                          </Grid>
                          <Field name="alwaysOpen" subscription={{ value: true }}>
                            {({ input: { value } }) => (
                              !value && (
                                <FieldArray name="openingHours">
                                  {({ fields }) => (
                                    fields.map((name, index) => (
                                      <Grid item xs={12} key={`${name}-${index}`}>
                                        <Paper className={giveCardFormClasses.paperItem} variant="outlined">
                                          <Grid container spacing={2}>
                                            <Grid container item xs={12} sm={6} spacing={2}>
                                              <Grid item xs={12}>
                                                <Field name={`${name}.date.start`} validate={required}>
                                                  {({ input, meta }) => (
                                                    <FormControl
                                                      {...formControlProps}
                                                      error={isFieldError(meta)}>
                                                      <InputLabel id="start-date">Start date</InputLabel>
                                                      <Select
                                                        {...input}
                                                        disabled={!isEngLang || props.disableForm}
                                                        labelId="start-date"
                                                        id="startDate">
                                                        {
                                                          days.map((day, index) => (
                                                            <MenuItem key={day} value={index}>{day}</MenuItem>)
                                                          )
                                                        }
                                                      </Select>
                                                      <FormHelperText>{getFieldErrorMessage(meta)}</FormHelperText>
                                                    </FormControl>
                                                  )}
                                                </Field>
                                              </Grid>
                                              <Grid item xs={12}>
                                                <Field name={`${name}.time.start`} validate={required}>
                                                  {({ input }) => (
                                                    <KeyboardTimePicker
                                                      required
                                                      {...input}
                                                      disabled={!isEngLang || props.disableForm}
                                                      id="start-time-picker"
                                                      ampm={false}
                                                      format="HH:mm"
                                                      label="Start time"
                                                      openTo="hours"
                                                      views={['hours', 'minutes']}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                      }}
                                                    />
                                                  )}
                                                </Field>
                                              </Grid>
                                            </Grid>
                                            <Grid container item xs={12} sm={6} spacing={2}>
                                              <Grid item xs={12}>
                                                <Field name={`${name}.date.end`} validate={required}>
                                                  {({ input, meta }) => (
                                                    <FormControl
                                                      {...formControlProps}
                                                      error={isFieldError(meta)}>
                                                      <InputLabel id="end-date">End date</InputLabel>
                                                      <Select
                                                        {...input}
                                                        disabled={!isEngLang || props.disableForm}
                                                        labelId="end-date"
                                                        id="endDate">
                                                        {
                                                          days.map((day, index) => (
                                                            <MenuItem key={day} value={index}>{day}</MenuItem>)
                                                          )
                                                        }
                                                      </Select>
                                                      <FormHelperText>{getFieldErrorMessage(meta)}</FormHelperText>
                                                    </FormControl>
                                                  )}
                                                </Field>
                                              </Grid>
                                              <Grid item xs={12}>
                                                <Field name={`${name}.time.end`} validate={required}>
                                                  {({ input }) => (
                                                    <KeyboardTimePicker
                                                      required
                                                      {...input}
                                                      disabled={!isEngLang || props.disableForm}
                                                      id="end-time-picker"
                                                      ampm={false}
                                                      format="HH:mm"
                                                      label="End time"
                                                      openTo="hours"
                                                      views={['hours', 'minutes']}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                      }}
                                                    />
                                                  )}
                                                </Field>
                                              </Grid>
                                            </Grid>
                                            {
                                              (!props.disableForm && index > 0) && (
                                                <Grid container item xs={12}>
                                                  <IconButton
                                                    className={giveCardFormClasses.rightBtn}
                                                    size="small"
                                                    aria-label="remove"
                                                    disabled={!isEngLang}
                                                    onClick={() => fields.remove(index)}>
                                                    <DeleteOutline/>
                                                  </IconButton>
                                                </Grid>
                                              )
                                            }
                                          </Grid>
                                        </Paper>
                                      </Grid>
                                    ))
                                  )}
                                </FieldArray>
                              )
                            )}
                          </Field>
                          <Field name="alwaysOpen" subscription={{ value: true }}>
                            {({ input: { value } }) => (
                              (!value && !props.disableForm) && (
                                <Grid container item xs={12}>
                                  <Button
                                    className={giveCardFormClasses.rightBtn}
                                    disabled={!isEngLang}
                                    variant="text"
                                    size="small"
                                    color="primary"
                                    onClick={() => push('openingHours', defaultOpeningHours)}
                                    startIcon={<Add/>}>
                                    Add
                                  </Button>
                                </Grid>
                              )
                            )}
                          </Field>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Field name={`${contentLang}.additionalPromo`} parse={allowNullParse}>
                              {({ input, meta }) => (
                                <TextField
                                  {...formControlProps}
                                  {...input}
                                  required={false}
                                  size="medium"
                                  id="additional-promotions"
                                  label="Additional promotions offered"
                                  variant="outlined"
                                  multiline
                                  rowsMax={10}
                                  {...errorFieldProp(meta)}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Field name={`${contentLang}.awards`} parse={allowNullParse}>
                              {({ input, meta }) => (
                                <TextField
                                  {...formControlProps}
                                  {...input}
                                  required={false}
                                  size="medium"
                                  id="eco-friendly-awards"
                                  label="Eco-friendly or CSR awards"
                                  variant="outlined"
                                  multiline
                                  rowsMax={10}
                                  {...errorFieldProp(meta)}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              id="hashtags"
                              size="medium"
                              disabled={props.disableForm}
                              fullWidth
                              multiple
                              autoHighlight
                              freeSolo
                              options={[]}
                              value={hashtags[contentLang]}
                              onChange={handleOnHashtagsChanged}
                              renderTags={renderAutocompleteItem}
                              renderInput={renderAutocompleteInput}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <FieldArray name="branches">
                    {({ fields }) => (
                      <Grid container spacing={2}>
                        {
                          fields.map((name, index) => (
                            <Grid item xs={12} key={`${name}-${index}`}>
                              <Paper className={giveCardFormClasses.paperItem} variant="outlined">
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <Field
                                      name={`${name}.${contentLang}.title`}
                                      validate={composeValidators(required, maxLength(70))}
                                    >
                                      {({ input, meta }) => (
                                        <TextField
                                          {...formControlProps}
                                          {...input}
                                          id="title"
                                          label="Title"
                                          variant="outlined"
                                          multiline
                                          rowsMax={2}
                                          inputProps={{ maxLength: 70 }}
                                          {...errorFieldProp(meta)}
                                        />
                                      )}
                                    </Field>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Field name={`${name}.price`} validate={composeValidators(required, number, minValue(0))}>
                                      {({ input, meta }) => (
                                        <TextField
                                          {...formControlProps}
                                          {...input}
                                          id="price"
                                          label="Price"
                                          type="number"
                                          inputProps={{ min: 0 }}
                                          disabled={!isEngLang || props.disableForm}
                                          {...errorFieldProp(meta)}
                                        />
                                      )}
                                    </Field>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Field name={`${name}.value`} validate={composeValidators(required, number, minValue(0))}>
                                      {({ input, meta }) => (
                                        <TextField
                                          {...formControlProps}
                                          {...input}
                                          id="value"
                                          label="Value"
                                          type="number"
                                          inputProps={{ min: 0 }}
                                          disabled={!isEngLang || props.disableForm}
                                          {...errorFieldProp(meta)}
                                        />
                                      )}
                                    </Field>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="caption" color="secondary">Preferred price for best rate
                                      policy</Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Field name={`${name}.quantity`} validate={composeValidators(required, number, minValue(0))}>
                                      {({ input, meta }) => (
                                        <TextField
                                          {...formControlProps}
                                          {...input}
                                          id="quantity"
                                          label="Quantity"
                                          type="number"
                                          inputProps={{ min: 0 }}
                                          disabled={!isEngLang || props.disableForm}
                                          {...errorFieldProp(meta)}
                                        />
                                      )}
                                    </Field>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Field name={`${name}.quotaType`} validate={composeValidators(required)}>
                                      {({ input }) => (
                                        <FormControl {...formControlProps} component="fieldset">
                                          <FormLabel id="quota-type" component="legend">Quota type</FormLabel>
                                          <Select
                                            {...input}
                                            labelId="quota-type"
                                            id="quota-type"
                                            disabled={!isEngLang || props.disableForm}
                                          >
                                            {
                                              Object.keys(GiveCardFormConfig.quotaType).map((key) => (
                                                <MenuItem key={key}
                                                          value={key}>{GiveCardFormConfig.quotaType[key]}</MenuItem>))
                                            }
                                          </Select>
                                        </FormControl>
                                      )}
                                    </Field>
                                  </Grid>
                                  {
                                    (!props.disableForm && index > 0) && (
                                      <Grid container item xs={12}>
                                        <IconButton
                                          className={giveCardFormClasses.rightBtn}
                                          size="small"
                                          aria-label="remove"
                                          onClick={() => fields.remove(index)}>
                                          <DeleteOutline/>
                                        </IconButton>
                                      </Grid>
                                    )
                                  }
                                </Grid>
                              </Paper>
                            </Grid>
                          ))
                        }
                        {
                          !props.disableForm && (
                            <Grid container item xs={12}>
                              <Button
                                className={giveCardFormClasses.rightBtn}
                                variant="text"
                                size="small"
                                color="primary"
                                onClick={() => push(fields.name, defaultBranchItem)}
                                startIcon={<Add/>}>
                                Add Branch
                              </Button>
                            </Grid>
                          )
                        }
                      </Grid>
                    )}
                  </FieldArray>
                </Grid>
              </Grid>
            </CardContent>
            {
              props.showFooter && (
                <CardContent>
                  <GiveCardFooter />
                </CardContent>
              )
            }
            {
              !props.disableForm && (
                <CardActions disableSpacing>
                  <Button
                    onClick={props.handleOnBackBtnClick}
                    variant="contained"
                    color="primary"
                    disabled={props.saving}
                  >
                    Back
                  </Button>
                  {
                    props.saving && (
                      <CircularProgress size={32} className={giveCardFormClasses.rightBtn}/>
                    )
                  }
                  <Button
                    className={props.saving ? giveCardFormClasses.spaceLeft : giveCardFormClasses.rightBtn}
                    onClick={event => onSaveDraft(event, handleSubmit)}
                    variant="outlined"
                    color="primary"
                    disabled={props.saving}
                    startIcon={<Save/>}
                  >
                    Save Draft
                  </Button>
                  <Button
                    className={giveCardFormClasses.spaceLeft}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={props.saving}
                  >
                    Save & Next
                  </Button>
                </CardActions>
              )
            }
          </form>
        )}/>
    </Card>
  )
}

export const defaultBranchItem = { quotaType: 'annually', en: {}, th: {} }

export default forwardRef(Details)
