import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Paper,
  IconButton,
  CircularProgress
} from '@material-ui/core'
import { Add, DeleteOutline, Save } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import arrayMutators from 'final-form-arrays'
import React, { forwardRef, useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'

import { allowNullParse, composeValidators, errorFieldProp } from '../../utils.js'
import { required, email, maxLength, thaiRegex, numberRegex } from '../../validations'
import { GiveCardFormProps } from './create'
import { GiveCardFooter } from './giveCardFooter'
import { giveCardFormStyles } from './style'

const useStyles = makeStyles((theme) => ({
  selectBusinessPaper: {
    padding: theme.spacing(2),
    borderColor: theme.palette.primary.main,
    borderWidth: 1
  },
}))

const addNewBusinessObj = { id: 'add', name: 'Add New Business' }

function Business (props: GiveCardFormProps, ref) {
  // State
  const [isSameAsPrimary, setSameAsPrimary] = React.useState(!!props.initialValues?.isSameAsPrimary)
  const [selectedBusiness, setSelectedBusiness] = React.useState(addNewBusinessObj)
  const { setBusinessId, selectedBusinessId } = props

  // Const
  const classes = useStyles()
  const giveCardFormClasses = giveCardFormStyles()
  const isEnabled = selectedBusinessId === addNewBusinessObj.id
  const formControlProps = {
    required: true,
    fullWidth: true,
    size: 'small',
    disabled: !isEnabled,
    variant: isEnabled ? 'standard' : 'outlined'
  }
  let isSaveDraft = false

  // Functions
  useEffect(() => {
    if (props.businessList.length && selectedBusinessId) {
      setSelectedBusiness(props.businessList.find(({ id }) => id === selectedBusinessId) || addNewBusinessObj)
    }
  }, [selectedBusinessId, props.businessList])

  const onSaveDraft = (event, handleSubmit) => {
    isSaveDraft = true
    handleSubmit(event)
  }

  const onSubmit = (values) => {
    const submitValues = { ...values, selectedBusinessId }

    if (isSameAsPrimary) {
      submitValues.accountantContact = {
        ...submitValues.accountantContact,
        name: submitValues.contact.name,
        email: submitValues.contact.email,
        phoneNumber: submitValues.contact.phoneNumber,
      }
    }
    props.onSubmit(submitValues, isSaveDraft)
    isSaveDraft = false
  }

  const validate = (values) => {
    const errors = {}
    if (!isSameAsPrimary) {
      errors.accountantContact = {
        name: required(values.accountantContact?.name),
        phoneNumber: required(values.accountantContact?.phoneNumber),
        email: required(values.accountantContact?.email)
      }
    }
    return errors
  }

  const getSelectedBusinessData = () => {
    return props.businessList.find(item => item.id === selectedBusinessId) || null
  }

  const handleSwitchChange = (event) => {
    setSameAsPrimary(event.target.checked)
  }

  const handleOnSelectBusiness = (event, newValue) => {
    setBusinessId(newValue.id)
    setSameAsPrimary(newValue.id === addNewBusinessObj.id ? !!props.initialValues?.isSameAsPrimary : false)
  }

  const trimSpaceParse = (value) => {
    return value.replace(' ', '')
  }

  return (
    <Card ref={ref} elevation={0}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={isEnabled ? props.initialValues : getSelectedBusinessData()}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, form: { mutators: { push } } }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <CardContent>
              <Grid container spacing={2}>
                {
                  props.businessList?.length > 0 && !props.disableForm ? (
                    <Grid item xs={12}>
                      <Paper className={classes.selectBusinessPaper} variant="outlined">
                        <FormControl fullWidth variant="outlined" size="small">
                          <Typography variant="overline" id="selected-business">Select Business</Typography>
                          <Autocomplete
                            id="business"
                            margin="dense"
                            value={selectedBusiness}
                            onChange={handleOnSelectBusiness}
                            options={[addNewBusinessObj, ...props.businessList]}
                            loading={!props.businessList}
                            getOptionLabel={(option) => `${option.name}`}
                            getOptionSelected={(option, value) => option.id === value.id}
                            disableClearable
                            renderOption={option => (
                              <Typography
                                color={option.id === addNewBusinessObj.id ? 'primary' : 'secondary'}>
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) =>
                              <TextField {...params} label="Select Business"/>
                            }
                          />
                        </FormControl>
                      </Paper>
                    </Grid>
                  ) : null
                }
                <Grid item xs={12} sm={6}>
                  <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                    <Grid container alignContent="flex-start" spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='subtitle2'>
                          Business Details
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Field name="name" validate={composeValidators(required, maxLength(255))}>
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              {...input}
                              id="business-name"
                              label="Business Name"
                              inputProps={{ maxLength: 255 }}
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field name="contact.name" validate={composeValidators(required)}>
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              {...input}
                              id="sg-contact-name"
                              label="Contact Person - Primary"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field name="contact.email" validate={composeValidators(required, email)} parse={trimSpaceParse}>
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              {...input}
                              id="sg-email"
                              label="Contact Email"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field name="contact.phoneNumber" validate={composeValidators(required)}>
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              {...input}
                              id="sg-phone-number"
                              label="Contact Number"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                    <Grid container alignContent="flex-start" spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='subtitle2'>
                          Customer Contact Person
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Field name="customerContact.name" validate={composeValidators(required)}>
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              {...input}
                              id="sg-contact-name"
                              label="Contact Person - Customer"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field name="customerContact.email" validate={composeValidators(required, email)} parse={trimSpaceParse}>
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              {...input}
                              id="contact-email"
                              label="Contact Email"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field name="customerContact.phoneNumber" validate={composeValidators(required)}>
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              {...input}
                              id="contact-phone-number"
                              label="Contact Number"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field name="websiteUrl" parse={allowNullParse}>
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              required={false}
                              {...input}
                              id="contact-web"
                              label="Website"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field name="lineId" parse={allowNullParse}>
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              required={false}
                              {...input}
                              id="contact-line"
                              label="LINE"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field name="facebookUrl" parse={allowNullParse}>
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              required={false}
                              {...input}
                              id="contact-fb"
                              label="Facebook"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field name="instagramHandle" parse={allowNullParse}>
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              required={false}
                              {...input}
                              id="contact-ig"
                              label="Instagram"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                    <Grid container alignContent="flex-start" spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='subtitle2'>
                          Accountant Contact Person
                        </Typography>
                      </Grid>
                      <Grid item xs={12} hidden={!isEnabled}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isSameAsPrimary}
                              onChange={handleSwitchChange}
                              name="isSameAsPrimary"
                              color="primary"
                            />
                          }
                          label="Same as primary person"
                        />
                      </Grid>
                      <Grid item hidden={isSameAsPrimary && isEnabled} xs={12}>
                        <Field name="accountantContact.name" parse={allowNullParse}>
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              {...input}
                              id="account-contact-name"
                              label="Contact Person - Accounting"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item hidden={isSameAsPrimary && isEnabled} xs={12} sm={6}>
                        <Field
                          name="accountantContact.email"
                          validate={composeValidators(email)}
                          parse={trimSpaceParse}>
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              {...input}
                              id="account-email"
                              label="Contact Email"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item hidden={isSameAsPrimary && isEnabled} xs={12} sm={6}>
                        <Field name="accountantContact.phoneNumber">
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              {...input}
                              id="account-phone-number"
                              label="Contact Number"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                    <Typography variant='subtitle2' className={giveCardFormClasses.fieldGroupTitle}>
                      Please fill in your business details in Thai
                    </Typography>
                    <Grid container alignContent="flex-start" spacing={2}>
                      <Grid item xs={12}>
                        <Field name="companyName" validate={composeValidators(required, thaiRegex)}>
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              {...input}
                              id="registered-name"
                              label="Registered Company Name"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field name="companyAddress" validate={composeValidators(required, thaiRegex)}>
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              {...input}
                              size="medium"
                              id="registered-address"
                              label="Registered Company Address"
                              variant="outlined"
                              multiline
                              rowsMax={10}
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field name="taxId" validate={composeValidators(required, numberRegex)}>
                          {({ input, meta }) => (
                            <TextField
                              {...formControlProps}
                              {...input}
                              id="tax-id"
                              label="Tax ID"
                              {...errorFieldProp(meta)}
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={giveCardFormClasses.paperFiledGroup} variant="outlined">
                    <Typography variant='subtitle2' className={giveCardFormClasses.fieldGroupTitle}>
                      Payment
                    </Typography>
                    <FieldArray name="payments">
                      {({ fields }) =>(
                        <Grid container spacing={2}>
                          {
                            fields.map((name, index) => (
                              <Grid item xs={12} key={`${name}-${index}`}>
                                <Paper className={giveCardFormClasses.paperItem} variant="outlined">
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                      <Field name={`${name}.name`} validate={required}>
                                        {({ input, meta }) => (
                                          <TextField
                                            {...formControlProps}
                                            {...input}
                                            id="bank-name"
                                            label="Bank Name"
                                            {...errorFieldProp(meta)}
                                          />
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Field name={`${name}.accountName`} validate={required}>
                                        {({ input, meta }) => (
                                          <TextField
                                            {...formControlProps}
                                            {...input}
                                            id="bank-account-name"
                                            label="Bank Account Name"
                                            {...errorFieldProp(meta)}
                                          />
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <Field name={`${name}.accountNumber`} validate={composeValidators(required, numberRegex)}>
                                        {({ input, meta }) => (
                                          <TextField
                                            {...formControlProps}
                                            {...input}
                                            id="bank-account-number"
                                            label="Bank Account Number"
                                            {...errorFieldProp(meta)}
                                          />
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <Field name={`${name}.branch`} validate={required}>
                                        {({ input, meta }) => (
                                          <TextField
                                            {...formControlProps}
                                            {...input}
                                            id="bank-branch"
                                            label="Bank Branch"
                                            {...errorFieldProp(meta)}
                                          />
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <Field name={`${name}.branchCode`} parse={allowNullParse}>
                                        {({ input, meta }) => (
                                          <TextField
                                            {...formControlProps}
                                            {...input}
                                            required={false}
                                            id="bank-branch-code"
                                            label="Branch Code"
                                            {...errorFieldProp(meta)}
                                          />
                                        )}
                                      </Field>
                                    </Grid>
                                    {
                                      isEnabled && index > 0 && (
                                        <Grid container item xs={12}>
                                          <IconButton
                                            className={giveCardFormClasses.rightBtn}
                                            size="small"
                                            aria-label="remove"
                                            onClick={() => fields.remove(index)}>
                                            <DeleteOutline />
                                          </IconButton>
                                        </Grid>
                                      )
                                    }
                                  </Grid>
                                </Paper>
                              </Grid>
                            ))
                          }
                          {
                            isEnabled && (
                              <Grid container item xs={12}>
                                <Button
                                  className={giveCardFormClasses.rightBtn}
                                  variant="text"
                                  size="small"
                                  color="primary"
                                  onClick={() => push(fields.name, {})}
                                  startIcon={<Add />}>
                                  Add Payment
                                </Button>
                              </Grid>
                            )
                          }
                        </Grid>
                      )}
                    </FieldArray>
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
            {
              props.showFooter && (
                <CardContent>
                  <GiveCardFooter />
                </CardContent>
              )
            }
            {
              !props.disableForm && (
                <CardActions>
                  {
                    props.saving && (
                      <CircularProgress size={32} className={giveCardFormClasses.rightBtn} />
                    )
                  }
                  {
                    !props.useController && (
                      <Button
                        className={giveCardFormClasses.rightBtn}
                        onClick={event => onSaveDraft(event, handleSubmit)}
                        variant="outlined"
                        color="primary"
                        disabled={props.saving}
                        startIcon={<Save/>}
                      >
                        Save Draft
                      </Button>
                    )
                  }
                  <Button
                    className={props.useController ? giveCardFormClasses.rightBtn : null}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={props.saving}
                  >
                    { props.useController ? 'Save' : 'Save & Next' }
                  </Button>
                </CardActions>
              )
            }
          </form>
        )}/>
    </Card>
  )
}

export default forwardRef(Business)
