import { makeStyles, Typography, Paper } from '@material-ui/core'
import React from 'react'
import { useTranslate } from 'react-admin'

import { giveCardFormStyles } from './style'

const useStyles = makeStyles(() => ({
  bodyText: {
    whiteSpace: 'pre-wrap',
  },
}))

export function GiveCardFooter () {
  const classes = useStyles()
  const giveCardFormClasses = giveCardFormStyles()
  const translate = useTranslate()
  return (
    <Paper className={giveCardFormClasses.paperItem} variant="outlined">
      <Typography className={classes.bodyText} variant="subtitle2">
        {translate('resources.givecards.footerText')}
      </Typography>
    </Paper>
  )
}
