import { unstable_createMuiStrictModeTheme } from '@material-ui/core/styles'

const breakpoints = {
  values: {
    xs: 0,
    sm: 768,
    md: 1024,
    lg: 1280,
    xl: 1920,
  },
}

const defaultPalette = {
  type: 'light',
  primary: {
    main: '#ff00a2',
    light: '#FF80CF',
  },
  secondary: {
    main: '#5A6469',
  },
  warning: {
    main: '#FFC800',
    light: '#FFE380',
  },
  success: {
    main: '#B4F000',
    light: '#D9F780',
  },
  text: {
    primary: '#5A6469',
    secondary: '#ACB1B4',
  }
}

export default {
  light: unstable_createMuiStrictModeTheme({ palette: { ...defaultPalette }, breakpoints }),
  dark: unstable_createMuiStrictModeTheme({ palette: { ...defaultPalette }, breakpoints }),
  // dark: unstable_createMuiStrictModeTheme({ palette: { ...defaultPalette, type: 'dark' }, breakpoints })
}
